import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchDashboardData } from "../../store/actions/dashboardActions";
import { Card, Col, Nav, Row, Alert } from "react-bootstrap";
import { formatDate } from "../../utils/utils";
import CountLoader from "../dashboard/ContLoader";
import ListLoader from "../dashboard/ListLoader";
import Avatar from "../common/Avatar";

const CompanyDashboard = () => {
  const dispatch = useDispatch();
  const { isLoading, error, dashboardData } = useSelector((state) => state.dashboard);
  const colCount = 4;

  useEffect(() => {
    dispatch(fetchDashboardData());
  }, [dispatch]);

  const countData = [
    {
      label: "Projects",
      icon: "ri-briefcase-line",
      value: dashboardData?.data?.company_projects_count || 0,
      smallText: "Our Projects"
    },
    {
      label: "Completed projects",
      icon: "ri-briefcase-line",
      value: dashboardData?.data?.company_projects_count_completed || 0,
      smallText: "Our completed projects"
    },
    {
      label: "Customer's",
      icon: "ri-user-add-line",
      value: dashboardData?.data?.company_coustomer_count || 0,
      smallText: "Our customers"
    },
    {
      label: "Tender's",
      icon: "ri-article-line",
      value: dashboardData?.data?.user_tender_count || 0,
      smallText: "Our tenders"
    },
  ];

  return (
    <>
      {isLoading ? (
        <CountLoader colCOunt={colCount} />
      ) : (
        <Row>
          {countData.map((card, index) => {

            const getBackgroundColor = (type) => {
              switch (type) {
                case 'Projects':
                  return '#c9ebf280';
                case 'Completed projects':
                  return '#c0ebe180';
                case 'Customer\'s':
                  return '#ffffcc';
                case 'Tender\'s':
                  return '#ffebcc';
                default:
                  return '#ffffff';
              }
            };

            const getColor = (type) => {
              switch (type) {
                case 'Projects':
                  return '#45a2b5';
                case 'Completed projects':
                  return '#43a18b';
                case 'Customer\'s':
                  return '#e6e600';
                case 'Tender\'s':
                  return '#ffcc00';
                default:
                  return '#ffffff';
              }
            };

            return (
              <Col xs="6" xl="3" key={index}>
                <Card
                  className="card-one"
                  style={{
                    background: `linear-gradient(to bottom, ${getBackgroundColor(card.label)}, #ffffff)`,
                    border: `2px solid ${getBackgroundColor(card.label)}`
                  }}
                >
                  <Card.Body>
                    <Card.Title as="label" className="fs-sm fw-medium mb-1">
                      {card.label}
                    </Card.Title>
                    <h3 className="card-value mb-1">
                      <i style={{ color: getColor(card.label) }} className={card.icon}></i> {card.value}
                    </h3>
                    <small>{card.smallText}</small>
                  </Card.Body>
                </Card>
              </Col>
            );
          })}
        </Row>
      )}

      <Row className="g-3 mt-2">
        {isLoading ? (
          <ListLoader rowCount={10} xlCol={4} />
        ) : (
          <Col md="6" xl="4">
            <Card className="card-one">
              <Card.Header>
                <Card.Title as="h6">Ongoing projects</Card.Title>
              </Card.Header>
              <Card.Body className="p-0">
                <ul className="people-group">
                  {dashboardData?.data?.ongoing_project_list?.length ? (
                    dashboardData.data.ongoing_project_list.map((project, index) => (
                      <li className="people-item" key={index}>
                        <Avatar initial={project.project_title.charAt(0)} />
                        <div className="people-body">
                          <h6>
                            <Link to={`/project-view?projectId=${project.id}&projectName=${project.project_title}`}>
                              {project.project_title}
                            </Link>
                          </h6>
                          <span className="three-lines">{project.project_description}</span>
                          <span>
                            <strong className="text-danger">Deadline: </strong>
                            {formatDate(project.project_deadline)}
                          </span>
                        </div>
                        <Nav as="nav" className="nav-icon">
                          <Link to={`/project-view?projectId=${project.id}&projectName=${project.project_title}`}>
                            <i className="ri-arrow-right-s-line" style={{ fontSize: 24 }}></i>
                          </Link>
                        </Nav>
                      </li>
                    ))
                  ) : (
                    <li className="people-item">
                      <div className="people-body">
                        <Alert variant="primary" className="d-flex align-items-center mt-2 mb-2">
                          <small>No ongoing projects available</small>
                        </Alert>
                      </div>
                    </li>
                  )}
                </ul>
              </Card.Body>
              {dashboardData?.data?.ongoing_project_list?.length !== 0 ? <Card.Footer className="d-flex justify-content-start">
                <Link to="/my_projects" className="fs-sm">
                  All projects
                </Link>
              </Card.Footer> : ''}

            </Card>
          </Col>
        )}

        {isLoading ? (
          <ListLoader rowCount={10} xlCol={4} />
        ) : (
          <Col md="6" xl="4">
            <Card className="card-one">
              <Card.Header>
                <Card.Title as="h6">Ongoing tenders</Card.Title>
              </Card.Header>
              <Card.Body className="p-0">
                <ul className="people-group">
                  {dashboardData?.data?.ongoin_tender_list?.length ? (
                    dashboardData.data.ongoin_tender_list.map((tender, index) => (
                      <li className="people-item" key={index}>
                        <Avatar initial={tender.tender_name.charAt(0)} />
                        <div className="people-body">
                          <h6>
                            <Link to={`/tender-view?tenderId=${tender.id}&tenderName=${tender.tender_name}`}>
                              {tender.tender_name}
                            </Link>
                          </h6>
                          <span className="three-lines">{tender.tender_description}</span>
                          <span>
                            <strong className="text-danger">Deadline: </strong>
                            {formatDate(tender.project_deadline)}
                          </span>
                        </div>
                        <Nav as="nav" className="nav-icon">
                          <Link to={`/tender-view?tenderId=${tender.id}&tenderName=${tender.tender_name}`}>
                            <i className="ri-arrow-right-s-line" style={{ fontSize: 24 }}></i>
                          </Link>
                        </Nav>
                      </li>
                    ))
                  ) : (
                    <li className="people-item">
                      <div className="people-body">
                        <Alert variant="primary" className="d-flex align-items-center mt-2 mb-2">
                          <small>No ongoing tenders available</small>
                        </Alert>
                      </div>
                    </li>
                  )}
                </ul>
              </Card.Body>
              {dashboardData?.data?.ongoin_tender_list?.length !== 0 ? <Card.Footer className="d-flex justify-content-start">
                <Link to="/tender_list" className="fs-sm">
                  All tenders
                </Link>
              </Card.Footer> : ''}

            </Card>
          </Col>
        )}

        {isLoading ? (
          <ListLoader rowCount={10} xlCol={4} />
        ) : (
          <Col md="6" xl="4">
            <Card className="card-one">
              <Card.Header>
                <Card.Title as="h6">Completed projects</Card.Title>
              </Card.Header>
              <Card.Body className="p-0">
                <ul className="people-group">
                  {dashboardData?.data?.completed_project_list?.length ? (
                    dashboardData.data.completed_project_list.map((project, index) => (
                      <li className="people-item" key={index}>
                        <Avatar initial={project.project_title.charAt(0)} />
                        <div className="people-body">
                          <h6>
                            <Link to={`/project-view?projectId=${project.id}&projectName=${project.project_title}`}>
                              {project.project_title}
                            </Link>
                          </h6>
                          <span className="three-lines">{project.project_description}</span>
                          <span>
                            <strong className="text-success">Completed date: </strong>
                            {formatDate(project.project_deadline)}
                          </span>
                        </div>
                        <Nav as="nav" className="nav-icon">
                          <Link to={`/project-view?projectId=${project.id}&projectName=${project.project_title}`}>
                            <i className="ri-arrow-right-s-line" style={{ fontSize: 24 }}></i>
                          </Link>
                        </Nav>
                      </li>
                    ))
                  ) : (
                    <li className="people-item">
                      <div className="people-body">
                        <Alert variant="primary" className="d-flex align-items-center mt-2 mb-2">
                          <small>No completed projects available</small>
                        </Alert>
                      </div>
                    </li>
                  )}
                </ul>
              </Card.Body>
              {dashboardData?.data?.completed_project_list?.length !== 0 ? <Card.Footer className="d-flex justify-content-start">
                <Link to="/my_projects" className="fs-sm">
                  All projects
                </Link>
              </Card.Footer> : ''}

            </Card>
          </Col>
        )}
      </Row >
    </>
  );
};

export default CompanyDashboard;
