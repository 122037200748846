import React, { useState, useRef, useEffect } from 'react';
import { Form, Alert } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import CompanyProjectReport from '../../components/report/CompanyProjectReport';
import CustomerAllProjectReport from '../../components/report/CustomerAllProjectReport';
import CustomerSingleProjectReport from '../../components/report/CustomerSingleProjectReport';
import { projectReportTypes } from '../../utils/types';
import { useDispatch } from 'react-redux';
import { fetchInitialProjectListCustomer } from '../../store/actions/reportActions';

function ProjectReport() {
    const moduleName = "Project Reports";
    const dispatch = useDispatch();
    const UserRole = useSelector((state) => state.auth.userInfo.user_role);
    const reportSelectRef = useRef(null);
    const [selectedReport, setSelectedReport] = useState("");
    const [selectOptions, setSelectOption] = useState(projectReportTypes);
    const [projectOptions, setProjectOption] = useState([]);
    const isReportIdNullOrZero = selectedReport === "" || selectedReport === "0";

    useEffect(() => {
        if (UserRole === "2") {
            fetchCustomerProjectList();
        }
    }, [])

    const fetchCustomerProjectList = async () => {
        const projects = await dispatch(fetchInitialProjectListCustomer());
        if (projects.payload) {
            setProjectOption(projects.payload);
        }
    };

    const handleSelectChange = (reportType) => {
        setSelectedReport(reportType);
    };

    return (
        <>
            <div className="main main-app p-3 p-lg-4">
                <div className="d-flex align-items-center justify-content-between mb-4">
                    <div>
                        <ol className="breadcrumb fs-sm mb-1">
                            <li className="breadcrumb-item" aria-current="page">
                                Dashboard
                            </li>
                            <li className="breadcrumb-item active">{moduleName}</li>
                        </ol>
                        <h4 className="main-title mb-0">{moduleName}</h4>
                    </div>
                </div>
                {UserRole === "3" &&
                    <div className="d-flex flex-row mb-4 col-md-3">
                        <Form.Select
                            ref={reportSelectRef}
                            onChange={(e) => handleSelectChange(e.target.value)}
                        >
                            <option value="0">Select Report Type</option>
                            {selectOptions?.map((report) => (
                                <option key={report.id} value={report.id}>
                                    {report.name}
                                </option>
                            ))}
                        </Form.Select>
                    </div>
                }
                {UserRole === "2" &&
                    <div className="d-flex flex-row mb-4 col-md-3">
                        <Form.Select
                            ref={reportSelectRef}
                            onChange={(e) => handleSelectChange(e.target.value)}
                        >
                            <option value="0">Select Report Type</option>
                            <option value="2">All</option>
                            {projectOptions && projectOptions?.map((project) => (
                                <option key={project.id} value={project.id}>
                                    {project.project_title}
                                </option>
                            ))}
                        </Form.Select>
                    </div>
                }
                {isReportIdNullOrZero ? (
                    <Alert variant="primary" className="d-flex align-items-center mb-2">
                        <i className="ri-information-line"></i> Report Type not selected.
                        Please select a report type.
                    </Alert>
                ) : (
                    <div>
                        {selectedReport === "0" ?
                            null :
                            selectedReport === "1" ?
                                <CompanyProjectReport /> :
                                selectedReport === "2" ?
                                    <CustomerAllProjectReport /> :
                                    <CustomerSingleProjectReport id={selectedReport} />
                        }
                    </div>
                )
                }
            </div>
        </>
    )
}

export default ProjectReport