import React, { useEffect, useState } from "react";
import moment from "moment";
import {
  Button,
  Tooltip,
  OverlayTrigger,
  Form,
  Row,
  Alert,
} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import ReactDatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import FormModal from "../../common/FormModal";
import {
  saveCustomerQuotation,
  fetchBidListTender,
} from "../../../store/actions/tenderActions";
import { useForm, Controller } from "react-hook-form";

const CustomInput = React.forwardRef(({ value, onClick }, ref) => (
  <div className="custom-input" onClick={ onClick } ref={ ref }>
    <input value={ value } readOnly className="form-control w-100" />
    <i className="ri-calendar-line calendar-icon"></i>
  </div>
));

const SaveCustomerQuotationData = ({
  generateQuotation,
  quotatonFileName,
  tenderData,
  totalPrice,
  quotationData,
  bidId,
  is_sent_quotation,
  customerQuotationSaveData,
}) => {
  const dispatch = useDispatch();
  const moment = require("moment");
  const { loading } = useSelector((state) => state.tender);
  const [showModalState, setShowModalState] = useState(false);

  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: {
      email: tenderData?.customer_email,
      is_logistics:
        customerQuotationSaveData?.is_logistics === 0 ? false : true,
      quotation_note: customerQuotationSaveData?.quotation_note,
      quotation_deadline: customerQuotationSaveData?.quotation_deadline,
      project_deadline: customerQuotationSaveData?.project_deadline,
      attachment: "",
    },
  });

  const toggleFormModal = () => {
    setShowModalState(!showModalState);
    reset();
  };

  const onSubmit = async (data) => {
    const transformedDetails = quotationData.map((item) => ({
      item_name: item.item_name,
      quantity: item.quantity,
      price: item.price,
      after_price: item.after_price,
    }));
    data.attachment = quotatonFileName;
    data.tender_id = tenderData.id;
    data.bid_quotation_id = bidId;
    data.total_price = totalPrice;
    data.project_deadline = moment(data.project_deadline).format(
      "YYYY-MM-DD HH:mm:ss"
    );
    data.quotation_deadline = moment(data.quotation_deadline).format(
      "YYYY-MM-DD HH:mm:ss"
    );

    data.details = transformedDetails;

    dispatch(saveCustomerQuotation(data)).then(() => {
      dispatch(fetchBidListTender(tenderData.id));
      toggleFormModal();
    });
  };

  return (
    <>
      <OverlayTrigger
        placement="top"
        overlay={ <Tooltip>Save customer quotation</Tooltip> }
      >
        <Button
          variant="primary"
          className="icon-with-btn"
          onClick={ toggleFormModal }
          disabled={ is_sent_quotation === 1 }
        >
          <i className="ri-save-line"></i> Save customer quotation
        </Button>
      </OverlayTrigger>
      <FormModal
        moduleName={ "Save customer quotation" }
        modalState={ showModalState }
        toggleFormModal={ toggleFormModal }
        width="600px"
      >
        <Form onSubmit={ handleSubmit(onSubmit) }>
          <Row>
            <div className="col-md-12 mb-3">
              <div className="form-group">
                <Form.Label><span><i style={ { color: "#ff3300", fontSize: "10px" } } className="ri-asterisk"></i></span> Customer email</Form.Label>
                <Form.Control
                  type="email"
                  className="form-input"
                  disabled
                  id="email"
                  { ...register("email", {
                    required: "Customer email is required",
                    pattern: {
                      value:
                        /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
                      message: "Invalid email address",
                    },
                  }) }
                />
                { errors.email && (
                  <small className="text-danger">{ errors.email.message }</small>
                ) }
              </div>
            </div>
          </Row>
          <Row>
            <div className="col-md-12 mb-3">
              <div className="form-group">
                <Form.Label><span><i style={ { color: "#ff3300", fontSize: "10px" } } className="ri-asterisk"></i></span> Quotation note</Form.Label>
                <Form.Control
                  as="textarea"
                  disabled={ loading }
                  { ...register("quotation_note", {
                    required: "Quotation note is required",
                    maxLength: {
                      value: 1000,
                      message: "Maximum length is 1000 characters",
                    },
                  }) }
                  placeholder="Tender description"
                  className="textarea"
                ></Form.Control>
                { errors.quotation_note && (
                  <small className="text-danger">
                    { errors.quotation_note.message }
                  </small>
                ) }
              </div>
            </div>
          </Row>
          <Row>
            <div className="col-md-6 mb-3">
              <Form.Label><span><i style={ { color: "#ff3300", fontSize: "10px" } } className="ri-asterisk"></i></span> Quotation deadline</Form.Label>
              <Controller
                control={ control }
                name="quotation_deadline"
                rules={ { required: "Quotation deadline is required" } }
                render={ ({ field }) => (
                  <ReactDatePicker
                    className="form-control"
                    disabled={ loading }
                    dateFormat="yyyy-MM-dd"
                    minDate={ new Date() }
                    dropdownMode="select"
                    selected={ field.value }
                    onChange={ (date) => field.onChange(date) }
                    customInput={ <CustomInput /> }
                  />
                ) }
              />
              { errors.quotation_deadline && (
                <small className="text-danger">
                  { errors.quotation_deadline.message }
                </small>
              ) }
            </div>
            <div className="col-md-6 mb-3">
              <Form.Label><span><i style={ { color: "#ff3300", fontSize: "10px" } } className="ri-asterisk"></i></span> Project deadline</Form.Label>
              <Controller
                control={ control }
                name="project_deadline"
                rules={ { required: "Project deadline is required" } }
                render={ ({ field }) => (
                  <ReactDatePicker
                    className="form-control"
                    disabled={ loading }
                    dateFormat="yyyy-MM-dd"
                    minDate={ new Date() }
                    dropdownMode="select"
                    selected={ field.value }
                    onChange={ (date) => field.onChange(date) }
                    customInput={ <CustomInput /> }
                  />
                ) }
              />
              { errors.project_deadline && (
                <small className="text-danger">
                  { errors.project_deadline.message }
                </small>
              ) }
            </div>
          </Row>
          <Row>
            <div className="col-md-12 mb-3">
              <Form.Label>Attachment Doc</Form.Label>
              <Alert
                variant="secondary"
                className="d-flex align-items-center p-1 pl-2"
              >
                <span className="me-auto d-block p-2">{ quotatonFileName }</span>
                <Button
                  variant="secondary"
                  className="icon-with-btn"
                  onClick={ () => {
                    generateQuotation();
                  } }
                >
                  <i className="ri-attachment-2"></i> Generate attachment
                </Button>
              </Alert>
            </div>
          </Row>
          <Row>
            <div className="col-md-12 mb-3">
              <div className="form-group">
                <Form.Check
                  disabled={ loading }
                  type="checkbox"
                  label="We have logistic facilities"
                  { ...register("is_logistics") }
                />
              </div>
            </div>
          </Row>
          <div className="d-flex flex-wrap gap-2 mt-3">
            <Button
              type="button"
              disabled={ loading }
              variant="outline-primary"
              className="btn-sign"
              onClick={ () => {
                reset();
                toggleFormModal();
              } }
            >
              Cancel
            </Button>
            <Button type="submit" variant="primary" disabled={ loading }>
              { loading ? (
                <span
                  className="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
              ) : (
                "Save Quotation data"
              ) }
            </Button>
          </div>
        </Form>
      </FormModal>
    </>
  );
};

export default SaveCustomerQuotationData;
