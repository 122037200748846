// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.map-container {
  height: 350px;
  width: 100%;
  border-radius: 8px;
}
.map-container iframe {
  border-radius: 8px;
}`, "",{"version":3,"sources":["webpack://./src/assets/styles/components/common/GoogleMapEmbed.scss"],"names":[],"mappings":"AAEA;EACI,aAAA;EACA,WAAA;EACA,kBAAA;AADJ;AAGI;EACI,kBAAA;AADR","sourcesContent":["@import '../../variables';\n\n.map-container {\n    height: 350px;\n    width: 100%;\n    border-radius: 8px;\n\n    iframe {\n        border-radius:8px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
