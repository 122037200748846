import React, { useState } from "react";
import { Card, Badge } from "react-bootstrap";
import { Link } from "react-router-dom";
import { formatDate } from "../../../utils/utils";
import DownloadButton from "../../common/DownloadButton";
import useAuth from "../../../hooks/useAuth";
import styles from "../../../assets/styles/components/tender-list/TenderView.module.scss";

const ProjectInfo = ({ projectInfoData }) => {
  const isAuthenticated = useAuth();
  const viewPrice =
    isAuthenticated.id === projectInfoData?.owner?.id ||
    isAuthenticated.id === projectInfoData?.user_id.id;

  return (
    <>
      { projectInfoData && (
        <div>
          <p className="post-text">{ projectInfoData?.project_description }</p>
          <div className={ `row ${styles.tender_info_list}` }>
            <div className="col-md-12">
              <div className={ styles.media }>
                <label>
                  <i className="ri-link"></i> Project Ref.No.
                </label>
                <p>{ projectInfoData?.id }</p>
              </div>
              <div className={ styles.media }>
                <label>
                  <i className="ri-article-line"></i> Tender Ref.No.
                </label>
                <p>{ projectInfoData?.tender_id }</p>
              </div>
              <div className={ styles.media }>
                <label>
                  <i className="ri-link"></i> Project status
                </label>
                <p>
                  { projectInfoData?.is_completed === 0 ? (
                    <Badge className="bg-danger" pill>Ongoing</Badge>
                  ) : (
                    <Badge bg="success" pill>Completed</Badge>
                  ) }
                </p>
              </div>
              <div className={ styles.media }>
                <label>
                  <i className="ri-community-line"></i> Project owner
                </label>
                <p>{ projectInfoData?.owner?.first_name }</p>
              </div>
              <div className={ styles.media }>
                <label>
                  <i className="ri-map-pin-line"></i> Location
                </label>
                <p>{ `${projectInfoData?.owner?.region}, ${projectInfoData?.owner?.country}` }</p>
              </div>
              <div className={ styles.media }>
                <label>
                  <i className="ri-calendar-line"></i> Project create date
                </label>
                <p>{ formatDate(projectInfoData?.created_at) }</p>
              </div>
              <div className={ styles.media }>
                <label>
                  <i className="ri-calendar-line"></i> Project closing date
                </label>
                <p className="text-danger">
                  { formatDate(projectInfoData?.project_deadline) }
                </p>
              </div>
              { viewPrice && (
                <div className={ styles.media }>
                  <label>
                    <i className="ri-calendar-line"></i> Project value
                  </label>
                  <p className="text-bold">
                    <strong>{ projectInfoData?.project_value }</strong>
                  </p>
                </div>
              ) }

              {/* <div className={styles.media}>
                <label>
                  <i className="ri-group-3-line"></i> Project involved users
                </label>
                <p className="text-bold">{`Customer: ${projectInfoData?.user?.first_name} Owner: ${projectInfoData?.owner?.first_name} Bidding company: ${projectInfoData?.bid_user?.first_name}`}</p>
              </div> */}
              { projectInfoData?.attachments && (
                <div className={ styles.media }>
                  <label>
                    <i className="ri-attachment-line"></i> Attachments
                  </label>
                  <p className="text-danger">
                    <DownloadButton
                      fileName={ projectInfoData?.attachments }
                      toolTip="Click here download document"
                    />
                  </p>
                </div>
              ) }
            </div>
          </div>
        </div>
      ) }
    </>
  );
};

export default ProjectInfo;
