import { createAsyncThunk } from "@reduxjs/toolkit";
import { get, post, put } from "../../servces/Api";
import Alert from "../../components/common/Alert";

//Create new tender
export const createTender = createAsyncThunk(
  "tender/createTender",
  async (tenderData, { rejectWithValue }) => {
    try {
      const response = await post("/api/create_tender", tenderData);
      if (response.status === 200) {
        Alert.success("Your tender has been successfully created!");
        return response.data;
      }
    } catch (error) {
      if (!error.response) {
        // network error
        Alert.error("Network error. Please try again.");
      } else {
        // server error
        Alert.error("Failed to create tender. Please try again later.");
      }
      return rejectWithValue(error.response.data);
    }
  }
);

//Fetch all tenders data
export const fetchTenders = createAsyncThunk(
  "tender/tenderList",
  async (data, { rejectWithValue }) => {
    try {
      const response = await post("api/view_all_tenders", data);
      if (response.status === 200) {
        return response.data;
      }
    } catch (error) {
      if (!error.response) {
        // network error
        return rejectWithValue("Network error. Please try again.");
      } else {
        // server error
        return rejectWithValue(
          "Failed to fetch tenders. Please try again later."
        );
      }
    }
  }
);

//Updte tender closing date
export const updateTender = createAsyncThunk(
  "tender/updateTender",
  async ({ tenderData }, { rejectWithValue }) => {
    try {
      const response = await put(`/api/edit_tender`, tenderData);
      if (response.status === 200) {
        Alert.success("Your tender has been successfully updated!");
        return response.data;
      }
    } catch (error) {
      if (!error.response) {
        // Network error
        Alert.error("Network error. Please try again.");
      } else {
        // Server error
        Alert.error("Failed to update tender. Please try again later.");
      }
      return rejectWithValue(
        error.response ? error.response.data : error.message
      );
    }
  }
);

//Fetch tender all infomation
export const tenderView = createAsyncThunk(
  "tender/tenderView",
  async (id, thunkApi) => {
    try {
      const response = await get(`api/view_tender/${id}`);
      if (response.status === 200) {
        return response.data;
      }
    } catch (error) {
      if (!error.response) {
        // network error
        return thunkApi.rejectWithValue("Network error. Please try again.");
      } else {
        // server error
        return thunkApi.rejectWithValue(
          "Failed to fetch tenders. Please try again later."
        );
      }
    }
  }
);

//Fetch all terders for bided
export const fetchTendersForBided = createAsyncThunk(
  "tender/fetchTendersForBided",
  async (data, { rejectWithValue }) => {
    try {
      const response = await post("api/view_all_bids_by_user", data);
      if (response.status === 200) {
        return response.data;
      }
    } catch (error) {
      if (!error.response) {
        // network error
        return rejectWithValue("Network error. Please try again.");
      } else {
        // server error
        return rejectWithValue(
          "Failed to fetch tenders. Please try again later."
        );
      }
    }
  }
);

//Add bid for tender
export const addBidForTender = createAsyncThunk(
  "tender/addBidForTender",
  async (bidQuotationData, { rejectWithValue }) => {
    try {
      const response = await post(
        "/api/add_quotation_company",
        bidQuotationData
      );

      if (response.status === 201) {
        Alert.success("Your bid has been successfully added!");
        return response.data;
      }
    } catch (error) {
      if (!error.response) {
        // network error
        Alert.error("Network error. Please try again.");
      } else {
        // server error
        Alert.error("Failed to create bid. Please try again later.");
      }
      return rejectWithValue(error.response.data);
    }
  }
);

//get bid list by tender
export const fetchBidListTender = createAsyncThunk(
  "tender/viewTenderBids",
  async (id, thunkApi) => {
    try {
      const response = await get(
        `api/get_quotation_by_bid_service_provider/${id}`
      );
      if (response.status === 200) {
        return response.data;
      }
    } catch (error) {
      if (!error.response) {
        // network error
        return thunkApi.rejectWithValue("Network error. Please try again.");
      } else {
        // server error
        return thunkApi.rejectWithValue(
          "Failed to fetch tenders. Please try again later."
        );
      }
    }
  }
);

//Send quotation in customer
export const saveCustomerQuotation = createAsyncThunk(
  "tender/saveCustomerQuotation",
  async (quotationData, { rejectWithValue }) => {
    try {
      const response = await post("/api/add_customer_quotation", quotationData);

      if (response.status === 201) {
        Alert.success("your quotation has been save successfully!");
        return response.data;
      } else if (response.status === 200) {
        Alert.success("your quotation has been update successfully!");
        return response.data;
      }
    } catch (error) {
      if (!error.response) {
        // network error
        Alert.error("Network error. Please try again.");
      } else {
        // server error
        Alert.error("Failed to send quotation. Please try again later.");
      }
      return rejectWithValue(error.response.data);
    }
  }
);

//Send quotation email customer
export const sendQuotationCustomer = createAsyncThunk(
  "tender/sendQuotationCustomer",
  async (emailData, { rejectWithValue }) => {
    try {
      const response = await post("/api/send_quotation", emailData);

      if (response.status === 200) {
        Alert.success("your quotation has been sent successfully!");
        return response.data;
      }
    } catch (error) {
      if (!error.response) {
        // network error
        Alert.error("Network error. Please try again.");
      } else {
        // server error
        Alert.error("Failed to send quotation. Please try again later.");
      }
      return rejectWithValue(error.response.data);
    }
  }
);

//Customer reject quotation
export const customerRejectQuotation = createAsyncThunk(
  "tender/customerRejectQuotation",
  async (quotation_id, { rejectWithValue }) => {
    try {
      const response = await post("/api/reject_quotation", quotation_id, false);

      if (response.status === 200) {
        Alert.success("Your quotation has been rejected successfully!");
        return response.data;
      }
    } catch (error) {
      if (!error.response) {
        // network error
        Alert.error("Network error. Please try again.");
      } else {
        // server error
        Alert.error("Failed to send quotation. Please try again later.");
      }
      return rejectWithValue(error.response.data);
    }
  }
);

//acceptCustomerQuotation
export const acceptCustomerQuotation = createAsyncThunk(
  "tender/acceptCustomerQuotation",
  async (quotation_id, { rejectWithValue }) => {
    try {
      const response = await post(
        "/api/approve_quotation",
        quotation_id,
        false
      );

      if (response.status === 200) {
        Alert.success("Your quotation has been rejected successfully!");
        return response.data;
      }
    } catch (error) {
      if (!error.response) {
        // network error
        Alert.error("Network error. Please try again.");
      } else {
        // server error
        Alert.error("Failed to send quotation. Please try again later.");
      }
      return rejectWithValue(error.response.data);
    }
  }
);

//get save quotation and send quotation data
export const fetchCustomerQuotation = createAsyncThunk(
  "tender/fetchCustomerQuotation",
  async (id, thunkApi) => {
    try {
      const response = await get(`api/get_quotation_customer/${id}`);
      if (response.status === 200) {
        return response.data;
      }
    } catch (error) {
      if (!error.response) {
        // network error
        return thunkApi.rejectWithValue("Network error. Please try again.");
      } else {
        // server error
        return thunkApi.rejectWithValue(
          "Failed to fetch tenders. Please try again later."
        );
      }
    }
  }
);

//Customer reject quotation
export const getQuotationStatus = createAsyncThunk(
  "tender/getQuotationStatus",
  async (quotation_id, { rejectWithValue }) => {
    try {
      const response = await post("/api/check_status_quotation", quotation_id);

      if (response.status === 200) {
        return response.data;
      }
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

//Get Tender comments
export const getTenderComments = createAsyncThunk(
  "tender/getTenderComments",
  async (tender_id, { rejectWithValue }) => {
    try {
      const response = await post("/api/load_comments", tender_id);

      if (response.status === 200) {
        return response.data;
      }
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const saveComments = createAsyncThunk(
  "tender/createComments",
  async (data, { rejectWithValue }) => {
    try {
      const response = await post("/api/create_question", data);
      if (response.status === 200) {
        Alert.success("Your comment has been successfully created!");
        return response;
      }
    } catch (error) {
      if (!error.response) {
        // network error
        Alert.error("Network error. Please try again.");
      } else {
        // server error
        Alert.error("Failed to create comment. Please try again later.");
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const editComments = createAsyncThunk(
  "tender/editComments",
  async (data, { rejectWithValue }) => {
    try {
      const response = await post("/api/update_question", data);
      if (response.status === 200) {
        Alert.success("Your comment has been successfully updated!");
        return response;
      }
    } catch (error) {
      if (!error.response) {
        // network error
        Alert.error("Network error. Please try again.");
      } else {
        // server error
        Alert.error("Failed to update comment. Please try again later.");
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const saveReply = createAsyncThunk(
  "tender/createReply",
  async (data, { rejectWithValue }) => {
    try {
      const response = await post("/api/question_reply", data);
      if (response.status === 200) {
        Alert.success("Your reply has been successfully created!");
        return response;
      }
    } catch (error) {
      if (!error.response) {
        // network error
        Alert.error("Network error. Please try again.");
      } else {
        // server error
        Alert.error("Failed to create reply. Please try again later.");
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const editReply = createAsyncThunk(
  "tender/updateReply",
  async (data, { rejectWithValue }) => {
    try {
      const response = await post("/api/question_reply_update", data);
      if (response.status === 200) {
        Alert.success("Your reply has been successfully updated!");
        return response;
      }
    } catch (error) {
      if (!error.response) {
        // network error
        Alert.error("Network error. Please try again.");
      } else {
        // server error
        Alert.error("Failed to update reply. Please try again later.");
      }
      return rejectWithValue(error.response.data);
    }
  }
);
