import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Rating from "react-rating-stars-component";
import TestimonialBg from "../../assets/img/bg/11.png";
import quoteIcon from "../../assets/img/testimonial/01.png";
import UserImage from "../../assets/img/user.png";
import "../../assets/styles/components/home/FeedbackCarousel.scss";
import FeedbackForm from "./FeedbackForm";
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

const FeedbackCarousel = ({ feedbackData, feedbackDetailsLoading }) => {
  const settings = {
    dots: true,
    autoplay: true,
    arrows: true,
    infinite: true,
    speed: 1000,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,

    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
          arrows: false,
        },
      },
      {
        breakpoint: 575,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
        },
      },
    ],
  };

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const loaderItems = isMobile ? [0] : [...Array(3)];

  const StarRatingLoader = ({ count = 5 }) => {
    return (
      <div className="d-flex justify-content-center">
        {[...Array(count)].map((_, index) => (
          <Skeleton
            key={index}
            width={24}
            height={24}
            style={{ marginRight: '4px', borderRadius: '50%', marginTop:"15px" }}
          />
        ))}
      </div>
    );
  };

  return (
    <>
      <div
        className="testimonial-area pd-top-60 pd-bottom-60 bg-gray"
        style={{ backgroundImage: `url(${TestimonialBg})` }}
      >
        <div className="container">
          <div className="section-title text-center mb-5">
            <h2 className="title">What people say about us</h2>
            <FeedbackForm feedbackDetailsLoading={feedbackDetailsLoading} />
          </div>
          <div className="testimonial-slider  slider-control-round slider-control-dots slider-control-right-top">
            {!feedbackDetailsLoading ? (
              <Slider {...settings}>
                {feedbackData.map((feedback, index) => (
                  <div className="item" key={feedback.id}>
                    <div className="single-testimonial-inner testimonial-content text-center">
                      <h5>{feedback.full_name}</h5>
                      <div className="icon mb-2">
                        <img src={quoteIcon} alt="img" />
                      </div>
                      <p className="limited-lines">{feedback.q_1}</p>
                      <div className="ratting-inner mt-4 d-flex justify-content-center">
                        <Rating
                          count={feedback.rate}
                          size={24}
                          edit={false}
                          color="#ffd700"
                        />
                      </div>
                      <div className="thumb">
                        <img src={UserImage} alt="img" />
                      </div>
                    </div>
                  </div>
                ))}
              </Slider>
            ) : (
              <div className="loader">
                {loaderItems.map((_, index) => (
                  <div className="item" key={index}>
                    <div className="single-testimonial-inner testimonial-content text-center">
                      <Skeleton width={100} height={30} style={{ margin: '10px auto' }} />
                      <Skeleton count={2} height={15} style={{ margin: '10px 0' }} />
                      <StarRatingLoader count={5} />
                      <div className="thumb mt-4">
                        <Skeleton circle={true} width={80} height={80} />
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default FeedbackCarousel;
