import React, { useEffect, useState } from "react";
import { Badge, Alert, Tooltip, OverlayTrigger, } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { fetchOnboardUsersList } from "../../store/actions/userManageActions";
import Footer from "../../components/dashboard/Footer";
import { formatDate } from "../../utils/utils";
import PagintionWithDataTable from "../../components/common/PagintionWithDataTable";
import UserInfoView from "../../components/dashboard/user-manage/UserInfoView";
import UserApproval from "../../components/dashboard/onboardUser/UserApproval";
import PaginationWithDynamicData from "../../components/common/PaginationWithDynamicData";

const OnboardUsers = () => {
  const moduleName = "Onboard Users";
  const dispatch = useDispatch();
  const { onboardUserList, isLoading, error } = useSelector(
    (state) => state.usersManage
  );
  const [paginationData, setPaginationData] = useState({
    page: 1,
    perPage: 10,
  });
  const [paginationOptions, setPaginationOptions] = useState({});

  useEffect(() => {
    dispatch(fetchOnboardUsersList(paginationData));
  }, [paginationData]);

  useEffect(() => {
    if (onboardUserList?.pagination) {
      setPaginationOptions({
        totalRows: onboardUserList.pagination.total,
        perPage: onboardUserList.pagination.per_page,
        currentPage: onboardUserList.pagination.current_page,
        totalPages: onboardUserList.pagination.total_pages,
      });
    }
  }, [onboardUserList]);

  // Function to format date
  const formatDateTime = (dateTimeString) => {
    const date = new Date(dateTimeString);
    const dateOptions = { year: 'numeric', month: 'long', day: 'numeric' };
    const timeOptions = { hour: '2-digit', minute: '2-digit', hour12: true };
    const formattedDate = date.toLocaleDateString('en-US', dateOptions);
    const formattedTime = date.toLocaleTimeString('en-US', timeOptions);
    return `${formattedDate}, ${formattedTime}`;
  };

  const columns = [
    {
      name: "ID",
      selector: (row) => row.id,
      width: "75px",
    },
    {
      name: "Full Name",
      selector: (row) => row.first_name + " " + row.last_name,
    },
    {
      name: "Email",
      selector: (row) => row.email,
    },
    {
      name: "User Role",
      selector: (row) => (
        <strong className="text-capitalize text-dark">
          {row.user_role ? row.user_role.role_name : "N/A"}
        </strong>
      ),
    },
    {
      name: "Onboard Process Status",
      selector: (row) => (
        <Badge pill
          bg={`${row.is_onboard === 0 && row.is_pending === 1 ? "danger" : "success"
            }`}
        >
          {row.is_onboard === 0 && row.is_pending === 1
            ? "Document Upload Pending"
            : "Admin Approval Pending"}
        </Badge>
      ),
      width: "200px",
    },
    {
      name: "Created At",
      selector: (row) => (
        <div className="d-flex flex-wrap">
          <OverlayTrigger
            placement="top"
            overlay={<Tooltip>{formatDateTime(row.updated_at)}</Tooltip>}
          >
            <div style={{ display: "inline-block" }}>
              {formatDateTime(row.updated_at)}
            </div>
          </OverlayTrigger>
        </div>
      ),
    },
    {
      name: "Action",
      selector: (row) => (
        <div className="d-flex flex-wrap gap-2">
          <UserInfoView userId={row.id} />
          <UserApproval
            user={row}
            onApprove={() => dispatch(fetchOnboardUsersList())}
            isOnboard={row.is_onboard}
            isPending={row.is_pending}
          />
        </div>
      ),
      width: "150px",
    },
  ];

  const rows = Array.isArray(onboardUserList?.userdet)
    ? onboardUserList?.userdet?.map(
      ({
        id,
        first_name,
        last_name,
        updated_at,
        email,
        status,
        is_onboard,
        is_pending,
        user_role,
      }) => ({
        id,
        first_name,
        last_name,
        updated_at,
        email,
        status,
        is_onboard,
        is_pending,
        user_role,
      })
    )
    : [];

  const handlePageChange = (page) => {
    setPaginationData((prev) => ({ ...prev, page }));
  };

  const handlePerPageChange = (newPerPage) => {
    setPaginationData((prev) => ({ ...prev, perPage: newPerPage }));
  };

  return (
    <>
      <div className="main main-app p-3 p-lg-4">
        <div className="d-flex align-items-center justify-content-between mb-4">
          <div>
            <ol className="breadcrumb fs-sm mb-1">
              <li className="breadcrumb-item" aria-current="page">
                Dashboard
              </li>
              <li className="breadcrumb-item active">{moduleName}</li>
            </ol>
            <h4 className="main-title mb-0">{moduleName}</h4>
          </div>
        </div>
        <div className="inner-wrapper">
          <PaginationWithDynamicData
            columns={columns}
            rows={rows}
            loading={isLoading}
            columnCount={columns.length}
            paginationOptions={paginationOptions}
            handlePageChange={handlePageChange}
            handlePerPageChange={handlePerPageChange}
          />
        </div>

        <Footer />
      </div>
    </>
  );
};

export default OnboardUsers;
