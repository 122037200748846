import React, { useState, useEffect } from "react";
import MenuBar from "../components/home/MenuBar";
import TandCBanner from "../assets/img/TandC-img.jpg";
import TandCBannerBottom from "../assets/img/TandC-img2.png";
import Footer from "../components/home/Footer";
import "../assets/styles/pages/PrivacyAndPolicy.scss";
import { useNavigate } from "react-router-dom";
import { tabsData } from "../data/HomeData";
import { Tabs, Tab, Container } from 'react-bootstrap';

function PrivacyAndPolicy() {
    const navigate = useNavigate();

    return (
        <div className="page-home">
            <MenuBar />
            <div className="position-relative" style={{ height: '400px', marginTop: '-137px' }}>
                <div
                    className="position-absolute top-0 start-0 w-100 h-100"
                    style={{
                        backgroundImage: `url(${TandCBanner})`,
                        backgroundSize: 'cover',
                    }}
                ></div>
                <div
                    className="position-absolute top-0 start-0 w-100 h-100"
                    style={{ backgroundColor: 'rgba(0, 0, 0, 0.5)' }}
                ></div>
                <div className="position-relative d-flex justify-content-center align-items-center w-100 h-100 pt-5" style={{ zIndex: 1 }}>
                    <h1 className="text-white fw-bold">Terms of Use</h1>
                </div>
            </div>
            <div className="work-preference pd-top-60 pd-bottom-60 bg-light-gray">
                <div className="container">
                    <div className="div-main">
                        <p className="heading">Legal Information & Notices</p>
                        <p className="sub-heading mb-5">Abdolit is committed to your privacy. Read our customer Privacy Policy for a clear explanation of how we collect, use, disclose, transfer, and store your information.</p>
                        <Container>
                            <Tabs defaultActiveKey="tab1" id="tab-view" className="mb-3" fill>
                                {tabsData.map((tab) => (
                                    <Tab eventKey={tab.key} title={tab.title} key={tab.key}>
                                        <div className="text-center my-5">
                                            <h3 className="fw-bold text-primary">{tab.title}</h3>
                                            <p className="text-dark">{tab.description}</p>
                                        </div>
                                        <div className="text-start">
                                            {tab.content.map((paragraph, index) => (
                                                <p className="mb-4" key={index}>{paragraph}</p>
                                            ))}
                                        </div>
                                    </Tab>
                                ))}
                            </Tabs>
                        </Container>
                    </div>
                </div>
            </div>
            <div className="position-relative" style={{ height: '250px' }}>
                <div
                    className="position-absolute top-0 start-0 w-100 h-100"
                    style={{
                        backgroundImage: `url(${TandCBannerBottom})`,
                        backgroundSize: 'cover',
                    }}
                ></div>
                <div
                    className="position-absolute top-0 start-0 w-100 h-100"
                    style={{ backgroundColor: 'rgba(0, 0, 0, 0.5)' }}
                ></div>
                <div className="position-relative d-flex flex-column justify-content-center align-items-center w-100 h-100" style={{ zIndex: 1 }}>
                    <h1 className="text-white">Feedback and Information</h1>
                    <p className="text-white fs-5">Any feedback you provide at this site shall be deemed to be non-confidential.
                        Abdolit shall be free to use such information on an unrestricted basis</p>
                    <button onClick={() => navigate("/help_and_support")} className="btn-contact">Contact Us</button>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default PrivacyAndPolicy