import React from 'react';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import '../../assets/styles/components/common/ProjectManagementLoader.scss';

const ProjectManagementLoader = () => {
  return (
    <div className="project-loader">
      {Array(3).fill().map((_, index) => (
        <div className="project-item" key={index}>
          <div className="project-name">
            <Skeleton width={250} height={18} />
          </div>
          <div className="filter-options">
            <Skeleton className="filter" width={70} height={15} />
            <Skeleton className="filter" width={70} height={15} />
            <Skeleton className="filter" width={70} height={15} />
            <Skeleton className="filter" width={70} height={15} />
          </div>
        </div>
      ))}
    </div>
  );
};

export default ProjectManagementLoader;
