import React, { useState, useRef, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Form } from "react-bootstrap";
import styles from "../../assets/styles/components/tender-list/TenderComment.module.scss";
import {
  saveComments,
  saveReply,
  editReply,
  editComments,
  getTenderComments,
} from "../../store/actions/tenderActions";
import userAvatar from "../../assets/img/user.png";

const CommentInput = ({
  type = "question",
  parant_reply_id = 0,
  comment_id = 0,
  tenderId,
  isFocus = false,
  editId = 0,
  editText = "",
  commentOwnerId = "",
}) => {
  const inputRef = useRef(null);
  const [value, setValue] = useState("");
  const [isChecked, setIsChecked] = useState(false);
  const dispatch = useDispatch();
  const UserData = useSelector((state) => state.auth.userInfo);

  const UserId = UserData.id;

  const handleCheckboxChange = (e) => {
    setIsChecked(e.target.checked);
  };

  const getRecentComment = () => {
    if (tenderId) {
      setValue("");
      const data = {
        page: 1,
        per_page: 10,
        tender_id: tenderId,
      };
      dispatch(getTenderComments(data));
    }
  };

  useEffect(() => {
    setValue("");
  }, []);

  useEffect(() => {
    if (isFocus && inputRef.current) {
      inputRef.current.focus();
    }
  }, [isFocus]);

  useEffect(() => {
    setValue(editText);
    inputRef?.current && inputRef.current.focus();
  }, [editId, editText]);

  const saveComment = () => {
    if (type === "question") {
      // create questions
      const data = {
        tender_id: tenderId,
        question: value,
      };
      dispatch(saveComments(data)).then((response) => {
        if (response?.payload?.status === 200) {
          setValue("");
          getRecentComment();
        }
      });
    } else {
      const data = {
        // create reply
        comment_id: comment_id,
        answer: value,
        is_close: isChecked ? 1 : 0,
        parent_reply_id: parant_reply_id, // this use if user reply agains reply
      };

      dispatch(saveReply(data)).then((response) => {
        if (response?.payload?.status === 200) {
          setValue("");
          getRecentComment();
        }
      });
    }
  };

  const editCommentOrReply = () => {
    if (parant_reply_id == 0) {
      // edit question
      const data = {
        id: editId,
        question: value,
        is_close: isChecked ? 1 : 0,
      };

      dispatch(editComments(data)).then((response) => {
        if (response?.payload?.status === 200) {
          setValue("");
          getRecentComment();
        }
      });
    } else {
      const data = {
        // edit  reply
        id: editId,
        answer: value,
        is_close: isChecked ? 1 : 0,
      };

      dispatch(editReply(data)).then((response) => {
        if (response?.payload?.status === 200) {
          setValue("");
          getRecentComment();
        }
      });
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      if (editId == 0) {
        saveComment();
      } else {
        editCommentOrReply();
      }
    }
  };
  return (
    <div>
      {/* {type != "question" && commentOwnerId === UserId && (
        <div className={` mt-2`}>
          <Form.Check
            type="checkbox"
            label="Close Chat"
            checked={isChecked}
            onChange={handleCheckboxChange}
          />
        </div>
      )} */}
      <div className={`${styles.write_comment} mt-2`}>
        <img
          src={userAvatar}
          alt="Profile"
          className="rounded-circle mr-2"
          width={40}
          height={40}
        />
        <input
          ref={inputRef}
          value={value}
          type="text"
          className="form-control me-2 "
          placeholder={
            type === "question" ? "Write a comment" : "Write a reply here"
          }
          onChange={(e) => setValue(e.target.value)}
          style={{ borderColor: "#FFCC00", boxShadow: "none" }}
          onKeyPress={handleKeyPress}
        />
        <button
          className={styles.send_button}
          onClick={() => (editId == 0 ? saveComment() : editCommentOrReply())}
          disabled={value == ""}
        >
          <i className={`${styles.ri_send_plane_fill} ri-send-plane-fill`}></i>
        </button>
      </div>
    </div>
  );
};

export default CommentInput;
