import React, { useState } from "react";
import Select from "react-select";

const customStyles = {
  control: (provided, state) => ({
    ...provided,
    minWidth: "200px",
    color: "black",
    boxShadow: state.isFocused ? 'none' : provided.boxShadow,
    borderColor: state.isFocused ? provided.borderColor : provided.borderColor,
    '&:hover': {
      borderColor: state.isFocused ? provided.borderColor : provided.borderColor,
    },
  }),
  menu: (provided) => ({
    ...provided,
    minWidth: "200px",
    color: "black",
    zIndex: 1050,
  }),
  multiValue: (provided) => ({
    ...provided,
    backgroundColor: "#444",
    color: "white",
  }),
  multiValueLabel: (provided) => ({
    ...provided,
    color: "white",
  }),
  multiValueRemove: (provided) => ({
    ...provided,
    color: "white",
    ":hover": {
      backgroundColor: "#ff5630",
      color: "white",
    },
  }),
};

const CustomSearchSelect = ({
  options,
  selectedOption,
  handleChange,
  isMultiValue = false,
  disabled = false,
}) => {
  return (
    <div style={ { minWidth: "100px", zIndex: 1 } }>
      <Select
        value={ selectedOption }
        onChange={ handleChange }
        options={ options }
        styles={ customStyles }
        isSearchable
        placeholder="Search and select..."
        isMulti={ isMultiValue }
        isDisabled={ disabled }
      />
    </div>
  );
};

export default CustomSearchSelect;
