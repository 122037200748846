import React from "react";
import BidsAccordion from "./BidsAccordion";
import { Alert } from "react-bootstrap";
import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';
import "../../../assets/styles/components/tender-list/TenderBid.scss";

const TenderBid = ({
  bidList = {},
  tenderAddUser,
  remainingDays,
  iscustomerQuotations,
  tenderStatus
}) => {
  const bidListItems = bidList.companyQuotations || [];

  return (
    <>
      { bidListItems.length > 0 ? (
        <div>
          <Accordion className='accordion-custom-green'>
            <Accordion.Item as={ Card.Header } eventKey="0">
              <Accordion.Header >
                <i className="ri-file-text-fill"></i>
                <p className="mb-0">
                  { bidListItems.length }{ " " }
                  <strong>bid{ bidListItems.length > 1 ? "'s" : "" }</strong> have
                  been received for this tender.
                </p>
              </Accordion.Header>
              <Accordion.Body>
                <div>
                  <BidsAccordion
                    bidListItems={ bidListItems }
                    tenderAddUser={ tenderAddUser }
                    remainingDays={ remainingDays }
                    iscustomerQuotations={ iscustomerQuotations }
                    tenderStatus={ tenderStatus }
                  />
                </div>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
      ) : (
        <Alert
          variant="primary"
          className="d-flex align-items-center mb-2 mt-4"
        >
          <i className="ri-information-line"></i>
          <p className="mb-0">
            No <strong>bids</strong> have been received for this tender.
          </p>
        </Alert>
      ) }
    </>
  );
};

export default TenderBid;
