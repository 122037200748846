import React, {useState, useEffect} from "react";
import MenuBar from "../components/home/MenuBar";
import SupportBanner from "../assets/img/FAQ_img.jpg";
import Footer from "../components/home/Footer";
import { Accordion, Button, Row } from 'react-bootstrap';
import { FAQDataList, FAQCategoriesList } from "../utils/types";
import "../assets/styles/pages/FAQ.scss";
import { useNavigate } from "react-router-dom";

function FrequentlyAskedQuestions() {
    const navigate = useNavigate();
    const [selectedCategory, setSelectedCategory] = useState(FAQCategoriesList[0]);
    const [selectedDataList, setSelectedDataList] = useState([]);

    useEffect(() => {
        const filteredData = FAQDataList.filter((data) => data.category === selectedCategory);
        setSelectedDataList(filteredData)
    }, [selectedCategory])

    return (
        <div className="page-home">
            <MenuBar />
            <div className="position-relative" style={{ height: '400px', marginTop: '-137px' }}>
                <div
                    className="position-absolute top-0 start-0 w-100 h-100"
                    style={{
                        backgroundImage: `url(${SupportBanner})`,
                        backgroundSize: 'cover',
                    }}
                ></div>
                <div
                    className="position-absolute top-0 start-0 w-100 h-100"
                    style={{ backgroundColor: 'rgba(35, 35, 64, 0.5)' }}
                ></div>
                <div className="position-relative" style={{ zIndex: 1 }}>
                </div>
            </div>



            <div className="work-preference pd-top-60 pd-bottom-60 bg-light-gray">
                <div className="container">
                    <Row>
                        <div className="d-flex flex-column col-md-4 gap-1">
                            {FAQCategoriesList && FAQCategoriesList.map((cat) => (
                                <div className={`btn-text ${selectedCategory === cat ? "selected" : ""}`} onClick={()=>setSelectedCategory(cat)}>{cat}</div>
                            ))
                            }
                        </div>
                        <div className="col-md-8">
                            <h3 className="mb-3">{selectedCategory}</h3>
                            <Accordion alwaysOpen>
                                {selectedDataList.map((item, index) => (
                                    <Accordion.Item eventKey={index.toString()} key={index} className="accordion-item">
                                        <Accordion.Header>
                                            <b>{index + 1} .</b>
                                            <b>{item.question}</b>
                                        </Accordion.Header>
                                        <Accordion.Body>
                                            {item.answer}
                                            {item.points.length > 0 &&
                                                <ul>
                                                    {item.points.map((point) => {
                                                        return (
                                                            <li>{point}</li>
                                                        )
                                                    })}
                                                </ul>
                                            }
                                        </Accordion.Body>
                                    </Accordion.Item>
                                ))}
                            </Accordion>
                        </div>
                    </Row>
                    <div className="bottom-container">
                        <h3>Still got question?</h3>
                        <p>We are here to assist you with any issues or questions you may have regarding our services.</p>
                        <Button onClick={() => navigate("/help_and_support")} className="btn">Contact Us</Button>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default FrequentlyAskedQuestions