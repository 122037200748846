import React from 'react';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import '../../assets/styles/components/common/ProjectsLoading.scss';

const ProjectsLoading = () => {
  return (
    <div className="card-loader2">
      {Array(3).fill().map((_, index) => (
        <div className="card2" key={index}>
          <Skeleton className="card-image2" />
          <Skeleton className="card-title" />
          <Skeleton className="card-description" count={2} />
          <Skeleton className="card-button" />
        </div>
      ))}
    </div>
  );
};

export default ProjectsLoading;
