import { createBrowserRouter } from "react-router-dom";
import RoleBasedRoute from "./RoleBasedRoute";
import { useSelector } from "react-redux";

//Guest pages
import Home from "../pages/Home";
import NotFound from "../pages/NotFound";
import RejectQuotation from "../pages/RejectQuotation";
import HelpAndSupport from "../pages/HelpAndSupport";
import FrequentlyAskedQuestions from "../pages/FrequentlyAskedQuestions";
import TermsAndConditions from "../pages/TermsAndConditions";
import PrivacyAndPolicy from "../pages/PrivacyAndPolicy";
import AboutUsPage from "../pages/AboutUsPage";

//Auth pages
import Signin from "../pages/Signin";
import Signup from "../pages/Signup";
import VerifyAccount from "../pages/VerifyAccount";
import ForgotPassword from "../pages/ForgotPassword";
import PackageTermination from "../pages/PackageTermination";

//Dashboard pages
import BlankPage from "../pages/dashboard/BlankPage";
import Dashboard from "../pages/dashboard/Dashboard";
import MyProfile from "../pages/dashboard/MyProfile";
import UserPermissions from "../pages/dashboard/UserPermissions";
import UserManage from "../pages/dashboard/UserManage";
import OnboardUsers from "../pages/dashboard/OnboardUsers";
import AllTenders from "../pages/dashboard/AllTenders";
import TenderViewPage from "../pages/dashboard/TenderView";
import CustomerQuotation from "../pages/dashboard/CustomerQuotation";
import MyProjects from "../pages/dashboard/MyProjects";
import ProjectView from "../pages/dashboard/ProjectView";
import FeedbackAnalysis from "../pages/dashboard/reports/FeedbackAnalysis";
import OrderReports from "../pages/dashboard/reports/OrderReports";
import QuotationReports from "../pages/dashboard/reports/QuotationReports";
import AllTendersForBided from "../pages/dashboard/AllTendersForBided";
import ProjectsManage from "../pages/dashboard/ProjectsManage";
import AcceptQuotation from "../pages/dashboard/AcceptQuotation";
import PricingPackages from "../pages/dashboard/PricingPackages";
import SubscriptionPackges from "../pages/dashboard/SubscriptionPackges";
import GeneralFeedbackReports from "../pages/Report/FeedbackReports";
import TenderReport from "../pages/Report/TenderReport";
import UserReport from "../pages/Report/UserReport";
import ProjectReport from "../pages/Report/ProjectReport";

//Onboard pages
import UsersOnboard from "../pages/onboard/Onboard";
import ProjectChat from "../pages/dashboard/ProjectChat";

//Error pages

//Layouts
import GuestLayout from "../layout/GuestLayout";
import OnboardLayout from "../layout/OnboardLayout";
import DashboardLayout from "../layout/DashboardLayout";

const Router = createBrowserRouter([
  {
    path: "/",
    element: <GuestLayout />,
    children: [
      {
        path: "/",
        element: <Home />,
      },
      {
        path: "/signin",
        element: <Signin />,
      },
      {
        path: "/signup",
        element: <Signup />,
      },
      {
        path: "/activate/",
        element: <VerifyAccount />,
      },
      {
        path: "/forgot-password",
        element: <ForgotPassword />,
      },
      {
        path: "/reject_quotation",
        element: <RejectQuotation />,
      },
      {
        path: "/help_and_support",
        element: <HelpAndSupport />,
      },
      {
        path: "/frequently_asked_questions",
        element: <FrequentlyAskedQuestions />,
      },
      {
        path: "/terms_and_conditions",
        element: <TermsAndConditions />,
      },
      {
        path: "/privacy_and_Policy",
        element: <PrivacyAndPolicy />,
      },{
        path: "/about_us",
        element: <AboutUsPage />,
      },
    ],
  },
  {
    path: "/",
    element: <OnboardLayout />,
    children: [
      {
        path: "/onboard",
        element: <UsersOnboard />,
      },
    ],
  },
  {
    path: "/",
    element: <DashboardLayout />,
    children: [
      {
        name: "Dashboard",
        path: "/dashboard",
        element: (
          <RoleBasedRoute
            element={ <Dashboard /> }
            requiredRoles={ ["1", "2", "3", "4", "5"] }
          />
        ),
        icon: <i class="ri-dashboard-line" />,
      },
      {
        name: "My Profile",
        path: "/my_profile",
        element: (
          <RoleBasedRoute
            element={ <MyProfile /> }
            requiredRoles={ ["1", "2", "3", "4", "5"] }
          />
        ),
      },
      {
        name: "Manage User Accounts",
        path: "/manage_user_accounts",
        element: (
          <RoleBasedRoute element={ <UserManage /> } requiredRoles={ ["1", "3"] } />
        ),
        icon: <i class="ri-user-settings-line" />,
      },
      {
        name: "User Onboard",
        path: "/user_onboard",
        element: (
          <RoleBasedRoute element={ <OnboardUsers /> } requiredRoles={ ["1", "3"] } />
        ),
        icon: <i class="ri-user-add-fill" />,
      },
      {
        name: "User Permissions",
        path: "/user_module_permision",
        element: (
          <RoleBasedRoute element={ <UserPermissions /> } requiredRoles={ ["1"] } />
        ),
        icon: <i class="ri-shield-user-line" />,
      },
      {
        name: "All Tenders",
        path: "/tender_list",
        element: (
          <RoleBasedRoute element={ <AllTenders /> } requiredRoles={ ["3"] } />
        ),
        icon: <i class="ri-list-check-2" />,
      },
      {
        path: "/tender-view",
        element: (
          <RoleBasedRoute element={ <TenderViewPage /> } requiredRoles={ ["3"] } />
        ),
      },
      {
        name: "Bids on External Tenders",
        path: "/bids_on_external_tenders",
        element: (
          <RoleBasedRoute
            element={ <AllTendersForBided /> }
            requiredRoles={ ["3"] }
          />
        ),
        icon: <i class="ri-list-check-2" />,
      },

      {
        path: "/customer_quotation",
        element: (
          <RoleBasedRoute
            element={ <CustomerQuotation /> }
            requiredRoles={ ["3"] }
          />
        ),
      },
      {
        name: "Accept Quotation",
        path: "/accept_quotation/",
        element: (
          <RoleBasedRoute element={ <AcceptQuotation /> } requiredRoles={ ["2"] } />
        ),
        icon: <i class="ri-list-check-2" />,
      },
      {
        name: "My Projects",
        path: "/my_projects",
        element: (
          <RoleBasedRoute
            element={ <MyProjects /> }
            requiredRoles={ ["2", "3", "4", "5"] }
          />
        ),
        icon: <i class="ri-funds-box-line" />,
      },
      {
        path: "/project-view",
        element: (
          <RoleBasedRoute
            element={ <ProjectView /> }
            requiredRoles={ ["2", "3", "4", "5"] }
          />
        ),
        icon: <i class="ri-funds-box-line" />,
      },
      {
        path: "/project-chat",
        element: (
          <RoleBasedRoute
            element={ <ProjectChat /> }
            requiredRoles={ ["2", "3", "4", "5"] }
          />
        ),
        icon: <i class="ri-funds-box-line" />,
      },
      {
        name: "Project Management",
        path: "/project_management",
        element: (
          <RoleBasedRoute
            element={ <ProjectsManage /> }
            requiredRoles={ ["2", "3", "4", "5"] }
          />
        ),
        icon: <i class="ri-funds-box-line" />,
      },
      {
        path: "/pricing_packages",
        element: (
          <RoleBasedRoute
            element={ <PricingPackages /> }
            requiredRoles={ ["2", "3", "4", "5"] }
          />
        ),
        icon: <i class="ri-funds-box-line" />,
      },
      {
        path: "/upgrade-package",
        element: (
          <RoleBasedRoute
            element={ <PackageTermination /> }
            requiredRoles={ ["3", "4", "5"] }
          />
        ),
        icon: <i class="ri-funds-box-line" />,
      },
      {
        name: "Package Limitaions",
        path: "/package_limitaions",
        element: (
          <RoleBasedRoute
            element={ <SubscriptionPackges /> }
            requiredRoles={ ["1"] }
          />
        ),
        icon: <i class="ri-funds-box-line" />,
      },
      {
        name: "Tracking",
        path: "/order_tracking",
        element: <Dashboard />,
        icon: <i class="ri-dashboard-2-line" />,
      },
      {
        name: "Order Reports",
        path: "/order_reports",
        element: (
          <RoleBasedRoute element={ <OrderReports /> } requiredRoles={ ["3"] } />
        ),
        icon: <i class="ri-dashboard-2-line" />,
      },
      {
        name: "Feedback Analysis",
        path: "/feedback_analysis",
        element: (
          <RoleBasedRoute
            element={ <FeedbackAnalysis /> }
            requiredRoles={ ["1", "3"] }
          />
        ),
        icon: <i class="ri-dashboard-2-line" />,
      },
      {
        name: "Quotation Reports",
        path: "/quotation_analysis",
        element: (
          <RoleBasedRoute
            element={ <QuotationReports /> }
            requiredRoles={ ["3"] }
          />
        ),
        icon: <i class="ri-dashboard-2-line" />,
      },
      {
        name: "Feedback Reports",
        path: "/feedback_reports",
        element: (
          <RoleBasedRoute
            element={ <GeneralFeedbackReports /> }
            requiredRoles={ ["1", "3"] }
          />
        ),
        icon: <i class="ri-file-chart-line" />,
      },
      {
        name: "Tender Reports",
        path: "/tender_reports",
        element: (
          <RoleBasedRoute
            element={ <TenderReport /> }
            requiredRoles={ ["1", "3"] }
          />
        ),
        icon: <i class="ri-file-chart-line" />,
      },
      {
        name: "User Reports",
        path: "/user_reports",
        element: (
          <RoleBasedRoute
            element={ <UserReport /> }
            requiredRoles={ ["1"] }
          />
        ),
        icon: <i class="ri-file-chart-line" />,
      },
      {
        name: "Project Reports",
        path: "/project_reports",
        element: (
          <RoleBasedRoute
            element={ <ProjectReport /> }
            requiredRoles={ ["3", "2"] }
          />
        ),
        icon: <i class="ri-file-chart-line" />,
      },
      {
        name: "Chat",
        path: "/single_project_chat",
        element: <Dashboard />,
        icon: <i class="ri-dashboard-2-line" />,
      },
    ],
  },
  {
    path: "*",
    element: <NotFound />,
  },
]);

export default Router;
