import React, { useState } from "react";
import { useSelector } from "react-redux";
import Footer from "../../components/dashboard/Footer";
import TenderListForBided from "../../components/dashboard/tenders/TenderListForBided";
import SearchBarSelection from "../../components/common/SearchBarSelection";

const AllTendersForBided = () => {
  const moduleName = "Bids on External Tenders";
  const { loading, tendersForbidedData } = useSelector((state) => state.tender);
  const [searchCategory, setSearchCategory] = useState("");

  const handleSearch = (value) => {
    setSearchCategory(value);
  };

  return (
    <>
      <div className="main main-app p-3 p-lg-4">
        <div className="d-flex align-items-center justify-content-between mb-4">
          <div>
            <ol className="breadcrumb fs-sm mb-1">
              <li className="breadcrumb-item" aria-current="page">
                Dashboard
              </li>
              <li className="breadcrumb-item active">{moduleName}</li>
            </ol>
            <h4 className="main-title mb-0">{moduleName}</h4>
          </div>
        </div>
        <div className="d-flex align-items-center justify-content-between mb-4">
          <div className="w-50">
            <SearchBarSelection
              onChange={(value) => handleSearch(value)}
              categoryList={tendersForbidedData?.tender_category || []}
              handleClearSearch={() => setSearchCategory(0)}
            />
          </div>
        </div>
        <div className="inner-wrapper">
          <TenderListForBided searchCategory={searchCategory} />
        </div>
        <Footer />
      </div>
    </>
  );
};

export default AllTendersForBided;
