// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.card-loader2 {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
}
@media (max-width: 768px) {
  .card-loader2 {
    grid-template-columns: 1fr;
  }
}

.card2 {
  background-color: #fff;
  border-radius: 8px;
  padding: 16px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}
.card2 .card-image2 {
  width: 100%;
  height: 180px;
  border-radius: 8px;
  margin-bottom: 16px;
}
.card2 .card-title {
  width: 60%;
  height: 20px;
  margin-bottom: 8px;
}
.card2 .card-description {
  width: 100%;
  height: 14px;
  margin-bottom: 8px;
}
.card2 .card-button {
  width: 30%;
  height: 20px;
  margin-top: 16px;
}`, "",{"version":3,"sources":["webpack://./src/assets/styles/components/common/ProjectsLoading.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,qCAAA;EACA,SAAA;AACJ;AACI;EALJ;IAMM,0BAAA;EAEJ;AACF;;AACE;EACE,sBAAA;EACA,kBAAA;EACA,aAAA;EACA,wCAAA;AAEJ;AAAI;EACE,WAAA;EACA,aAAA;EACA,kBAAA;EACA,mBAAA;AAEN;AACI;EACE,UAAA;EACA,YAAA;EACA,kBAAA;AACN;AAEI;EACE,WAAA;EACA,YAAA;EACA,kBAAA;AAAN;AAGI;EACE,UAAA;EACA,YAAA;EACA,gBAAA;AADN","sourcesContent":[".card-loader2 {\n    display: grid;\n    grid-template-columns: repeat(3, 1fr);\n    gap: 20px;\n  \n    @media (max-width: 768px) {\n      grid-template-columns: 1fr;\n    }\n  }\n  \n  .card2 {\n    background-color: #fff;\n    border-radius: 8px;\n    padding: 16px;\n    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);\n  \n    .card-image2 {\n      width: 100%;\n      height: 180px;\n      border-radius: 8px;\n      margin-bottom: 16px;\n    }\n  \n    .card-title {\n      width: 60%;\n      height: 20px;\n      margin-bottom: 8px;\n    }\n  \n    .card-description {\n      width: 100%;\n      height: 14px;\n      margin-bottom: 8px;\n    }\n  \n    .card-button {\n      width: 30%;\n      height: 20px;\n      margin-top: 16px;\n    }\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
