import { createAsyncThunk } from "@reduxjs/toolkit";
import { BlobServiceClient } from "@azure/storage-blob";
import { get } from "../../servces/Api";
import Alert from "../../components/common/Alert";
import queryString from "query-string";
import Cookies from "js-cookie";

import { getSasToken } from "../../utils/utils";

// Environment variables
const storageAccountName = process.env.REACT_APP_STORAGE_ACCOUNT_NAME;
const containerName = process.env.REACT_APP_STORAGE_CONTAINER_NAME;

// Parse the SAS token to extract query parameters
const parseSasToken = (sasToken) => {
  const token = sasToken.startsWith("?") ? sasToken.slice(1) : sasToken;
  return queryString.parse(token);
};

// Check if the SAS token is expired
const isTokenExpired = (sasToken) => {
  const params = parseSasToken(sasToken);
  if (params.se) {
    const expirationDate = new Date(params.se);
    const now = new Date();
    return now > expirationDate;
  }
  return true;
};

// Calculate how many days until the token expires
const calculateDaysUntilExpiration = (sasToken) => {
  const params = parseSasToken(sasToken);
  if (params.se) {
    const expirationDate = new Date(params.se);
    const now = new Date();
    const diffTime = Math.abs(expirationDate - now);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)); // Convert milliseconds to days
    return diffDays;
  }
  return 1; // Default to 1 day if expiration is not present
};

// Fetch a new SAS token from the server
const fetchNewSasToken = async () => {
  const response = await get("/api/generate_sas");
  const newSasToken = response.data.sasToken;

  // Calculate the expiration days and set the token in cookies
  const daysUntilExpiration = calculateDaysUntilExpiration(newSasToken);
  Cookies.set("sasToken", newSasToken, {
    expires: daysUntilExpiration,
  });

  return newSasToken;
};

// Fetch or renew SAS token if expired
export const fetchSasToken = createAsyncThunk("file/getsass", async () => {
  try {
    // Get the SAS token from cookies
    let sasToken = Cookies.get("sasToken");

    // Check if the token is expired
    if (!sasToken || isTokenExpired(sasToken)) {
      // Fetch a new token if expired or not present
      sasToken = await fetchNewSasToken();
    }

    return sasToken;
  } catch (error) {
    console.error("Error fetching SAS token", error);
    throw error;
  }
});

// Azure blob file delete function
const deleteFile = async (blobName, sasToken) => {
  const blobServiceClient = new BlobServiceClient(
    `https://${storageAccountName}.blob.core.windows.net?${sasToken}`
  );
  const containerClient = blobServiceClient.getContainerClient(containerName);
  const blobClient = containerClient.getBlobClient(blobName);

  try {
    await blobClient.delete();
    Alert.success(`Blob "${blobName}" deleted successfully.`);
    return { success: true };
  } catch (error) {
    Alert.error(`Error deleting blob "${blobName}". Please try again later.`);
    throw error;
  }
};

// Thunk for deleting a blob file
export const deleteBlobFile = createAsyncThunk(
  "file/deleteBlob",
  async ({ blobName }, thunkAPI) => {
    try {
      // Ensure the SAS token is up-to-date
      const sasToken = await thunkAPI.dispatch(fetchSasToken()).unwrap();

      // Delete the file using the valid SAS token
      const result = await deleteFile(blobName, sasToken);
      return result;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);
