import React from "react";
import DataTable from "react-data-table-component";
import TableLoader from "./TableLoader";
import { Alert } from "react-bootstrap";

const PaginationWithDynamicData = ({
  columns,
  rows,
  loading,
  pagination = true,
  columnCount,
  paginationOptions,
  handlePageChange,
  handlePerPageChange,
}) => {
  const customStyles = {
    headRow: {
      style: {
        backgroundColor: "#ccd2da",
        minHeight: 40,
      },
    },
    headCells: {
      style: {
        fontSize: "0.8125rem",
        borderBottom: "1px solid #ddd", // Border for header cells
      },
    },
    cells: {
      style: {
        borderLeft: "1px solid #ddd", // Border for regular cells
        borderRight: "1px solid #ddd", // Border for regular cells
      },
    },
  };

  return (
    <DataTable
      columns={columns}
      data={rows || []}
      pagination={pagination}
      paginationTotalRows={paginationOptions.totalRows}
      paginationPerPage={paginationOptions.perPage}
      paginationServer
      paginationDefaultPage={paginationOptions.currentPage}
      paginationRowsPerPageOptions={[10, 30, 60, 100]} // Example additional options
      onChangePage={handlePageChange}
      onChangeRowsPerPage={handlePerPageChange}
      progressPending={loading}
      progressComponent={
        <TableLoader
          rowCount={4}
          columnCount={columnCount}
        />
      }
      noDataComponent={
        !loading && (
          <Alert
            variant="primary"
            className="d-flex align-items-center mb-2 w-100"
          >
            <i className="ri-information-line"></i> No data available in the
            table.
          </Alert>
        )
      }
      responsive
      striped
      highlightOnHover
      customStyles={customStyles}
    />
  );
};

export default PaginationWithDynamicData;
