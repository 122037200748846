// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.project-loader {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.project-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  background-color: #f9f9f9;
}
.project-item .filter-options {
  display: flex;
  gap: 10px;
}
.project-item .filter-options .filter {
  border-radius: 4px;
}
.project-item .project-name {
  display: flex;
  align-items: center;
}`, "",{"version":3,"sources":["webpack://./src/assets/styles/components/common/ProjectManagementLoader.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,sBAAA;EACA,SAAA;AACJ;;AAEE;EACE,aAAA;EACA,mBAAA;EACA,8BAAA;EACA,aAAA;EACA,yBAAA;EACA,kBAAA;EACA,yBAAA;AACJ;AACI;EACE,aAAA;EACA,SAAA;AACN;AACM;EACE,kBAAA;AACR;AAGI;EACE,aAAA;EACA,mBAAA;AADN","sourcesContent":[".project-loader {\n    display: flex;\n    flex-direction: column;\n    gap: 10px;\n  }\n  \n  .project-item {\n    display: flex;\n    align-items: center;\n    justify-content: space-between;\n    padding: 12px;\n    border: 1px solid #e0e0e0;\n    border-radius: 8px;\n    background-color: #f9f9f9;\n  \n    .filter-options {\n      display: flex;\n      gap: 10px;\n  \n      .filter {\n        border-radius: 4px;\n      }\n    }\n  \n    .project-name {\n      display: flex;\n      align-items: center;\n    }\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
