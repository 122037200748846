import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchDashboardData } from "../../store/actions/dashboardActions";
import { Card, Col, Nav, Row, Alert } from "react-bootstrap";
import { formatDate } from "../../utils/utils";
import CountLoader from "../dashboard/ContLoader";
import ListLoader from "../dashboard/ListLoader";
import Avatar from "../common/Avatar";

const CustomerDashboard = () => {
  const dispatch = useDispatch();
  const { isLoading, error, dashboardData } = useSelector((state) => state.dashboard);
  const colCount = 4;

  useEffect(() => {
    dispatch(fetchDashboardData());
  }, [dispatch]);

  const countData = [
    {
      label: "Ongoing projects",
      icon: "ri-briefcase-line",
      value: dashboardData?.data?.customer_projects_count_pending || 0,
      smallText: "Ongoing projects"
    },
    {
      label: "Completed projects",
      icon: "ri-briefcase-line",
      value: dashboardData?.data?.customer_projects_count_completed || 0,
      smallText: "Completed projects"
    },
    {
      label: "Completed tasks",
      icon: "ri-checkbox-line",
      value: dashboardData?.data?.completed_task_by_projects || 0,
      smallText: "Projects completed tasks"
    },
    {
      label: "Pending tasks",
      icon: "ri-file-list-line",
      value: dashboardData?.data?.pending_task_by_projects || 0,
      smallText: "Projects pending tasks"
    },
  ];

  return (
    <>
      {isLoading ? (
        <CountLoader colCOunt={colCount} />
      ) : (
        <Row>
          {countData.map((card, index) => {

            const getBackgroundColor = (type) => {
              switch (type) {
                case 'Ongoing projects':
                  return '#c9ebf280';
                case 'Completed projects':
                  return '#c0ebe180';
                case 'Completed tasks':
                  return '#c6ecd9';
                case 'Pending tasks':
                  return '#f2e6ff';
                default:
                  return '#ffffff';
              }
            };

            const getColor = (type) => {
              switch (type) {
                case 'Ongoing projects':
                  return '#45a2b5';
                case 'Completed projects':
                  return '#43a18b';
                case 'Completed tasks':
                  return '#40bf80';
                case 'Pending tasks':
                  return '#8080ff';
                default:
                  return '#ffffff';
              }
            };

            return (
              <Col xs="6" xl="3" key={index}>
                <Card
                  className="card-one"
                  style={{
                    background: `linear-gradient(to bottom, ${getBackgroundColor(card.label)}, #ffffff)`,
                    border: `2px solid ${getBackgroundColor(card.label)}`
                  }}
                >
                  <Card.Body>
                    <Card.Title as="label" className="fs-sm fw-medium mb-1">
                      {card.label}
                    </Card.Title>
                    <h3 className="card-value mb-1">
                      <i style={{ color: getColor(card.label) }} className={card.icon}></i> {card.value}
                    </h3>
                    <small>{card.smallText}</small>
                  </Card.Body>
                </Card>
              </Col>
            );
          })}
        </Row>
      )}

      <Row className="g-3 mt-2">
        {isLoading ? (
          <ListLoader rowCount={10} xlCol={12} />
        ) : (
          <Col md="6" xl="12">
            <Card className="card-one">
              <Card.Header>
                <Card.Title as="h6">All projects</Card.Title>
              </Card.Header>
              <Card.Body className="p-0">
                <ul className="people-group">
                  {dashboardData?.data?.customer_projects_list?.length ? (
                    dashboardData.data.customer_projects_list.map((project, index) => (
                      <li className="people-item" key={index}>
                        <Avatar initial={project.project_title.charAt(0)} />
                        <div className="people-body">
                          <h6>
                            <Link to={`/project-view?projectId=${project.id}&projectName=${project.project_title}`}>
                              {project.project_title}
                            </Link>
                          </h6>
                          <span className="three-lines">{project.project_description}</span>
                          <span>
                            <strong className="text-danger">Deadline: </strong>
                            {formatDate(project.project_deadline)}
                          </span>
                        </div>
                        <Nav as="nav" className="nav-icon">
                          <Link to={`/project-view?projectId=${project.id}&projectName=${project.project_title}`}>
                            <i className="ri-arrow-right-s-line" style={{ fontSize: 24 }}></i>
                          </Link>
                        </Nav>
                      </li>
                    ))
                  ) : (
                    <li className="people-item">
                      <div className="people-body">
                        <Alert variant="primary" className="d-flex align-items-center mt-2 mb-2">
                          <small>No projects available</small>
                        </Alert>
                      </div>
                    </li>
                  )}
                </ul>
              </Card.Body>
              {dashboardData?.data?.ongoing_project_list?.length !== 0 ? <Card.Footer className="d-flex justify-content-start">
                <Link to="/my_projects" className="fs-sm">
                  All projects
                </Link>
              </Card.Footer> : ''}

            </Card>
          </Col>
        )}
      </Row >
    </>
  );
};


export default CustomerDashboard