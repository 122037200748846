import React, { useState, useEffect } from "react";
import { useLocation, Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Badge, Col, Container, Image, Row, Button, Spinner } from "react-bootstrap";

import Footer from "../../components/dashboard/Footer";
import Alert from "../../components/common/Alert";
import { getSasToken } from "../../utils/utils";
import useAuth from "../../hooks/useAuth";
import { fetchProjectInfo, markProjectCompleted } from "../../store/actions/projectActions";
import ProjectInfo from "../../components/dashboard/projects/ProjectInfo";
import TenderViewLoading from "./TenderViewLoading";
import ConfirmModal from "../../components/common/ConfirmModal";
import styles from "../../assets/styles/components/tender-list/TenderView.module.scss";
import DefaultImg from "../../assets/img/CardImage.jpg";

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const ProjectView = () => {
  const query = useQuery();
  const projectId = query.get("projectId");
  const projectName = query.get("projectName");
  const moduleName = projectName;
  const dispatch = useDispatch();
  const { loading, projectInfoData } = useSelector((state) => state.project);

  const [currentImgSrc, setCurrentImgSrc] = useState(DefaultImg);
  const sasToken = getSasToken();
  const storageAccountName = process.env.REACT_APP_STORAGE_ACCOUNT_NAME;
  const containerName = process.env.REACT_APP_STORAGE_CONTAINER_NAME;

  const isAuthenticated = useAuth();
  const projectOwner =
    isAuthenticated.id === projectInfoData?.owner?.id ||
    isAuthenticated.id === projectInfoData?.user_id.id;

  // State to control modal visibility and loading
  const [showModal, setShowModal] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    if (projectInfoData?.images) {
      const fileUrl = `https://${storageAccountName}.blob.core.windows.net/${containerName}/${projectInfoData.images}?${sasToken}`;
      setCurrentImgSrc(fileUrl);
    } else {
      setCurrentImgSrc(DefaultImg);
    }
  }, [projectInfoData, sasToken, storageAccountName, containerName]);

  useEffect(() => {
    dispatch(fetchProjectInfo(projectId));
  }, [dispatch, projectId]);

  const handleImageError = () => {
    setCurrentImgSrc(DefaultImg);
  };

  // Mark project as completed action
  const handleMarkProjectAsCompleted = () => {
    setIsSubmitting(true);

    const data = {
      is_completed: "1",
      project_id: projectId,
    };

    dispatch(markProjectCompleted(data))
      .then((response) => {
        setIsSubmitting(false);
        setShowModal(false);
        dispatch(fetchProjectInfo(projectId));
        if (response.payload.message === "Some tasks in this project are still incomplete, so the project cannot be marked as completed.") {
          Alert.error(response.payload.message);
        } else {
          Alert.success(response.payload.message);
        }

      })
      .catch(() => {
        setIsSubmitting(false); // Hide the loading spinner in case of error
      });
  };

  // Show confirmation modal when button is clicked
  const handleMarkAsCompletedClick = () => {
    setShowModal(true);
  };

  // Hide the modal
  const handleModalHide = () => {
    setShowModal(false);
  };

  return (
    <>
      <div className="main main-app tender-view p-3 p-lg-4">
        { loading ? (
          <TenderViewLoading />
        ) : (
          <>
            <div className="border border-secondary">
              <Container fluid className="p-0">
                <Row className="m-0">
                  <Col className="p-0">
                    <div className={ styles.banner_container }>
                      <Image
                        src={ currentImgSrc }
                        className={ styles.banner_image }
                        fluid
                        onError={ handleImageError }
                      />
                      <div className={ styles.overlay_content }>
                        <div>
                          <ol className="breadcrumb fs-sm mb-1">
                            <li className="breadcrumb-item" aria-current="page">
                              <Link to="/dashboard">Dashboard</Link>
                            </li>
                            <li className="breadcrumb-item" aria-current="page">
                              <Link to="/my_projects">Project</Link>
                            </li>
                            <li className="breadcrumb-item active">
                              { moduleName }
                            </li>
                          </ol>
                          <h4 className="main-title mb-0">{ moduleName }</h4>
                        </div>
                        <div className="d-flex gap-2">
                          <Link
                            to="/my_projects"
                            className="btn btn-primary icon-with-btn"
                          >
                            <i className="ri-arrow-left-s-line"></i>{ " " }
                            <span>Back to my project</span>
                          </Link>
                          { projectOwner && projectInfoData?.is_completed === 0 ? (
                            <Button
                              className="btn btn-success icon-with-btn"
                              onClick={ handleMarkAsCompletedClick }
                              type="button"
                            >
                              <i className="ri-check-line"></i>
                              <span>Mark Project as Completed</span>
                            </Button>
                          ) : null }

                          <Link
                            to={ `/project-chat?projectId=${projectId}` }
                            className="btn btn-primary"
                          >
                            <i className="ri-question-answer-line"></i>{ " " }
                            <span>Chat</span>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
              </Container>
            </div>
            <div className="inner-wrapper">
              <div
                className={ `${styles.TenderView_info} border border-secondary p-4` }
              >
                { projectInfoData && (
                  <ProjectInfo projectInfoData={ projectInfoData } />
                ) }
              </div>
            </div>
          </>
        ) }

        {/* Confirmation Modal */ }
        <ConfirmModal
          show={ showModal }
          onHide={ handleModalHide }
          onConfirm={ handleMarkProjectAsCompleted }
          title="Confirm Project Completion"
          message="Are you sure you want to mark this project as completed?"
          loading={ isSubmitting }
        />

        <Footer />
      </div>
    </>
  );
};

export default ProjectView;
