import React, { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Card,
  Col,
  Container,
  Form,
  Row,
  Spinner,
} from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import pageSvg from "../assets/svg/mailbox.svg";
import {
  customerRejectQuotation,
  getQuotationStatus,
} from "../store/actions/tenderActions";
import AcceptedQuotationMsg from "./AcceptedQuotationMsg";
import RejectedQuotationMsg from "./RejectedQuotationMsg";

const RejectQuotation = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const quotation_id = searchParams.get("quotation_id");
  const [rejectQuotationData, setRejectQuotationData] = useState({
    quotation_id: quotation_id,
    reject_reason: "",
  });
  const customerRejectQuotationData = useSelector((state) => state.tender);
  const loading = customerRejectQuotationData.loading;
  const [sendQuoteSubmitted, setSendQuoteSubmitted] = useState(false);
  const [quatationStatus, setQuatationStatus] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    getQuatationStatus();
  }, []);

  const getQuatationStatus = () => {
    if (quotation_id) {
      dispatch(getQuotationStatus({ quotation_id })).then((result) => {
        setQuatationStatus(result.payload.status);
        setIsLoading(false);
      });
    }

  };
  const handleChange = (e) => {
    setRejectQuotationData({
      ...rejectQuotationData,
      reject_reason: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSendQuoteSubmitted(true);
    if (rejectQuotationData.reject_reason) {
      dispatch(customerRejectQuotation(rejectQuotationData)).then((result) => {
        if (result.payload.message === "Rejected email sent successfully.") {
          navigate("/");
        }
      });
    }
  };

  return (
    <>
      { isLoading ? (
        <div className="page-auth">
          <div className="content">
            <Container>
              <Card className="card-auth">
                <Card.Body className="text-center">
                  <Spinner />
                </Card.Body>
              </Card>
            </Container>
          </div>
        </div>
      ) : quatationStatus === "accepted" ? (
        <AcceptedQuotationMsg />
      ) : quatationStatus === "rejected" ? (
        <RejectedQuotationMsg />
      ) : (
        <div className="page-auth">
          <div className="content">
            <Container>
              <Card className="card-auth">
                <Card.Body className="text-center">
                  <div className="mb-5">
                    <img src={ pageSvg } alt="Email veryfication" width={ 200 } />
                  </div>
                  <Card.Title>Reject your quotation</Card.Title>
                  <Card.Text className="mb-5">
                    If you are click reject button quotation will
                    rejected.Please add your reject reason also.
                  </Card.Text>

                  <Row className="g-2 g-sm-3">
                    <Col lg>
                      <div className="mb-3">
                        <Form.Label htmlFor="reject_reason">
                          <span><i style={ { color: "#ff3300", fontSize: "10px" } } className="ri-asterisk"></i></span> Reject Reason
                        </Form.Label>
                        <Form.Control
                          as="textarea"
                          id="reject_reason"
                          rows="3"
                          placeholder="Enter reject reason here..."
                          value={ rejectQuotationData.reject_reason }
                          onChange={ handleChange }
                          disabled={ loading }
                        ></Form.Control>
                        { sendQuoteSubmitted &&
                          !rejectQuotationData.reject_reason && (
                            <p style={ { color: "#d82a2a", fontSize: "13px" } }>
                              This field is required!
                            </p>
                          ) }
                      </div>
                    </Col>
                  </Row>
                  <Row className="g-2 g-sm-3">
                    <Col lg>
                      <Button
                        disabled={ loading }
                        variant="primary"
                        className="btn-sign"
                        onClick={ handleSubmit }
                      >
                        { loading ? (
                          <span
                            className="spinner-border spinner-border-sm"
                            role="status"
                            aria-hidden="true"
                          ></span>
                        ) : (
                          <>Reject</>
                        ) }
                      </Button>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Container>
          </div>
        </div>
      ) }
    </>
  );
};

export default RejectQuotation;
