import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import { Button, Form, Row } from "react-bootstrap";
import { useForm, Controller } from "react-hook-form";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import FormModal from "../../common/FormModal";
import { CustomInput } from "./../../../utils/utils";
import { TASK_STATUS_TYPE, ROLES } from "../../../utils/types";
import useAuth from "../../../hooks/useAuth";
import { getTaskReport } from "../../../store/actions/projectManageActions";
import HeaderImg from "../../../assets/img/default-banner-img.jpg";

const GetReportModal = ({
  show,
  toggleFormModal,
  ProjectTaskData = [],
  allUserList = [],
}) => {
  const isAuthenticated = useAuth();
  const dispatch = useDispatch();
  const moment = require("moment");
  const isCompany = isAuthenticated.user_role == ROLES.COMPANY ? true : false;

  const {
    register,
    handleSubmit,
    watch,
    reset,
    control,
    formState: { errors },
  } = useForm();

  const onSubmit = async (data) => {
    let newData = {};
    newData.from = moment(data.from).format("YYYY-MM-DD");
    newData.to = moment(data.to).format("YYYY-MM-DD");
    newData.project_id = data.project_id;
    if (data.assignee_id != "") {
      newData.assignee_id = data.assignee_id;
    } else {
      newData.assignee_id = 0;
    }

    if (data.status_id != "") {
      newData.status_id = data.status_id;
    } else {
      newData.status_id = 0;
    }
    const response = await dispatch(getTaskReport(newData));
    if (!response.payload) {
      return false;
    } else {
      genarateTaskReport(response.payload);
    }
  };
  const genarateTaskReport = (data) => {
    let projectTitle = data && data.length > 0 && data[0].project.project_title;
    const doc = new jsPDF("p", "mm", "a4");
    const imgWidth = doc.internal.pageSize.getWidth();
    const imgHeight = 40; // Set the height of the image as needed

    // Add letterhead
    const imgData = HeaderImg; // Letterhead image
    doc.addImage(imgData, "JPEG", 0, 0, imgWidth, imgHeight);

    // Customer info
    doc.setTextColor(0, 0, 0);
    doc.setFontSize(12);
    doc.text(`Project Name : ${projectTitle}`, 14, 56);
    // Quotation title
    doc.setFontSize(16);
    doc.setTextColor(0, 0, 0);
    doc.text("Tasks Details", doc.internal.pageSize.getWidth() / 2, 90, {
      align: "center",
    });

    // Content below the header
    const tableColumn = [
      "Task Title",
      "Task Description",
      "Task Status ID",
      "Task Closing Date",
      "Assignee ID",
    ];

    const tableRows =
      data &&
      data.length > 0 &&
      data.map((row) => [
        row.task_title,
        row.task_description,
        TASK_STATUS_TYPE.filter((status) => status.id == row.task_status_id)[0]
          .name,
        row.task_closing_date,
        `${row.assignee.first_name} ${row.assignee.last_name}`,
      ]);
    doc.autoTable({
      head: [tableColumn],
      body: tableRows,
      startY: 96,
      theme: "grid",
      headStyles: { fillColor: [35, 35, 64] },
      styles: { cellPadding: 2, fontSize: 12 },
    });

    window.open(doc.output("bloburl"), "_blank");
  };
  return (
    <FormModal
      moduleName={ "Project Report" }
      modalState={ show }
      toggleFormModal={ toggleFormModal }
      width="600px"
    >
      <Form onSubmit={ handleSubmit(onSubmit) }>
        <Row>
          <div className={ `${isCompany ? "col-md-4" : "col-md-6"} mb-3` }>
            <Form.Group>
              <Form.Label><span><i style={ { color: "#ff3300", fontSize: "10px" } } className="ri-asterisk"></i></span> Select Project </Form.Label>
              <Form.Control
                className="form-select"
                as="select"
                name="project_id"
                { ...register("project_id", {
                  required: "Project id is required",
                }) }
              >
                <option value="">Select project </option>
                { ProjectTaskData &&
                  ProjectTaskData.map((list) => (
                    <option key={ list.id } value={ list.id }>
                      { list.project_title }
                    </option>
                  )) }
              </Form.Control>
              { errors.project_id && (
                <small className="text-danger">
                  { errors.project_id.message }
                </small>
              ) }
            </Form.Group>
          </div>
          { isAuthenticated.user_role == ROLES.COMPANY && (
            <div className={ `${isCompany ? "col-md-4" : "col-md-6"} mb-3` }>
              <Form.Group>
                <Form.Label>Select Assignee </Form.Label>
                <Form.Control
                  className="form-select"
                  as="select"
                  name="assignee_id"
                  { ...register("assignee_id") }
                >
                  <option value="">Select Assignee </option>
                  { allUserList.map((list) => (
                    <option key={ list.id } value={ list.id }>
                      { list.first_name }
                    </option>
                  )) }
                </Form.Control>
              </Form.Group>
            </div>
          ) }
          <div className={ `${isCompany ? "col-md-4" : "col-md-6"} mb-3` }>
            <Form.Group>
              <Form.Label>Select Status </Form.Label>
              <Form.Control
                className="form-select"
                as="select"
                name="status_id"
                { ...register("status_id") }
              >
                <option value="">Select Status </option>
                { TASK_STATUS_TYPE.map((status) => (
                  <option key={ status.id } value={ status.id }>
                    { status.name }
                  </option>
                )) }
              </Form.Control>
            </Form.Group>
          </div>
        </Row>
        <Row>
          <div className="col-md-6 mb-3 text-black">
            <Form.Label><span><i style={ { color: "#ff3300", fontSize: "10px" } } className="ri-asterisk"></i></span> From</Form.Label>
            <Controller
              control={ control }
              name="from"
              rules={ { required: "From date is required" } }
              render={ ({ field }) => (
                <ReactDatePicker
                  className="form-control"
                  dateFormat="yyyy-MM-dd"
                  dropdownMode="select"
                  selected={ field.value }
                  onChange={ (date) => field.onChange(date) }
                  customInput={ <CustomInput /> }
                />
              ) }
            />

            { errors.from && (
              <small className="text-danger">{ errors.from.message }</small>
            ) }
          </div>
          <div className="col-md-6 mb-3 text-black">
            <Form.Label><span><i style={ { color: "#ff3300", fontSize: "10px" } } className="ri-asterisk"></i></span> To</Form.Label>
            <div>
              <Controller
                control={ control }
                name="to"
                rules={ { required: "To date is required" } }
                render={ ({ field }) => (
                  <ReactDatePicker
                    className="form-control"
                    dateFormat="yyyy-MM-dd"
                    dropdownMode="select"
                    selected={ field.value }
                    onChange={ (date) => field.onChange(date) }
                    customInput={ <CustomInput /> }
                  />
                ) }
              />

              { errors.to && (
                <small className="text-danger">{ errors.to.message }</small>
              ) }
            </div>
          </div>
        </Row>

        <div className="d-flex flex-wrap gap-2 mt-3 justify-content-end">
          <Button
            type="button"
            variant="outline-primary"
            className="btn-sign"
            onClick={ () => {
              toggleFormModal();
            } }
          >
            Cancel
          </Button>
          <Button type="submit" variant="primary" className="btn-sign">
            Generate Report
          </Button>
        </div>
      </Form>
    </FormModal>
  );
};

export default GetReportModal;
