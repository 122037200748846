import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, Link } from "react-router-dom";
import { Button, Card, Form, Alert } from "react-bootstrap";
import { userLogin } from "../store/actions/authActions";
import { fetchSasToken } from "../store/actions/fileActions";
import Logo from "../assets/img/icon_mob.png";
import bg from "../assets/img/bg/login.jpg";

const Signin = () => {
  const { loading, userInfo, error } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const navigate = useNavigate();

  // Redirect authenticated user to the appropriate screen
  useEffect(() => {
    if (userInfo) {
      if (userInfo.is_verify === 1) {
        if (userInfo.is_onboard === 1) {
          navigate("/dashboard");
        } else {
          navigate("/onboard");
        }
      }
    }
  }, [dispatch, error, loading, navigate, userInfo]);

  const submitForm = (data) => {
    dispatch(userLogin(data)).then(() => {
      //aZure blob sas token genarate
      dispatch(fetchSasToken());
    });
  };

  return (
    <>
      <div className="page-sign" style={ { backgroundImage: `url(${bg})` } }>
        <Card className="card-sign">
          <Card.Header>
            <Link to="/" className={ `d-flex mb-4 justify-content-center` }>
              <img src={ Logo } alt="Logo" width={ 140 } />
            </Link>
            <Card.Title>Sign In</Card.Title>
            <Card.Text>Welcome back! Please sign in to continue.</Card.Text>
          </Card.Header>
          <Card.Body>
            <Form onSubmit={ handleSubmit(submitForm) }>
              { error && (
                <Alert variant="danger" className="mb-4">
                  { error }
                </Alert>
              ) }
              <div className="mb-4">
                <Form.Label><span><i style={ { color: "#ff3300", fontSize: "10px" } } className="ri-asterisk"></i></span> Email address</Form.Label>
                <Form.Control
                  type="email"
                  placeholder="Enter your email address"
                  { ...register("email", {
                    required: "Email is required",
                    pattern: {
                      value: /^\S+@\S+$/i,
                      message: "Invalid email",
                    },
                  }) }
                  required
                  disabled={ loading }
                />
                { errors.email && (
                  <Form.Text className="text-danger">
                    { errors.email.message }
                  </Form.Text>
                ) }
              </div>
              <div className="mb-4">
                <Form.Label className="d-flex justify-content-between">
                  <div><span><i style={ { color: "#ff3300", fontSize: "10px" } } className="ri-asterisk"></i></span> Password</div>
                  <Link to="/forgot-password">Forgot password?</Link>
                </Form.Label>
                <Form.Control
                  type="password"
                  placeholder="Enter your password"
                  { ...register("password", {
                    required: "Password is required",
                  }) }
                  required
                  disabled={ loading }
                />
                { errors.password && (
                  <Form.Text className="text-danger">
                    { errors.password.message }
                  </Form.Text>
                ) }
              </div>
              <Button
                type="submit"
                variant="primary"
                className="btn-sign w-100"
                disabled={ loading }
              >
                { loading ? (
                  <span
                    className="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>
                ) : (
                  "Sign In"
                ) }
              </Button>
            </Form>
          </Card.Body>
          <Card.Footer>
            Don't have an account? <Link to="/signup">Create an Account</Link>
          </Card.Footer>
        </Card>
      </div>
    </>
  );
};

export default Signin;
