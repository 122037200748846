import React, { useState } from "react";
import { Button, OverlayTrigger, Tooltip, Alert } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { userApproveByAdmin } from "../../../store/actions/userManageActions";
import { CustomInput } from "../../../utils/utils";
import FormModal from "../../common/FormModal";
import { Form, Spinner } from "react-bootstrap";
import ReactDatePicker from "react-datepicker";
import Alertmsg from "../../common/Alert";

const UserApproval = ({ user, isOnboard, isPending, onApprove }) => {
  const [showModal, setShowModal] = useState(false);
  const dispatch = useDispatch();
  const [endDate, setEndDate] = useState("")
  const [loading, setLoading] = useState(false)

  const toggleApprovalModal = () => {
    setShowModal(!showModal);
    setEndDate("")
  };

  const handleConfirmUserApproved = async () => {
    setLoading(true);
    const updatedData = {
      id: user.id,
      package_end_date: endDate === "" ? null : endDate,
    }
    try {
      const response = await dispatch(userApproveByAdmin(updatedData));
      if (response.meta.requestStatus === "fulfilled") {
        onApprove(); // Trigger the parent component's approval logic
        toggleApprovalModal(); // Hide the modal after approval
      } else {
        Alertmsg.error("Unable to approve user!");
      }
    } catch (error) {
      Alertmsg.error("Unable to approve user!");
    } finally {
      setLoading(false);
      setEndDate("");
    }
  };

  return (
    <>
      <OverlayTrigger placement="top" overlay={ <Tooltip>Approve User</Tooltip> }>
        <Button
          variant="primary"
          size="sm"
          onClick={ toggleApprovalModal }
          disabled={ isOnboard === 0 && isPending === 1 ? true : false }
        >
          Approve
        </Button>
      </OverlayTrigger>

      <FormModal
        moduleName="Confirm User Approval"
        modalState={ showModal }
        toggleFormModal={ toggleApprovalModal }
        size="md"
      >
        <>
          { user.user_role.id === 3 ?
            <>
              <Alert variant="primary">
                <p className="mb-0">
                  If the package end date is selected for the user, it will serve as the user's package end date. If not selected, the free trial end date will be used instead.
                </p>
              </Alert>

              <div className="d-flex flex-column p-2">
                <Form.Label className="font-weight-bold">Package End Date :</Form.Label>
                <ReactDatePicker
                  className="form-control"
                  selected={ endDate }
                  onChange={ (date) => setEndDate(date) }
                  dateFormat="yyyy-MM-dd"
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode="select"
                  customInput={ <CustomInput /> }
                />
              </div>
            </>
            :
            <div className="d-flex flex-column align-items-start justify-content-center mt-2">
              <p className="text-left">Are you sure you want to approve this user's account?</p>
            </div>
          }
        </>
        <div className='d-flex justify-content-end mr-5 p-2'>
          <Button disabled={ loading } onClick={ handleConfirmUserApproved } variant="success" className='fs-6 text-capitalize'>
            { loading && <Spinner animation="border" size="sm" /> }{ " " }Confirm
          </Button>
        </div>
      </FormModal>
    </>
  );
};

export default UserApproval;
