import React, { useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Badge, Tooltip, OverlayTrigger, Alert } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import useAuth from "../../../hooks/useAuth";
import { formatDate } from "../../../utils/utils";
import { TENDER_PROGRESS } from "../../../utils/types";
import { fetchTendersForBided } from "../../../store/actions/tenderActions";
import PaginationWithDynamicData from "../../common/PaginationWithDynamicData";

const TenderListForBided = ({ searchCategory }) => {
  const dispatch = useDispatch();
  const isAuthenticated = useAuth();
  const { loading, tendersForbidedData } = useSelector((state) => state.tender);
  const [paginationData, setPaginationData] = useState({});
  const [paginationOptions, setPaginationOptions] = useState({
    totalRows: tendersForbidedData?.pagination?.total, // Total number of rows in  data set
    perPage: tendersForbidedData?.pagination?.per_page, // Number of rows to display per page
    currentPage: 1, // Active page (starting from 1)
    totalPages: tendersForbidedData?.pagination?.total_pages, // Total number of pages
  });

  const fetchData = (data) => {
    dispatch(fetchTendersForBided(data));
  };

  useEffect(() => {
    setPaginationData({
      page: 1,
      perPage: 10,
    });
  }, []);

  useEffect(() => {
    if (searchCategory !== "") {
      let data = paginationData;
      data = {
        ...paginationData,
        category_id: searchCategory,
      };
      setPaginationData(data);
    }
  }, [searchCategory]);

  useEffect(() => {
    let data = paginationData;
    console.log(paginationData, "paginationData", "bid");
    data = {
      page: data?.page,
      per_page: data?.perPage,
      category_id: data?.category_id,
    };

    paginationData?.page &&
      paginationData?.category_id !== "" &&
      fetchData(data);
  }, [paginationData]);

  //set table columns
  const columns = [
    {
      name: "Ref.No",
      selector: (row) => row.id,
      width: "75px",
    },
    {
      name: "Created date",
      selector: (row) => formatDate(row.created_at),
      width: "114px",
    },
    {
      name: "Tender name",
      selector: (row) => row.tender_name,
    },
    {
      name: "Added by",
      selector: (row) =>
        <Badge bg="secondary" pill>{ row.com_name }</Badge>,
      width: "150px",
    },
    {
      name: "Category",
      selector: (row) => row.category_name,
      width: "130px",
    },
    {
      name: "Type",
      selector: (row) => row.tender_type,
      width: "65px",
    },
    {
      name: "Closing date",
      selector: (row) => formatDate(row.tender_closing_date),
      width: "114px",
    },
    {
      name: "Status",
      selector: (row) => {
        const statusType = TENDER_PROGRESS.find(
          (status) => status.id === row.tender_progress_type
        );
        if (row.user_id === isAuthenticated.id) {
          if (statusType && [1, 2, 3, 4, 5, 6, 7].includes(statusType.id)) {
            return (
              <Badge className="badge" pill bg={ statusType.bg }>
                { statusType.type }
              </Badge>
            );
          }
        } else {
          if (statusType && [1, 2, 3, 7].includes(statusType.id)) {
            return (
              <Badge className="badge" pill bg={ statusType.bg }>
                { statusType.type }
              </Badge>
            );
          } else {
            return (
              <Badge className="badge" pill bg="danger">
                Bidding is over
              </Badge>
            );
          }
        }
        return null;
      },

      width: "230px",
    },
    {
      name: "Action",
      selector: (row) => (
        <div className="d-flex flex-wrap gap-2">
          <OverlayTrigger
            placement="top"
            overlay={ <Tooltip>View tender information</Tooltip> }
          >
            <div style={ { display: "inline-block" } }>
              <Link
                className="d-flex btn btn-primary btn-sm"
                to={ `/tender-view?tenderId=${row.id}&tenderName=${row.tender_name
                  }&forBided=${true}` }
              >
                <i className="ri-eye-line"></i>
              </Link>
            </div>
          </OverlayTrigger>
        </div>
      ),
      width: "105px",
    },
  ];

  //Set table rows data
  const rows = Array.isArray(tendersForbidedData?.tenders)
    ? tendersForbidedData.tenders.map(
      ({
        id,
        created_at,
        tender_name,
        tender_description,
        customer_name,
        customer_email,
        tender_type,
        tender_option,
        tender_closing_date,
        status,
        attachments,
        com_name,
        user_id,
        tender_progress_type,
        category_name,
      }) => ({
        id,
        created_at,
        tender_name,
        tender_description,
        customer_name,
        customer_email,
        tender_type,
        tender_option,
        tender_closing_date,
        status,
        attachments,
        com_name,
        user_id,
        tender_progress_type,
        category_name,
      })
    )
    : [];

  const handlePageChange = (page) => {
    let data = paginationData;
    data = {
      ...paginationData,
      page: page,
    };
    setPaginationData(data);
  };

  const handlePerPageChange = (newPerPage, page) => {
    let data = paginationData;
    data = {
      ...paginationData,
      page: page,
      perPage: newPerPage,
    };
    setPaginationData(data);
  };
  return (
    <>
      <PaginationWithDynamicData
        columns={ columns }
        rows={ rows }
        loading={ loading }
        columnCount={ columns?.length || 8 }
        paginationOptions={ paginationOptions }
        handlePageChange={ (page) => handlePageChange(page) }
        handlePerPageChange={ (newPerPage, page) =>
          handlePerPageChange(newPerPage, page)
        }
      />
    </>
  );
};

export default TenderListForBided;
