import { createAsyncThunk } from "@reduxjs/toolkit";
import { get, post } from "../../servces/Api";
import Alert from "../../components/common/Alert";

//fetch user profiles
export const fetchUsersList = createAsyncThunk("usersList", async (paginationData) => {
  try {
    const response = await post("/api/user_profile_list", paginationData);
    const data = await response.data;
    if (response.status === 200) {
      return data;
    } else {
      return { err: "something went wrong" };
    }
  } catch (error) {
    console.error("Error fetching user list:", error);
    return { err: "something went wrong" };
  }
});

//fetch onboard user profiles
export const fetchOnboardUsersList = createAsyncThunk(
  "onboardUsersList",
  async (paginationData) => {
    try {
      const response = await post("/api/onboard", paginationData);
      const data = await response.data;
      if (response.status === 200) {
        return data;
      } else {
        return { err: "something went wrong" };
      }
    } catch (error) {
      console.error("Error fetching user list:", error);
      return { err: "something went wrong" };
    }
  }
);

//update user status
export const updateUserStatus = createAsyncThunk(
  "updateUserStatus",
  async (userStatusData, { rejectWithValue }) => {
    try {
      const response = await post("/api/update_user_status", userStatusData);
      const data = response.data;
      if (response.status === 200) {
        Alert.success("Update user status success!");
        return data;
      } else {
        Alert.success("Error update user status!");
      }
    } catch (error) {
      Alert.error("something went wrong");
      return rejectWithValue(error.response.data);
    }
  }
);

//Approve onboard user by admin (Approved only document upload completed)
export const userApproveByAdmin = createAsyncThunk(
  "userApproveByAdmin",
  async (userStatusData, { rejectWithValue }) => {
    try {
      const response = await post("/api/onboard_user_by_admin", userStatusData);
      const data = response.data;
      if (response.status === 200) {
        Alert.success("User approved successfully!");
        return data;
      } else {
        Alert.success("Error approving user!");
      }
    } catch (error) {
      Alert.error("something went wrong!");
      return rejectWithValue(error.response.data);
    }
  }
);
