import React, { useState } from "react";
import { Row, Form, Alert } from "react-bootstrap";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import UploadFile from "../../common/UploadFile";
import { TENDER_TYPES, TENDER_OPTIONS } from "../../../utils/types";
import { CustomInput } from "../../../utils/utils";

const TenderForm = ({
  updateTender = false,
  register,
  watch,
  errors,
  loading,
  closingDate,
  setClosingDate,
  setValue,
  attachmentDoc,
  bannerImg,
  reset,
  tenderCategory = [],
}) => {
  const selectedTenderType = watch("tender_type");

  const setAttachmentDocName = (fileName) => {
    if (attachmentDoc) {
      setValue("attachments", attachmentDoc);
    } else {
      setValue("attachments", fileName);
    }
  };

  const setBannerImgName = (fileName) => {
    if (bannerImg) {
      setValue("images", bannerImg);
    } else {
      setValue("images", fileName);
    }
  };

  return (
    <>
      <Row>
        <div className="col-md-6 mb-3">
          <div className="form-group">
            <Form.Label><span><i style={ { color: "#ff3300", fontSize: "10px" } } className="ri-asterisk"></i></span> Customer name</Form.Label>
            <Form.Control
              disabled={ loading || updateTender }
              type="text"
              className="form-input"
              id="customer_name"
              { ...register("customer_name", {
                required: "Customer name is required",
              }) }
            />
            { errors.customer_name && (
              <small className="text-danger">
                { errors.customer_name.message }
              </small>
            ) }
          </div>
        </div>
        <div className="col-md-6 mb-3">
          <div className="form-group">
            <Form.Label><span><i style={ { color: "#ff3300", fontSize: "10px" } } className="ri-asterisk"></i></span> Customer email</Form.Label>
            <Form.Control
              disabled={ loading || updateTender }
              type="email"
              className="form-input"
              id="customer_email"
              { ...register("customer_email", {
                required: "Customer email is required",
                pattern: {
                  value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
                  message: "Invalid email address",
                },
              }) }
            />
            { errors.customer_email && (
              <small className="text-danger">
                { errors.customer_email.message }
              </small>
            ) }
          </div>
        </div>
      </Row>

      <Row>
        <div className="col-md-12 mb-3">
          <div className="form-group">
            <Form.Label><span><i style={ { color: "#ff3300", fontSize: "10px" } } className="ri-asterisk"></i></span> Tender name</Form.Label>
            <Form.Control
              disabled={ loading || updateTender }
              type="text"
              className="form-input"
              id="tender_name"
              { ...register("tender_name", {
                required: "Tender name is required",
              }) }
            />
            { errors.tender_name && (
              <small className="text-danger">
                { errors.tender_name.message }
              </small>
            ) }
          </div>
        </div>
      </Row>

      <Row>
        <div className="col-md-12 mb-3">
          <div className="form-group">
            <Form.Label><span><i style={ { color: "#ff3300", fontSize: "10px" } } className="ri-asterisk"></i></span> Tender description</Form.Label>
            <Form.Control
              disabled={ loading || updateTender }
              as="textarea"
              { ...register("tender_description", {
                required: "Tender description is required",
                maxLength: {
                  value: 2000,
                  message: "Maximum length is 2000 characters",
                },
              }) }
              placeholder="Tender description"
              className="textarea"
            ></Form.Control>
            { errors.tender_description && (
              <small className="text-danger">
                { errors.tender_description.message }
              </small>
            ) }
          </div>
        </div>
      </Row>
      <Row>
        <div className="col-md-12 mb-3">
          <div className="form-group">
            <Form.Group>
              <Form.Label><span><i style={ { color: "#ff3300", fontSize: "10px" } } className="ri-asterisk"></i></span> Select tender category</Form.Label>
              <Form.Control
                disabled={ loading || updateTender }
                className="form-select"
                as="select"
                name="category_id"
                { ...register("category_id", {
                  required: "Tender category is required",
                }) }
              >
                <option value="">Select tender category</option>
                { tenderCategory.map((value) => (
                  <option key={ value.id } value={ value.id }>
                    { value.name }
                  </option>
                )) }
              </Form.Control>
              { errors.category_id && (
                <small className="text-danger">
                  { errors.category_id.message }
                </small>
              ) }
            </Form.Group>
          </div>
        </div>
      </Row>
      <Row>
        <div className="col-md-6 mb-3">
          <Form.Group>
            <Form.Label><span><i style={ { color: "#ff3300", fontSize: "10px" } } className="ri-asterisk"></i></span> Select tender type</Form.Label>
            <Form.Control
              disabled={ loading || updateTender }
              className="form-select"
              as="select"
              name="tender_type"
              { ...register("tender_type", {
                required: "Tender type is required",
              }) }
            >
              <option value="">Select tender type</option>
              { Object.entries(TENDER_TYPES).map(([key, value]) => (
                <option key={ key } value={ key }>
                  { value }
                </option>
              )) }
            </Form.Control>
            { errors.tender_type && (
              <small className="text-danger">
                { errors.tender_type.message }
              </small>
            ) }
          </Form.Group>
        </div>
        { selectedTenderType === "ITT" && (
          <div className="col-md-6 mb-3">
            <Form.Group>
              <Form.Label><span><i style={ { color: "#ff3300", fontSize: "10px" } } className="ri-asterisk"></i></span> Select Tender Option</Form.Label>
              <Form.Control
                className="form-select"
                disabled={ loading || updateTender }
                as="select"
                name="tender_option"
                { ...register("tender_option", {
                  required: "Tender option is required",
                }) }
              >
                <option value="">Select Tender Option</option>
                { Object.entries(TENDER_OPTIONS).map(([key, label]) => (
                  <option key={ key } value={ key }>
                    { label }
                  </option>
                )) }
              </Form.Control>
              { errors.tender_option && (
                <small className="text-danger">
                  { errors.tender_option.message }
                </small>
              ) }
            </Form.Group>
          </div>
        ) }
        <div
          className={
            selectedTenderType === "ITT" ? "col-md-12 mb-3" : "col-md-6 mb-3"
          }
        >
          <Form.Label>Tender closing date</Form.Label>
          <div>
            <ReactDatePicker
              className="form-control"
              selected={ closingDate }
              disabled={ loading || (updateTender && closingDate < new Date()) }
              onChange={ (date) => setClosingDate(date) }
              dateFormat="yyyy-MM-dd"
              minDate={ new Date() }
              showMonthDropdown
              showYearDropdown
              dropdownMode="select"
              customInput={ <CustomInput /> }
            />
          </div>
        </div>
      </Row>

      {/* <Row>
        <UploadFile
          lableName="Upload tender document"
          disabled={loading || updateTender}
          setFileName={setAttachmentDocName}
          loading={loading}
        />
      </Row>
      <Row>
        <UploadFile
          lableName="Upload banner Image"
          uploadInfo="[File size: 1280px x 300px]"
          disabled={loading || updateTender}
          setFileName={setBannerImgName}
          loading={loading}
        />
      </Row> */}
      <Row>
        <div className="col-md-12">
          { attachmentDoc ? (
            <>
              <Form.Label>Uploaded tender document</Form.Label>
              <Alert variant="secondary" className="d-flex align-items-center">
                <i className="ri-file-text-line"></i>
                { attachmentDoc }
              </Alert>
            </>
          ) : (
            <UploadFile
              lableName="Upload tender document"
              uploadInfo="[Max file size 4Mb]"
              disabled={ loading || updateTender }
              setFileName={ setAttachmentDocName }
              loading={ loading }
            />
          ) }
        </div>
      </Row>

      <Row>
        <div className="col-md-12">
          { bannerImg ? (
            <>
              <Form.Label>Uploaded banner Image</Form.Label>
              <Alert variant="secondary" className="d-flex align-items-center">
                <i className="ri-file-text-line"></i>
                { bannerImg }
              </Alert>
            </>
          ) : (
            <UploadFile
              lableName="Upload banner Image"
              uploadInfo="[File size: 1280px x 300px / 4Mb]"
              disabled={ loading || updateTender }
              setFileName={ setBannerImgName }
              loading={ loading }
            />
          ) }
        </div>
      </Row>
    </>
  );
};

export default TenderForm;
