import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Button } from "react-bootstrap";
import InfiniteScroll from "react-infinite-scroll-component";
import Footer from "../../components/dashboard/Footer";
import { fetchProjectList } from "../../store/actions/projectActions";
import ProjectList from "../../components/dashboard/projects/ProjectList";
import SearchBar from "../../components/common/SearchBar";
import CardLoading from "../../components/common/CardLoading";
import ProjectsLoading from "../../components/common/ProjectsLoading";

const MyProjects = () => {
  const moduleName = "My Projects";
  const { loading, projectListData } = useSelector((state) => state.project);
  const dispatch = useDispatch();
  const [items, setItems] = useState([]);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [search, setSearch] = useState("");
  const per_page = 10;

  useEffect(() => {
    // Initial load
    setItems([]);
    const data = {
      page: page,
      per_page: per_page,
      search: "",
    };
    fetchMoreData(data);
  }, [dispatch]);

  const fetchMoreData = async (data) => {
    dispatch(fetchProjectList(data)).then((result) => {
      if (fetchProjectList.fulfilled.match(result)) {
        if (result?.payload?.projects?.length > 0) {
          setItems((prevItems) => [...prevItems, ...result?.payload?.projects]);
          setPage((prevPage) => prevPage + 1);
          if (
            result?.payload?.pagination?.current_page ===
            result?.payload?.pagination?.total_pages
          ) {
            setHasMore(false);
          }
        } else {
          setHasMore(false);
        }
      } else if (fetchProjectList.rejected.match(result)) {
        console.error("Failed to fetch projects:");
      }
    });
  };

  const handleScroll = async () => {
    const data = {
      page: page,
      per_page: per_page,
      search: "",
    };
    await fetchMoreData(data);
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      setItems([]);
      event.preventDefault();
      const data = {
        page: 1,
        per_page: per_page,
        search: search,
      };
      fetchMoreData(data);
    }
  };

  const handleOnClick = (event) => {
    setItems([]);
    event.preventDefault();
    const data = {
      page: 1,
      per_page: per_page,
      search: search,
    };
    fetchMoreData(data);
  };

  const handleReset = (event) => {
    event.preventDefault();
    setItems([]);
    setPage(1);
    setSearch('');

    const data = {
      page: 1,
      per_page: per_page,
      search: '',
    };
    fetchMoreData(data);
  };

  const removeDuplicates = (array) => {
    const seen = new Set();
    return array.filter((item) => {
      const duplicate = seen.has(item.id);
      seen.add(item.id);
      return !duplicate;
    });
  };

  return (
    <>
      <InfiniteScroll
        dataLength={ items.length }
        next={ () => handleScroll() }
        hasMore={ hasMore }
      >
        <div className="main main-app p-3 p-lg-4">
          <div className="d-flex align-items-center justify-content-between mb-4">
            <div>
              <ol className="breadcrumb fs-sm mb-1">
                <li className="breadcrumb-item" aria-current="page">
                  Dashboard
                </li>
                <li className="breadcrumb-item active">{ moduleName }</li>
              </ol>
              <h4 className="main-title mb-0">{ moduleName }</h4>
            </div>
          </div>
          <div className="d-flex align-items-center justify-content-between mb-4">
            <div className="w-50 d-flex">
              <SearchBar
                onChange={ (e) => setSearch(e) }
                searchValue={ search }
                handleKeyPress={ handleKeyPress }
                placeholder="Search project"
                onClick={ handleOnClick }
                loading={loading}
              />
              <Button disabled={loading} variant="secondary" className="btn icon-with-btn reset_btn" onClick={ handleReset } type="button"><i className="ri-refresh-line"></i> Clear</Button>
            </div>
          </div>
          <div className="inner-wrapper">
            { loading ? (
              <ProjectsLoading />
            ) : (
              <ProjectList
                projectListData={ removeDuplicates(items) }
                loading={ loading }
              />
            ) }
          </div>
          <Footer />
        </div>
      </InfiniteScroll>
    </>
  );
};

export default MyProjects;
