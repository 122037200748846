import React, { useEffect, useState } from "react";
import { Badge, Tooltip, OverlayTrigger, Alert } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import {
  fetchUsersList,
  updateUserStatus,
} from "../../store/actions/userManageActions";
import Footer from "../../components/dashboard/Footer";
import { formatDate } from "../../utils/utils";
import PaginationWithDynamicData from "../../components/common/PaginationWithDynamicData";
import CreateNewUser from "../../components/dashboard/user-manage/CreateNewUser";
import UserInfoView from "../../components/dashboard/user-manage/UserInfoView";
import UpdateUser from "../../components/dashboard/user-manage/UpdateUser";
import ConfirmModal from "../../components/common/ConfirmModal";

const UserManage = () => {
  const moduleName = "Manage User Accounts";
  const [showModal, setShowModal] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState(null);
  const dispatch = useDispatch();
  const { data, userStatus, isLoading, error } = useSelector(
    (state) => state.usersManage
  );
  const [paginationData, setPaginationData] = useState({
    page: 1,
    perPage: 10,
  });
  const [paginationOptions, setPaginationOptions] = useState({});

  useEffect(() => {
    dispatch(fetchUsersList(paginationData));
  }, [paginationData]);

  useEffect(() => {
    if (data?.pagination) {
      setPaginationOptions({
        totalRows: data.pagination.total,
        perPage: data.pagination.per_page,
        currentPage: data.pagination.current_page,
        totalPages: data.pagination.total_pages,
      });
    }
  }, [data]);

  // Function to format date
  const formatDateTime = (dateTimeString) => {
    const date = new Date(dateTimeString);
    const dateOptions = { year: 'numeric', month: 'long', day: 'numeric' };
    const timeOptions = { hour: '2-digit', minute: '2-digit', hour12: true };
    const formattedDate = date.toLocaleDateString('en-US', dateOptions);
    const formattedTime = date.toLocaleTimeString('en-US', timeOptions);
    return `${formattedDate}, ${formattedTime}`;
  };

  const columns = [
    {
      name: "ID",
      selector: (row) => row.id,
      width: "75px",
    },
    {
      name: "Full Name",
      selector: (row) => row.first_name + " " + row.last_name,
    },
    {
      name: "Email",
      selector: (row) => row.email,
    },
    {
      name: "User Role",
      selector: (row) => (
        <strong className="text-capitalize text-dark">
          {row.user_role ? row.user_role.role_name : "N/A"}
        </strong>
      ),
    },
    {
      name: "Status",
      selector: (row) => (
        <OverlayTrigger
          placement="top"
          overlay={
            row.status === 1 ? (
              <Tooltip>Click here to inactive this user</Tooltip>
            ) : (
              <Tooltip>Click here to active this user</Tooltip>
            )
          }
        >
          <Badge
            className="table-status-badge"
            role="button"
            onClick={() => handleChangeStatus(row.id)}
            bg={`${row.status === 1 ? "success" : "danger"}`}
          >
            {row.status === 1 ? "Active" : "Inactive"}
          </Badge>
        </OverlayTrigger>
      ),
      width: "134px",
    },
    {
      name: "Created At",
      selector: (row) => (
        <div className="d-flex flex-wrap">
          <OverlayTrigger
            placement="top"
            overlay={<Tooltip>{formatDateTime(row.updated_at)}</Tooltip>}
          >
            <div style={{ display: "inline-block" }}>
              {formatDateTime(row.updated_at)}
            </div>
          </OverlayTrigger>
        </div>
      ),
    },
    {
      name: "Action",
      selector: (row) => (
        <div className="d-flex flex-wrap gap-2">
          <UserInfoView userId={row.id} />
          <UpdateUser userId={row.id} />
        </div>
      ),
      width: "108px",
    },
  ];

  const rows = Array.isArray(data?.userdet)
    ? data.userdet.map(
      ({ id, first_name, last_name, updated_at, email, status, user_role }) => ({
        id,
        first_name,
        last_name,
        updated_at,
        email,
        status,
        user_role,
      })
    )
    : [];

  const handleChangeStatus = (id) => {
    const user = rows.find((item) => item.id === id);
    if (user) {
      setSelectedUserId(id);
      setShowModal(true);
    }
  };

  const handleConfirmStatusChange = () => {
    if (selectedUserId !== null) {
      const user = rows.find((item) => item.id === selectedUserId);
      if (user) {
        const newStatus = user.status === 1 ? 0 : 1;
        const userStatusData = { id: user.id, status: newStatus };

        dispatch(updateUserStatus(userStatusData))
          .then(() => {
            dispatch(fetchUsersList(paginationData));
          })
          .catch((error) => {
            // Handle the error appropriately here
            console.error("Failed to update user status:", error);
          });
      }
    }

    setShowModal(false);
  };

  const handlePageChange = (page) => {
    setPaginationData((prev) => ({ ...prev, page }));
  };

  const handlePerPageChange = (newPerPage) => {
    setPaginationData((prev) => ({ ...prev, perPage: newPerPage }));
  };

  return (
    <>
      <div className="main main-app p-3 p-lg-4">
        <div className="d-flex align-items-center justify-content-between mb-4">
          <div>
            <ol className="breadcrumb fs-sm mb-1">
              <li className="breadcrumb-item" aria-current="page">
                Dashboard
              </li>
              <li className="breadcrumb-item active">{moduleName}</li>
            </ol>
            <h4 className="main-title mb-0">{moduleName}</h4>
          </div>
          <div>
            {data && (
              <CreateNewUser
                companyList={data?.company || []}
                userRoleList={data?.user_role || []}
                isLoading={isLoading}
              />
            )}
          </div>
        </div>
        <div className="inner-wrapper">
          {error && (
            <Alert variant="danger">
              {error}
            </Alert>
          )}
          <PaginationWithDynamicData
            columns={columns}
            rows={rows}
            loading={isLoading}
            columnCount={columns.length}
            paginationOptions={paginationOptions}
            handlePageChange={handlePageChange}
            handlePerPageChange={handlePerPageChange}
          />

          <ConfirmModal
            show={showModal}
            onHide={() => setShowModal(false)}
            onConfirm={handleConfirmStatusChange}
            title="Confirm user status change"
            message={
              selectedUserId !== null
                ? `Are you sure you want to ${rows.find((item) => item.id === selectedUserId)?.status ===
                  1
                  ? "deactivate"
                  : "activate"
                } this user?`
                : "User not found"
            }
          />
        </div>
        <Footer />
      </div>
    </>
  );
};

export default UserManage;
