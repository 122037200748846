// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.accordion-custom-green {
  margin-top: 20px !important;
}
.accordion-custom-green .accordion-item {
  border-color: #9EE2CE !important;
  border-left: 2px solid #9EE2CE !important;
  border-right: 2px solid #9EE2CE !important;
  border-width: 3px !important;
  border-radius: 5px !important;
  margin-top: 10px !important;
}
.accordion-custom-green .accordion-button {
  background-color: #9EE2CE !important;
  color: #000000 !important;
  display: flex !important;
  align-items: center !important;
  gap: 10px !important;
}
.accordion-custom-green .accordion-button:not(.collapsed) {
  background-color: #9EE2CE !important;
  color: #000000 !important;
}
.accordion-custom-green .accordion-body {
  padding-top: 0;
  background-color: #f8f9fa !important;
}
.accordion-custom-green .accordion-header {
  display: flex !important;
  flex-direction: row !important;
  align-items: center !important;
  justify-content: center !important;
  gap: 10px !important;
}
.accordion-custom-green .accordion-header i {
  font-size: 1.5rem !important;
  color: #000000 !important;
}
.accordion-custom-green .accordion-header p {
  margin: 0 !important;
  font-size: 1rem !important;
}`, "",{"version":3,"sources":["webpack://./src/assets/styles/components/tender-list/TenderBid.scss"],"names":[],"mappings":"AAEA;EACE,2BAAA;AADF;AAEE;EACE,gCAAA;EACA,yCAAA;EACA,0CAAA;EACA,4BAAA;EACA,6BAAA;EACA,2BAAA;AAAJ;AAGE;EACE,oCAAA;EACA,yBAAA;EACA,wBAAA;EACA,8BAAA;EACA,oBAAA;AADJ;AAGI;EACE,oCAAA;EACA,yBAAA;AADN;AAKE;EACE,cAAA;EACA,oCAAA;AAHJ;AAME;EACE,wBAAA;EACA,8BAAA;EACA,8BAAA;EACA,kCAAA;EACA,oBAAA;AAJJ;AAOE;EACE,4BAAA;EACA,yBAAA;AALJ;AAQE;EACE,oBAAA;EACA,0BAAA;AANJ","sourcesContent":["@import \"../../variables\";\n\n.accordion-custom-green {\n  margin-top: 20px !important;\n  .accordion-item {\n    border-color: #9EE2CE !important; \n    border-left: 2px solid #9EE2CE !important;\n    border-right: 2px solid #9EE2CE !important;\n    border-width: 3px !important;\n    border-radius: 5px !important;\n    margin-top: 10px !important;\n  }\n\n  .accordion-button {\n    background-color: #9EE2CE !important;\n    color: #000000 !important;\n    display: flex !important;\n    align-items: center !important;\n    gap: 10px !important; \n\n    &:not(.collapsed) {\n      background-color: #9EE2CE !important; \n      color: #000000 !important; \n    }\n  }\n\n  .accordion-body {\n    padding-top: 0;\n    background-color: #f8f9fa !important;\n  }\n\n  .accordion-header {\n    display: flex !important;\n    flex-direction: row !important;\n    align-items: center !important; \n    justify-content: center !important; \n    gap: 10px !important; \n  }\n\n  .accordion-header i {\n    font-size: 1.5rem !important; \n    color: #000000 !important; \n  }\n\n  .accordion-header p {\n    margin: 0 !important;\n    font-size: 1rem !important; \n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
