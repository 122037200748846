import React, { useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  Badge,
  Tooltip,
  OverlayTrigger,
  Alert,
  Button,
  Form,
} from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import useAuth from "../../../hooks/useAuth";
import { formatDate } from "../../../utils/utils";
import { TENDER_PROGRESS } from "../../../utils/types";
import {
  fetchTenders,
  updateTender,
} from "../../../store/actions/tenderActions";
import PaginationWithDynamicData from "../../common/PaginationWithDynamicData";
import FormModal from "../../common/FormModal";
import TenderForm from "./TenderForm";

const TenderList = ({ refetchTendersRef, searchCategory }) => {
  const dispatch = useDispatch();
  const isAuthenticated = useAuth();
  const { loading, tenderList } = useSelector((state) => state.tender);
  const [selectedTenderId, setSelectedTenderId] = useState();
  const [closingDate, setClosingDate] = useState(new Date());
  const [showModalState, setShowModalState] = useState(false);
  const [attachmentDoc, setAttachmentDoc] = useState("");
  const [bannerImg, setBannerImg] = useState("");
  const [paginationData, setPaginationData] = useState({});

  const [paginationOptions, setPaginationOptions] = useState({
    totalRows: 0,
    perPage: 10,
    currentPage: 1,
    totalPages: 1,
  });

  const {
    register,
    handleSubmit,
    watch,
    reset,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      customer_name: "",
      customer_email: "",
      tender_name: "",
      tender_description: "",
      tender_type: "",
      tender_option: "",
      tender_closing_date: "",
      attachments: "",
      images: "",
      category_id: "",
    },
  });

  const fetchData = () => {
    dispatch(fetchTenders(paginationData)).then((response) => {
      if (response?.payload?.pagination) {
        const { total, per_page, current_page, total_pages } = response.payload.pagination;
        setPaginationOptions({
          totalRows: total,
          perPage: per_page,
          currentPage: current_page,
          totalPages: total_pages,
        });
      }
    });
  };

  // Fetch tenders initially or when paginationData changes
  useEffect(() => {
    fetchData();
  }, [paginationData, dispatch]);


  //form modal close/open
  const toggleFormModal = (show) => {
    setShowModalState(show);
  };

  useEffect(() => {
    if (searchCategory !== "") {
      setPaginationData((prev) => ({
        ...prev,
        category_id: searchCategory,
        page: 1, // Reset to the first page when category changes
      }));
    }
  }, [searchCategory]);

  //update tender
  const onSubmit = async (data) => {
    const dateObject = new Date(closingDate);
    const formatClosingDate = dateObject.toISOString().slice(0, 19).replace("T", " ");
    data.tender_id = selectedTenderId;
    data.tender_closing_date = formatClosingDate;
    data.attachments = attachmentDoc;
    data.images = bannerImg;

    dispatch(updateTender({ tenderData: data })).then(() => {
      setPaginationData((prev) => ({
        ...prev,
        page: prev.page, // Keep current page
      }));
      fetchData();
      toggleFormModal(false);
    });
  };

  const handlePageChange = (page) => {
    setPaginationData((prev) => ({
      ...prev,
      page,
    }));
  };

  const handlePerPageChange = (newPerPage, page) => {
    setPaginationData((prev) => ({
      ...prev,
      perPage: newPerPage,
      page,
    }));
  };

  // Function to format date
  const formatDateTime = (dateTimeString) => {
    const date = new Date(dateTimeString);
    const dateOptions = { year: 'numeric', month: 'long', day: 'numeric' };
    const timeOptions = { hour: '2-digit', minute: '2-digit', hour12: true };
    const formattedDate = date.toLocaleDateString('en-US', dateOptions);
    const formattedTime = date.toLocaleTimeString('en-US', timeOptions);
    return `${formattedDate}, ${formattedTime}`;
  };

  //set table columns
  const columns = [
    {
      name: "Ref.No",
      selector: (row) => row.tender_id,
      width: "75px",
    },
    {
      name: "Tender title",
      selector: (row) => row.tender_name,
    },
    {
      name: "Added By",
      selector: (row) =>
      (
        <Badge bg="secondary" pill>{row.com_name}</Badge>
      ),
      width: "150px",
    },
    {
      name: "Category",
      selector: (row) => row.category_name,
      width: "130px",
    },
    {
      name: "Type",
      selector: (row) => row.tender_type,
      width: "65px",
    },
    {
      name: "Status",
      selector: (row) => {
        const statusType = TENDER_PROGRESS.find(
          (status) => status.id === row.tender_progress_type
        );

        if (statusType && [1, 2, 3, 4, 5, 6, 7].includes(statusType.id)) {
          return (
            <Badge className="badge" pill bg={statusType.bg}>
              {statusType.type}
            </Badge>
          );
        }

        return null;
      },

      width: "236px",
    },
    {
      name: "Created At",
      selector: (row) => (
        <div className="d-flex flex-wrap">
          <OverlayTrigger
            placement="top"
            overlay={<Tooltip>{formatDateTime(row.created_at)}</Tooltip>}
          >
            <div style={{ display: "inline-block" }}>
              {formatDateTime(row.created_at)}
            </div>
          </OverlayTrigger>
        </div>
      ),
      width: "114px",
    },
    {
      name: "Closing At",
      selector: (row) => (
        <div className="d-flex flex-wrap">
          <OverlayTrigger
            placement="top"
            overlay={<Tooltip>{formatDateTime(row.tender_closing_date)}</Tooltip>}
          >
            <div style={{ display: "inline-block" }}>
              {formatDateTime(row.tender_closing_date)}
            </div>
          </OverlayTrigger>
        </div>
      ),
      width: "114px",
    },
    {
      name: "Action",
      selector: (row) => (
        <div className="d-flex flex-wrap gap-2">
          {row.user_id === isAuthenticated.id && (
            <OverlayTrigger
              placement="top"
              overlay={<Tooltip>Edit Tender</Tooltip>}
            >
              <div style={{ display: "inline-block" }}>
                <Button
                  variant="primary"
                  size="sm"
                  onClick={() => {
                    setSelectedTenderId(row.tender_id);
                    setValue("customer_name", row.customer_name);
                    setValue("customer_email", row.customer_email);
                    setValue("tender_name", row.tender_name);
                    setValue("tender_description", row.tender_description);
                    setValue("tender_type", row.tender_type);
                    setValue("tender_option", row.tender_option);
                    setClosingDate(new Date(row.tender_closing_date));
                    setAttachmentDoc(row.attachments);
                    setBannerImg(row.images);
                    setValue("category_id", row.category_id);
                    toggleFormModal(true);
                  }}
                  className="mr-2 d-flex"
                >
                  <i className="ri-edit-line"></i>
                </Button>
              </div>
            </OverlayTrigger>
          )}

          <OverlayTrigger
            placement="top"
            overlay={<Tooltip>View tender information</Tooltip>}
          >
            <div style={{ display: "inline-block" }}>
              <Link
                className="d-flex btn btn-primary btn-sm"
                to={`/tender-view?tenderId=${row.tender_id}&tenderName=${row.tender_name}`}
              >
                <i className="ri-eye-line"></i>
              </Link>
            </div>
          </OverlayTrigger>
        </div>
      ),
      width: "105px",
    },
  ];

  //Set table rows data
  const rows = Array.isArray(tenderList?.tenders)
    ? tenderList.tenders.map(
      ({
        tender_id,
        created_at,
        tender_name,
        tender_description,
        customer_name,
        customer_email,
        tender_type,
        tender_option,
        tender_closing_date,
        status,
        attachments,
        images,
        com_name,
        user_id,
        tender_progress_type,
        category_id,
        category_name,
      }) => ({
        tender_id,
        created_at,
        tender_name,
        tender_description,
        customer_name,
        customer_email,
        tender_type,
        tender_option,
        tender_closing_date,
        status,
        attachments,
        images,
        com_name,
        user_id,
        tender_progress_type,
        category_id,
        category_name,
      })
    )
    : [];


  return (
    <>
      <PaginationWithDynamicData
        columns={columns}
        rows={rows}
        loading={loading}
        columnCount={columns?.length || 8}
        paginationOptions={paginationOptions}
        handlePageChange={(page) => handlePageChange(page)}
        handlePerPageChange={(newPerPage, page) =>
          handlePerPageChange(newPerPage, page)
        }
      />

      <FormModal
        moduleName={"Edit tender"}
        modalState={showModalState}
        toggleFormModal={toggleFormModal}
        width="600px"
      >
        <Form onSubmit={handleSubmit(onSubmit)}>
          <TenderForm
            register={register}
            watch={watch}
            errors={errors}
            loading={loading}
            closingDate={closingDate}
            setClosingDate={setClosingDate}
            setValue={setValue}
            reset={reset}
            updateTender={true}
            setAttachmentDoc={setAttachmentDoc}
            setBannerImg={setBannerImg}
            attachmentDoc={attachmentDoc}
            bannerImg={bannerImg}
            tenderCategory={tenderList?.tender_category}
          />
          <div className="d-flex flex-wrap gap-2 mt-3">
            <Button
              type="button"
              disabled={loading}
              variant="outline-primary"
              className="btn-sign"
              onClick={() => {
                toggleFormModal(false);
              }}
            >
              Cancel
            </Button>
            {closingDate > new Date() && (
              <Button
                type="submit"
                variant="primary"
                className="btn-sign"
                disabled={loading}
              >
                {loading ? (
                  <>
                    <span
                      className="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                    Create tender
                  </>
                ) : (
                  "Update tender"
                )}
              </Button>
            )}
          </div>
        </Form>
      </FormModal>
    </>
  );
};

export default TenderList;
