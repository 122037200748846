import { React, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { get } from "../../servces/Api";
import Alert from "../common/Alert";
import basicImg from '../../assets/img/starter-img.png';
import standardImg from '../../assets/img/business-img.png'
import premiumImg from '../../assets/img/premium-img.png'
import freeImg from '../../assets/img/free-img.png'
import styles from "../../assets/styles/components/home/PricingPackges.module.scss"
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

const ChooseYourPricePackage = () => {
  const [packageDetails, setPackageDetails] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const response = await get("/api/packages");
        if (response) {
          const packages = response.data.packages;
          const filteredPackges = packages.filter(pkg => pkg.id !== 5);
          setPackageDetails(filteredPackges);
          setLoading(false);
        }
      } catch (err) {
        Alert.error("Error in package details fetch");
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  function formatPackageName(str) {
    return str
      .split('_')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  }

  function capitalizeFirstLetter(str) {
    if (!str) return '';
    const lowerCaseStr = str.toLowerCase();
    return lowerCaseStr.charAt(0).toUpperCase() + lowerCaseStr.slice(1);
  }

  return (
    <>
      <div className="testimonial-area pd-top-60 pd-bottom-60 bg-gray">
        <div className="container">
          <div className="section-title text-center mb-5">
            <h2 className="title">Choose your pricing plan</h2>
            <p className="text-black-50">
              Select a pricing plan that aligns with your project's needs and
              budget
            </p>
            <div className="d-flex gap-2 justify-content-center">
              <Link
                to="/signup"
                type="button"
                className="btn btn-primary feed-btn"
              >
                Start free trial
              </Link>
            </div>
          </div>
          {loading ? (
            <div className={styles["main-container"]}>
            {Array(4).fill().map((_, index) => (
              <div key={index} className={styles["grid-item-loading"]}>
                <Skeleton height={25} width={200} className={styles['title']} />
                <Skeleton height={12} width={150} className={styles['sub-title']} />
                <Skeleton height={120} width={200} className={styles['img']} />
                <div className={styles["horizontal-line"]}></div>
                <Skeleton count={4} height={12} width={150} className={styles['list-item']} />
                <div className={styles['price-tag']}>
                  <Skeleton height={32} width={100} />
                </div>
                <Skeleton height={35} width={150} className={styles['btn']} />
              </div>
            ))}
            </div>
          ) :
            (
              <div className={styles["main-container"]}>
                {packageDetails.map((item) => (
                  <div 
                  class={`
                    ${item?.id === 1 ? styles["grid-item"] :
                      item?.id === 2 ? styles["grid-item-starter"] : 
                      item?.id === 3 ? styles["grid-item-bisiness"] : 
                      item?.id === 4 ? styles["grid-item-enterprise"] : 
                      ""}
                  `}
                  >
                    <div className={styles['title']}>{formatPackageName(item?.name)}</div>
                    <div className={styles['sub-title']}>
                      {item?.description ? item?.description : "N/A"}
                    </div>
                    <img
                      className={styles['img']}
                      src={
                        item?.id === 1 ? freeImg :
                          item?.id === 2 ? basicImg :
                            item?.id === 3 ? standardImg :
                              item?.id === 4 ? premiumImg :
                                null
                      }
                      alt='pck-img'
                    />
                    <div 
                    className={`
                      ${item?.id === 1 ? styles["horizontal-line-free-trial"] : 
                        item?.id === 2 ? styles["horizontal-line-starter"] : 
                        item?.id === 3 ? styles["horizontal-line-business"] : 
                        item?.id === 4 ? styles["horizontal-line-enterprise"] : 
                        ""}
                      `}
                    >
                    </div>
                    {item?.features.map((feature) => (
                      <div className={styles['list-item']}>
                        <i style={{ fontSize: "16px", fontWeight: "700" }} class="ri-check-fill"></i>
                        <div>{capitalizeFirstLetter(feature?.feature_name)}</div>
                      </div>
                    ))}
                    <div className={styles['price-tag']}>
                      <span className={styles['currency']}>$</span>
                      <span className={styles['amount']}>{item?.price}</span>
                      {"/month"}
                    </div>
                    <Link 
                    to="/signup" 
                    className={`
                      ${item?.id === 1 ? styles['btn-free-trial'] :
                        item?.id === 2 ? styles['btn-stater'] :
                        item?.id === 3 ? styles['btn-business'] :
                        item?.id === 4 ? styles['btn-enterprise'] :
                         ""}
                      `}
                    >
                      Choose plan
                    </Link>
                  </div>
                ))
                }
              </div>
            )
          }
        </div>
      </div>
    </>
  );
};

export default ChooseYourPricePackage;
