import React from "react";
import { Link, useLocation } from "react-router-dom";
import { Accordion, Badge, Card } from "react-bootstrap";

import { formatDate } from "../../../utils/utils";
import DownloadButton from "../../common/DownloadButton";
import useAuth from "../../../hooks/useAuth";
import PaginationWithDataTable from "../../common/PagintionWithDataTable";
import Styles from "../../../assets/styles/components/tender-list/BidList.module.scss";

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const BidsAccordion = ({
  bidListItems,
  tenderAddUser,
  remainingDays,
  tenderStatus,
  iscustomerQuotations,
}) => {
  const query = useQuery();
  const tenderId = query.get("tenderId");
  const tenderName = query.get("tenderName");
  const isAuthenticated = useAuth();
  const loggedUserId = isAuthenticated.id;

  console.log('bidListItems', bidListItems)
  const columns = [
    {
      name: "Item name",
      selector: (row) => row.item_name,
    },
    {
      name: "Quantity",
      selector: (row) => row.quantity,
      width: "170px",
    },
    {
      name: "Price Per Item",
      selector: (row) =>
        new Intl.NumberFormat("en-US", {
          style: "currency",
          currency: "USD",
        }).format(row.price),
      width: "170px",
    },
    {
      name: "Total price",
      selector: (row) => {
        const totalPrice = row.price * row.quantity;
        return new Intl.NumberFormat("en-US", {
          style: "currency",
          currency: "USD",
        }).format(totalPrice);
      },
      width: "170px",
    },
  ];

  const hasAcceptedServiceProvider = bidListItems.some(
    (item) => item.is_accept_service_provider === 1
  );

  if (hasAcceptedServiceProvider) {
    return (
      <Accordion
        defaultActiveKey="0"
        className="accordion-custom"
      >
        { bidListItems.map((item) => {
          if (loggedUserId === item.user_id || loggedUserId === tenderAddUser) {
            const rows = Array.isArray(item.quotation_details)
              ? item.quotation_details.map(
                ({ item_name, quantity, price }) => ({
                  item_name,
                  quantity,
                  price,
                })
              )
              : [];

            return (
              <Accordion.Item as={ Card.Header } key={ item.id } eventKey={ item.id.toString() }>
                <Accordion.Header>
                  <h5 className="mb-0">
                    { loggedUserId === item.user_id
                      ? `#${item.id}. Your bid quotation`
                      : `#${item.id}. ${item.company.com_name} company bid quotation` }
                  </h5>

                  { item.customer_quotation_id > 0 ? (
                    <Badge className="Badge" bg="success" style={ { marginLeft: 12 } } >
                      <p className="mb-0 d-flex align-items-center fs-5">
                        <i className="ri-checkbox-circle-line"></i>{ " " }
                        <span> Selected</span>
                      </p>
                    </Badge>
                  ) : (
                    ""
                  ) }
                </Accordion.Header>
                <Accordion.Body>
                  <div className={ `row ${Styles.tender_info_list}` }>
                    <div className="col-md-12">
                      <div className={ Styles.media }>
                        <label>Quotation ID</label>
                        <p>{ item.id }</p>
                      </div>
                      <div className={ Styles.media }>
                        <label>Quotation note</label>
                        <p>{ item.bid_quotation_note }</p>
                      </div>
                      <div className={ Styles.media }>
                        <label>Company name</label>
                        <p>{ item.company.com_name }</p>
                      </div>
                      <div className={ Styles.media }>
                        <label>Quotation added date</label>
                        <p>{ formatDate(item.created_at) }</p>
                      </div>
                      { item.bid_quotation_document && <div className={ Styles.media }>
                        <label>Documents</label>
                        <p>
                          <DownloadButton
                            fileName={ item.bid_quotation_document }
                            toolTip={ "Click here download document" }
                          />
                        </p>
                      </div> }
                      <div className={ Styles.media }>
                        <label>Total price</label>
                        <p>{ `$${item.total_price}` }</p>
                      </div>
                    </div>
                  </div>
                  <div className="mt-4">
                    <PaginationWithDataTable
                      dataColumns={ columns }
                      entities={ rows }
                      loading={ false }
                      pagination={ false }
                    />
                    <div
                      className="d-flex justify-content-end p-3 bg-gray-200 border border-top-0"
                      style={ { fontSize: 20 } }
                    >
                      <strong className="flex-grow-1">Total Price: </strong>
                      <strong>{ `$${item.total_price}` }</strong>
                    </div>
                  </div>
                  {
                    loggedUserId === tenderAddUser &&
                      (item.is_accept_customer ||
                        item.is_accept_service_provider) &&
                      remainingDays <= 0 &&
                      (tenderStatus !== 6 || tenderStatus === 2) ? (
                      <div className="d-flex justify-content-end gap-2 mt-4">
                        <Link
                          type="button"
                          to={ `/customer_quotation?tenderId=${tenderId}&tenderName=${tenderName}&bidId=${item.id}` }
                          className="btn btn-primary icon-with-btn"
                        >
                          <i className="ri-mail-send-line"></i>
                          Update the Quotation and send to Customer
                        </Link>
                      </div>
                    ) : null
                  }
                </Accordion.Body>
              </Accordion.Item >
            );
          }
          return null;
        }) }
      </Accordion >
    );
  } else {
    return (
      <Accordion
        defaultActiveKey="0"
        className="accordion-secondary accordion-custom"
      >
        { bidListItems.map((item) => {
          if (loggedUserId === item.user_id || loggedUserId === tenderAddUser) {
            const rows = Array.isArray(item.quotation_details)
              ? item.quotation_details.map(
                ({ item_name, quantity, price }) => ({
                  item_name,
                  quantity,
                  price,
                })
              )
              : [];

            return (
              <Accordion.Item key={ item.id } eventKey={ item.id.toString() }>
                <Accordion.Header>
                  <h5 className="mb-0">
                    { loggedUserId === item.user_id
                      ? `#${item.id}. Your bid quotation`
                      : `#${item.id}. ${item.company.com_name} company bid quotation` }
                  </h5>

                  { item.customer_quotation_id > 0 ? (
                    <Badge bg="success" style={ { marginLeft: 12 } }>
                      <p className="mb-0 d-flex align-items-center fs-5 p-1">
                        <i className="ri-checkbox-circle-line"></i>{ " " }
                        <span> Selected</span>
                      </p>
                    </Badge>
                  ) : (
                    ""
                  ) }
                </Accordion.Header>
                <Accordion.Body>
                  <div className={ `row ${Styles.tender_info_list}` }>
                    <div className="col-md-12">
                      <div className={ Styles.media }>
                        <label>Quotation ID</label>
                        <p>{ item.id }</p>
                      </div>
                      <div className={ Styles.media }>
                        <label>Quotation note</label>
                        <p>{ item.bid_quotation_note }</p>
                      </div>
                      <div className={ Styles.media }>
                        <label>Company name</label>
                        <p>{ item.company.com_name }</p>
                      </div>
                      <div className={ Styles.media }>
                        <label>Quotation added date</label>
                        <p>{ formatDate(item.created_at) }</p>
                      </div>
                      { item.bid_quotation_document && <div className={ Styles.media }>
                        <label>Documents</label>
                        <p>
                          <DownloadButton
                            fileName={ item.bid_quotation_document }
                            toolTip={ "Click here download document" }
                          />
                        </p>
                      </div> }

                      <div className={ Styles.media }>
                        <label>Total price</label>
                        <p>{ `$${item.total_price}` }</p>
                      </div>
                    </div>
                  </div>
                  <div className="mt-4">
                    <PaginationWithDataTable
                      dataColumns={ columns }
                      entities={ rows }
                      loading={ false }
                      pagination={ false }
                    />
                    <div
                      className="d-flex justify-content-end p-3 bg-gray-200 border border-top-0"
                      style={ { fontSize: 20 } }
                    >
                      <strong className="flex-grow-1">Total Price: </strong>
                      <strong>{ `$${item.total_price}` }</strong>
                    </div>
                  </div>

                  { loggedUserId === tenderAddUser &&
                    remainingDays <= 0 &&
                    (tenderStatus !== 6 || tenderStatus !== 2) && (
                      <div className="d-flex justify-content-end gap-2 mt-4">
                        <Link
                          type="button"
                          to={ `/customer_quotation?tenderId=${tenderId}&tenderName=${tenderName}&bidId=${item.id}` }
                          className="btn btn-primary icon-with-btn"
                        >
                          <i className="ri-mail-send-line"></i> Update the
                          Quotation and send to Customer
                        </Link>
                      </div>
                    ) }
                </Accordion.Body>
              </Accordion.Item>
            );
          }
          return null;
        }) }
      </Accordion>
    );
  }
};

export default BidsAccordion;
