import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, Link, useLocation } from "react-router-dom";
import { Button, Card, Form, Row } from "react-bootstrap";

import {
  registerUser,
  emailVerificationLink,
} from "../store/actions/authActions";
import Logo from "../assets/img/icon_mob.png";
import bg from "../assets/img/bg/login.jpg";

const Signup = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const initialEmail = searchParams.get("email") || "";
  const [email, setEmail] = useState(initialEmail);
  const { loading, userInfo } = useSelector((state) => state.auth);
  const [errorList, setErrorList] = useState({});

  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
    trigger,
    clearErrors,
    setValue,
    getValues,
  } = useForm();
  const navigate = useNavigate();

  const validateField = (name, value) => {
    let error = "";

    switch (name) {
      case "first_name":
        if (!value) {
          error = "First name is required";
        }
        break;
      case "last_name":
        if (!value) {
          error = "Last name is required";
        }
        break;
      case "email":
        if (!value) {
          error = "Email is required";
        } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value)) {
          error = "Enter a valid email address";
        }
        break;
      case "password":
        if (!value) {
          error = "Password is required";
        } else if (!/(?=.*[A-Z])/.test(value)) {
          error = "Password must contain at least one uppercase letter";
        } else if (!/(?=.*\d)/.test(value)) {
          error = "Password must contain at least one number";
        } else if (!/(?=.*[!@#$%^&*])/.test(value)) {
          error = "Password must contain at least one special character";
        } else if (value.length < 8) {
          error = "Password must be at least 8 characters long";
        }
        break;
      case "r_password":
        if (!value) {
          error = "Confirm Password is required";
        } else if (value !== getValues("password")) {
          error = "The passwords do not match";
        }
        break;
      default:
        break;
    }

    if (error) {
      setErrorList((prev) => ({ ...prev, [name]: error }));
    } else {
      setErrorList((prev) => {
        const newErrors = { ...prev };
        delete newErrors[name];
        return newErrors;
      });
    }
  };

  const handleInputChange = async (e) => {
    const { name, value } = e.target;
    setValue(name, value);
    validateField(name, value);
    await trigger(name);
    if (name === "email") {
      setEmail(value);
    }
    await trigger(name);
  };

  const submitForm = async (data) => {
    await Object.keys(data).forEach((name) => {
      validateField(name, data[name]);
    });

    if (Object.keys(errorList).length !== 0) {
      return;
    }

    if (
      data.password &&
      data.r_password &&
      data.first_name &&
      data.last_name &&
      data.email
    ) {
      const email = data.email.toLowerCase();
      await dispatch(registerUser({ ...data, email })).then((response) => {
        if (response.payload === "save successfully") {
          sendEmailVerifiLink(email);
        }
      });
    }
  };

  const sendEmailVerifiLink = async (email) => {
    await dispatch(emailVerificationLink({ email })).then((response) => {
      if (response.payload.status === "success") {
        navigate("/signin");
      }
    });
  };

  useEffect(() => {
    if (userInfo?.is_verify === 1 && userInfo?.is_onboard === 1) {
      navigate("/dashboard");
    } else if (userInfo?.is_verify === 1 && userInfo?.is_onboard !== 1) {
      navigate("/onboard");
    }
  }, [userInfo, navigate]);

  return (
    <>
      <div className="page-sign" style={ { backgroundImage: `url(${bg})` } }>
        <Card className="card-sign card-signup">
          <Card.Header>
            <Link to="/" className={ `d-flex mb-4 justify-content-center` }>
              <img src={ Logo } alt="" width={ 140 } />
            </Link>
            <Card.Title>Sign Up</Card.Title>
            <Card.Text>It's free to signup and only takes a minute.</Card.Text>
          </Card.Header>
          <Card.Body>
            <Form onSubmit={ handleSubmit(submitForm) }>
              <div className="form-group" style={ { display: "none" } }>
                <input
                  type="text"
                  className="form-input"
                  value={ 0 }
                  { ...register("company_id") }
                  disabled={ loading }
                />
              </div>
              <Row className="mb-3">
                <Form.Label><span><i style={ { color: "#ff3300", fontSize: "10px" } } className="ri-asterisk"></i></span> Role</Form.Label>
                <div className="d-flex gap-3">
                  <Form.Check
                    type="radio"
                    name="role"
                    value="2"
                    id="role_customer"
                    defaultChecked
                    { ...register("user_role_id") }
                    disabled={ loading }
                  />
                  <label htmlFor="role_customer">Customer</label>
                  <Form.Check
                    type="radio"
                    name="role"
                    value="3"
                    id="role_company"
                    { ...register("user_role_id") }
                    disabled={ loading }
                  />
                  <label htmlFor="role_company">Company</label>
                </div>
              </Row>
              <Row>
                <div className="col-md-6 mb-3">
                  <div className="form-group">
                    <Form.Label htmlFor="firstName"><span><i style={ { color: "#ff3300", fontSize: "10px" } } className="ri-asterisk"></i></span> First Name</Form.Label>
                    <Form.Control
                      type="text"
                      className="form-input"
                      { ...register("first_name") }
                      disabled={ loading }
                      onChange={ handleInputChange }
                    />
                    { errorList.first_name && (
                      <div className="text-danger error-message">
                        { errorList.first_name }
                      </div>
                    ) }
                  </div>
                </div>
                <div className="col-md-6 mb-3">
                  <div className="form-group">
                    <Form.Label htmlFor="lastName"><span><i style={ { color: "#ff3300", fontSize: "10px" } } className="ri-asterisk"></i></span> Last Name</Form.Label>
                    <Form.Control
                      type="text"
                      className="form-input"
                      { ...register("last_name") }
                      disabled={ loading }
                      onChange={ handleInputChange }
                    />
                    { errorList.last_name && (
                      <div className="text-danger error-message">
                        { errorList.last_name }
                      </div>
                    ) }
                  </div>
                </div>
              </Row>
              <div className="form-group mb-3">
                <Form.Label htmlFor="email"><span><i style={ { color: "#ff3300", fontSize: "10px" } } className="ri-asterisk"></i></span> Email address</Form.Label>
                <Form.Control
                  type="email"
                  className="form-input"
                  value={ email }
                  { ...register("email") }
                  onChange={ handleInputChange }
                  disabled={ loading }
                />
                { errorList.email && (
                  <div className="text-danger error-message">
                    { errorList.email }
                  </div>
                ) }
              </div>
              <Row>
                <div className="col-md-6 mb-3">
                  <div className="form-group">
                    <Form.Label htmlFor="password"><span><i style={ { color: "#ff3300", fontSize: "10px" } } className="ri-asterisk"></i></span> Password</Form.Label>
                    <Form.Control
                      type="password"
                      className="form-input"
                      { ...register("password") }
                      disabled={ loading }
                      onChange={ handleInputChange }
                    />
                    { errorList.password && (
                      <div className="text-danger error-message">
                        { errorList.password }
                      </div>
                    ) }
                  </div>
                </div>
                <div className="col-md-6 mb-3">
                  <div className="form-group">
                    <Form.Label htmlFor="confirmPassword">
                      <span><i style={ { color: "#ff3300", fontSize: "10px" } } className="ri-asterisk"></i></span> Confirm Password
                    </Form.Label>
                    <Form.Control
                      type="password"
                      className="form-input"
                      { ...register("r_password") }
                      disabled={ loading }
                      onChange={ handleInputChange }
                    />
                    { errorList.r_password && (
                      <div className="text-danger error-message">
                        { errorList.r_password }
                      </div>
                    ) }
                  </div>
                </div>
              </Row>

              <div className="mb-4">
                <small>
                  By clicking <strong>Create Account</strong> below, you agree
                  to our terms of service and privacy statement.
                </small>
              </div>

              <Button
                type="submit"
                variant="primary"
                className="btn-sign w-100"
                disabled={ loading }
              >
                { loading ? (
                  <span
                    className="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>
                ) : (
                  "Create Account"
                ) }
              </Button>
            </Form>
          </Card.Body>
          <Card.Footer>
            Already have an account? <Link to="/signin">Sign In</Link>
          </Card.Footer>
        </Card>
      </div>
    </>
  );
};

export default Signup;
