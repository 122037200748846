import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import NFSLogo1 from "../../assets/img/icon.png";
import NFSLogo2 from "../../assets/img/icon_mob.png";
import "../../assets/styles/components/home/MenuBar.scss";

const MenuBar = () => {
  const [active, setActive] = useState(false);
  const navigate = useNavigate();

  const navigateToLogin = () => {
    navigate("/signin");
  };

  const menuActive = () => {
    setActive(!active);
  };

  return (
    <>
      <nav className="navbar navbar-area navbar-expand-lg animate__animated animate__fadeIn">
        <div className="container nav-container navbar-bg">
          <div className="responsive-mobile-menu">
            <button
              onClick={ menuActive }
              className={
                active
                  ? "menu toggle-btn d-block d-lg-none open"
                  : "menu toggle-btn d-block d-lg-none"
              }
            >
              <span className="icon-left" />
              <span className="icon-right" />
            </button>
          </div>
          <div className={ `logo` }>
            <Link to="/">
              <img className="desktop_logo" src={ NFSLogo1 } alt="Logo" />
              <img className="mob_logo" src={ NFSLogo2 } alt="Logo" />
            </Link>
          </div>
          <div
            className={
              active
                ? "collapse navbar-collapse sopen"
                : "collapse navbar-collapse"
            }
            id="itech_main_menu"
          >
            <ul className="navbar-nav menu-open text-lg-end">
              <li>
                <Link to="/#">Product</Link>
              </li>
              <li>
                <a
                  href="https://nordicfield.se/"
                  target="_blank"
                  rel="noreferrer"
                >
                  Web
                </a>
              </li>
              <li>
                <Link to="/help_and_support" >Support</Link>
              </li>
              <li>
                <Link to="/frequently_asked_questions" >FAQ's</Link>
              </li>
            </ul>
          </div>
          <div className="nav-right-part nav-right-part-desktop align-self-center">
            <button className="btn btn-primary mt-0" onClick={ navigateToLogin }>
              Get Access
            </button>
          </div>
        </div>
      </nav>
    </>
  );
};

export default MenuBar;
