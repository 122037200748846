import React from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { Card, Col, Row } from "react-bootstrap";
import "react-loading-skeleton/dist/skeleton.css";

const TenderCommentReplyLoading = ({}) => {
  return (
    <SkeletonTheme  baseColor="#e2e5ec" highlightColor="#f3f5f9">
      <Card style={{marginTop:"15px"}} className="mb-2">
        <Card.Body>
          <Row>
            <Col xs="auto">
              <Skeleton height={40} width={40} />
            </Col>
            <Col>
              <Card.Title>
                <Row>
                  <Skeleton height={10} width={200} />
                  <small className="text-muted">
                    <Skeleton height={5} width={80} />
                  </small>
                </Row>
              </Card.Title>
              <Card.Text>
                <Skeleton height={20} width={200} />
              </Card.Text>

              <div className="mt-3">
                <Card.Body>
                  <Row>
                    <Col xs="auto">
                      <Skeleton height={40} width={40} />
                    </Col>
                    <Col>
                      <Card.Title>
                        <Row>
                          <Skeleton height={10} width={200} />
                          <small className="text-muted">
                            <Skeleton height={5} width={80} />
                          </small>
                        </Row>
                      </Card.Title>
                      <Card.Text>
                        {" "}
                        <Skeleton height={20} width={200} />
                      </Card.Text>
                    </Col>
                  </Row>
                </Card.Body>
                <Card.Body>
                  <Row>
                    <Col xs="auto">
                      <Skeleton height={40} width={40} />
                    </Col>
                    <Col>
                      <Card.Title>
                        <Row>
                          <Skeleton height={10} width={200} />
                          <small className="text-muted">
                            <Skeleton height={5} width={80} />
                          </small>
                        </Row>
                      </Card.Title>
                      <Card.Text>
                        {" "}
                        <Skeleton height={20} width={200} />
                      </Card.Text>
                    </Col>
                  </Row>
                </Card.Body>
              </div>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </SkeletonTheme>
  );
};

export default TenderCommentReplyLoading;
