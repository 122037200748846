import React, { useState, useEffect } from 'react'
import { Button, Form, Row, Tooltip, OverlayTrigger, Spinner } from "react-bootstrap";
import { Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { Controller, useWatch } from 'react-hook-form';
import ReactDatePicker from 'react-datepicker';
import { CustomInput } from '../../utils/utils';
import PaginationWithDataTable from '../common/PagintionWithDataTable';
import { formatDate } from '../../utils/utils';
import FormModal from '../common/FormModal';
import PerfectScrollbar from "react-perfect-scrollbar";
import { post } from '../../servces/Api';
import Alert from '../common/Alert';
import "../../assets/styles/components/common/GeneralFeedbackReports.scss";
import * as XLSX from 'xlsx';
import { RatingTypes } from '../../utils/types';
import { useDispatch } from 'react-redux';
import { fetchInitialGeneralFeedbacksData, filterGeneralFeedbacksData } from '../../store/actions/reportActions';

function GeneralFeedbackReport() {
    const moment = require("moment");
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [submitLoading, setSubmitLoading] = useState(false)
    const [feedbackData, setFeedbackData] = useState([])
    const [viewMoreModal, setViewMoreModal] = useState({ open: false, data: [] });

    const [fromChanged, setFromChanged] = useState(false);
    const [toChanged, setToChanged] = useState(false);

    const [changedFrom, setChangedFrom] = useState(false);
    const [changedTo, setChangedTo] = useState(false);

    const thirtyDaysAgo = moment().subtract(30, 'days').toDate();
    const today = moment().toDate();

    const [maxDateFrom, setMaxDateFrom] = useState(today);
    const [minDateFrom, setMinDateFrom] = useState(null);
    const [maxDateTo, setMaxDateTo] = useState(today);
    const [minDateTo, setMinDateTo] = useState(null);

    const { register, setValue, handleSubmit, reset, control, formState: { errors }, watch } = useForm({
        defaultValues: {
            rate: "",
            from: thirtyDaysAgo,
            to: today,
        }
    });

    useEffect(() => {
        initialFeedbackList(thirtyDaysAgo, today)
    }, [])

    const initialFeedbackList = async (startDate, endDate) => {
        setLoading(true);
        const data = {
            rate: "",
            start_date: moment(startDate).format('YYYY-MM-DD'),
            end_date: moment(endDate).format('YYYY-MM-DD'),
        };
        const feedbacks = await dispatch(fetchInitialGeneralFeedbacksData(data));
        if (feedbacks.payload) {
            setFeedbackData(feedbacks.payload);
            setLoading(false);
        } else {
            setLoading(false);
        }
    };

    const toggleFormModal = () => {
        setViewMoreModal({ open: !viewMoreModal })
    }

    const onSubmit = async (data) => {
        setLoading(true);
        setSubmitLoading(true);
        let newData = {};
        newData.rate = data.rate !== "" ? data.rate : ""
        if (changedFrom && !changedTo) {
            newData.end_date = maxDateTo ? moment(maxDateTo).format("YYYY-MM-DD") : null;
            setValue('to', maxDateTo ? maxDateTo : '');
        } else {
            newData.end_date = data.to ? moment(data.to).format("YYYY-MM-DD") : null;
        }
        if (changedTo && !changedFrom) {
            newData.start_date = minDateFrom ? moment(minDateFrom).format("YYYY-MM-DD") : null;
            setValue('from', minDateFrom ? minDateFrom : '');
        } else {
            newData.start_date = data.from ? moment(data.from).format("YYYY-MM-DD") : null;
        }

        const feedbacks = await dispatch(filterGeneralFeedbacksData(newData));
        if (feedbacks.payload) {
            setFeedbackData(feedbacks.payload);
            setLoading(false);
            setSubmitLoading(false);
        } else {
            setLoading(false);
            setSubmitLoading(false);
        }

        setChangedFrom(false);
        setChangedTo(false);
    };

    const onReset = () => {
        initialFeedbackList(thirtyDaysAgo, today);
        reset({
            rate: "",
            from: thirtyDaysAgo,
            to: today,
        });
        setFromChanged(false);
        setToChanged(false);
        setMaxDateFrom(today);
        setMinDateFrom(null);
        setMaxDateTo(today);
        setMinDateTo(null);
    };

    //handle filter button disable
    const watchFields = watch(["rate"]);

    const watchFrom = useWatch({ control, name: "from" });
    const watchTo = useWatch({ control, name: "to" });

    function areDatesSame(date1, date2) {
        const d1 = new Date(date1);
        const d2 = new Date(date2);

        return (
            d1.getFullYear() === d2.getFullYear() &&
            d1.getMonth() === d2.getMonth() &&
            d1.getDate() === d2.getDate()
        );
    }

    useEffect(() => {
        if (watchFrom && !areDatesSame(watchFrom, thirtyDaysAgo)) {
            setFromChanged(true);
        } else {
            setFromChanged(false);
        }
    }, [watchFrom]);

    useEffect(() => {
        if (watchTo && !areDatesSame(watchTo, today)) {
            setToChanged(true);
        } else {
            setToChanged(false);
        }
    }, [watchTo]);

    const isFilterButtonDisabled = loading || (!watchFields.some(field => field) && !fromChanged && !toChanged);

    const validateFromField = (from, allValues) => {
        if (!from && allValues.to) {
            return "From date is required";
        }
        return true;
    };

    const validateToField = (to, allValues) => {
        if (!to && allValues.from) {
            return "To date is required";
        }
        return true;
    };

    //set table columns
    const columns = [
        {
            name: "ID",
            selector: (row) => row.id,
            width: "75px",
        },
        {
            name: "Full name",
            selector: (row) => row.full_name,
            width: "130px",
        },
        {
            name: "Email",
            selector: (row) => row.email,
        },
        {
            name: "Rating",
            selector: (row) => row.rate,
            width: "120px",
        },
        {
            name: "Action",
            selector: (row) => (
                <div className="d-flex flex-wrap gap-2">
                    <OverlayTrigger
                        placement="top"
                        overlay={<Tooltip>View questions</Tooltip>}
                    >
                        <div style={{ display: "inline-block" }}>
                            <Link
                                className="d-flex btn btn-primary btn-sm"
                                onClick={() => setViewMoreModal({ open: true, data: row })}
                            >
                                <i className="ri-eye-line"></i>
                            </Link>
                        </div>
                    </OverlayTrigger>
                </div>
            ),
            width: "105px",
        },
    ];

    //Set table rows data
    const rows = Array.isArray(feedbackData)
        ? feedbackData?.map(
            ({
                id,
                full_name,
                email,
                rate,
                questions,
            }) => ({
                id,
                full_name,
                email,
                rate,
                questions,
            })
        )
        : [];

    console.log(feedbackData)

    const generateExcel = (feedbackData) => {
        const formatDate = (dateTimeString) => {
            const date = new Date(dateTimeString);
            const dateOptions = { year: 'numeric', month: 'long', day: 'numeric' };
            const timeOptions = { hour: '2-digit', minute: '2-digit', hour12: true };
            const formattedDate = date.toLocaleDateString('en-US', dateOptions);
            return `${formattedDate}`;
        };

        const formattedData = feedbackData.map(data => ({
            'ID': data?.id,
            'Name': data?.full_name ? data.full_name : "N/A",
            'Email': data?.email ? data.email : "N/A",
            'Rating': data?.rate ? data.rate : "N/A",
            'Questions': data?.questions.map(({ question, answer }) => `Q: ${question}\nA: ${answer}`).join('\n\n'),
        }));

        const worksheet = XLSX.utils.json_to_sheet([]);

        XLSX.utils.sheet_add_aoa(worksheet, [
            [`General Feedback Report`],
            [`Date Range: ${formatDate(watchFrom)} - ${formatDate(watchTo)}`],
            [],
        ]);

        XLSX.utils.sheet_add_json(worksheet, formattedData, {
            origin: 'A4',
            skipHeader: false,
            header: [
                'ID',
                'Name',
                'Email',
                'Rating',
                'Questions',
            ],
        });

        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'FeedbackReport');

        XLSX.writeFile(workbook, 'GeneralFeedbackReport.xlsx');
    };

    return (
        <div>
            <Form onSubmit={handleSubmit(onSubmit)}>
                <Row>
                    <div className='col-md-4'>
                        <Form.Group>
                            <Form.Label>Select Rating </Form.Label>
                            <Form.Control
                                className="form-select"
                                as="select"
                                name="rate"
                                {...register("rate")}
                                disabled={loading || submitLoading || feedbackData.length <= 0}
                            >
                                <option value="">Select rating </option>
                                {RatingTypes &&
                                    RatingTypes.map((list) => (
                                        <option key={list.id} value={list.id}>
                                            {list.id}
                                        </option>
                                    ))
                                }
                            </Form.Control>
                        </Form.Group>
                    </div>
                    <div className="col-md-4 mb-3">
                        <Form.Label>From</Form.Label>
                        <Controller
                            control={control}
                            name="from"
                            rules={{ validate: (value) => validateFromField(value, watch()) }}
                            render={({ field }) => (
                                <ReactDatePicker
                                    disabled={loading || submitLoading}
                                    className="form-control"
                                    dateFormat="yyyy-MM-dd"
                                    dropdownMode="select"
                                    selected={field.value}
                                    onChange={(date) => {
                                        field.onChange(date);
                                        setChangedFrom(true);
                                        const thirtyDaysAfter = moment(date).add(30, 'days').toDate();
                                        setMinDateTo(date);
                                        if (thirtyDaysAfter > today) {
                                            setMaxDateTo(today);
                                        } else {
                                            setMaxDateTo(thirtyDaysAfter)
                                        }
                                    }}
                                    customInput={<CustomInput />}
                                    popperClassName="custom-datepicker-popper"
                                    maxDate={maxDateFrom}
                                    minDate={minDateFrom}
                                />
                            )}
                        />

                        {errors.from && (
                            <small className="text-danger">{errors.from.message}</small>
                        )}
                    </div>
                    <div className="col-md-4 mb-3">
                        <Form.Label>To</Form.Label>
                        <div>
                            <Controller
                                control={control}
                                name="to"
                                rules={{ validate: (value) => validateToField(value, watch()) }}
                                render={({ field }) => (
                                    <ReactDatePicker
                                        disabled={loading || submitLoading}
                                        className="form-control"
                                        dateFormat="yyyy-MM-dd"
                                        dropdownMode="select"
                                        selected={field.value}
                                        onChange={(date) => {
                                            field.onChange(date);
                                            const thirtyDaysAgo = moment(date).subtract(30, 'days').toDate();
                                            setMaxDateFrom(date);
                                            setMinDateFrom(thirtyDaysAgo);
                                            setChangedTo(true);
                                        }}
                                        customInput={<CustomInput />}
                                        popperClassName="custom-datepicker-popper"
                                        maxDate={maxDateTo}
                                        minDate={minDateTo}
                                    />
                                )}
                            />

                            {errors.to && (
                                <small className="text-danger">{errors.to.message}</small>
                            )}
                        </div>
                    </div>
                </Row>
                <div className='d-flex justify-content-end gap-3 mb-3'>
                    <div className="">
                        <Button type='submit' className='d-flex align-items-center gap-1' disabled={isFilterButtonDisabled}>
                            {submitLoading ? <Spinner animation="border" size="sm" /> : <i className="ri-filter-line"></i>}
                            Filter
                        </Button>
                    </div>
                    <div className="">
                        <Button onClick={onReset} className='d-flex align-items-center gap-1' disabled={isFilterButtonDisabled}>
                            <i className="ri-restart-line"></i>
                            Reset
                        </Button>
                    </div>
                    <div className="">
                        <Button disabled={loading || feedbackData.length <= 0 || submitLoading} onClick={() => generateExcel(feedbackData)} className='d-flex align-items-center gap-1'>
                            <i className="ri-file-excel-2-line"></i>
                            Generate Report
                        </Button>
                    </div>
                </div>
            </Form>
            <PaginationWithDataTable
                dataColumns={columns}
                entities={rows}
                loading={loading}
                rowCount={4}
                columnCount={5}
            />
            <FormModal
                moduleName={"Feedback Questions & Answers"}
                modalState={viewMoreModal.open}
                toggleFormModal={toggleFormModal}
                width="600px"
            >
                <PerfectScrollbar>
                    <div className="feedback-content">
                        {viewMoreModal?.data?.questions?.map((question, index) => (
                            <div key={index} className="question-container">
                                <h4 style={{ margin: '10px 0', color: '#333' }}>Question {index + 1}</h4>
                                <p className="question-text">Q : {question.question}</p>
                                <p className="answer-text">A : {question.answer}</p>
                            </div>
                        ))}
                    </div>
                </PerfectScrollbar>
            </FormModal>
        </div>
    )
}

export default GeneralFeedbackReport