import React from "react";
import { Form, InputGroup } from "react-bootstrap";

const SearchBar = ({ onChange, searchValue, handleKeyPress, placeholder, onClick, loading }) => {
  return (
    <InputGroup>

      {/* 
        can use featuer development this clde
        <InputGroup.Text>
        <i className="ri-search-line"></i> 
      </InputGroup.Text> */}
      <Form.Control
        type="text"
        placeholder={ placeholder }
        onChange={ (e) => onChange(e.target.value) }
        value={ searchValue }
        onKeyPress={ handleKeyPress }
        disabled={loading ? loading : false}
      />
      {/* <button className="btn btn-outline-secondary icon-with-btn" onClick={ handleReset } type="button"><i className="ri-refresh-line"></i> Clear</button> */ }
      <button disabled={loading ? loading : false} className="btn btn-primary icon-with-btn" onClick={ onClick } type="button" id="button-addon2"><i className="ri-search-line"></i> Search</button>
    </InputGroup>
  );
};

export default SearchBar;
