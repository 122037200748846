import React from "react";
import MenuBar from "../components/home/MenuBar";
import SupportBanner from "../assets/img/support_banner.jpg"
import CustomerSupportForm from "../components/customer-support/CustomerSupportForm"
import Footer from "../components/home/Footer";
import "../assets/styles/components/home/HelpAndSupport.scss";
import GoogleMapEmbed from "../components/common/GoogleMapEmbed";

const HelpAndSupport = () => {
  const location = "22, Blomdalsvägen 3b, Stockholm, Sweden";
  const zoom = 15;
  const apiKey = 'AIzaSyDmEDc4fzdy4ynvZkPsUnCqFgdtGbiclX0';

  return (
    <div className="">
      <MenuBar />
      <div className="position-relative" style={ { height: '400px', marginTop: '-137px' } }>
        <div
          className="position-absolute top-0 start-0 w-100 h-100"
          style={ {
            backgroundImage: `url(${SupportBanner})`,
            backgroundSize: 'cover',
          } }
        ></div>
        <div
          className="position-absolute top-0 start-0 w-100 h-100"
          style={ { backgroundColor: 'rgba(35, 35, 64, 0.5)' } }
        ></div>
        <div className="position-relative" style={ { zIndex: 1 } }>
        </div>
      </div>

      <div className="work-preference pd-top-60 pd-bottom-60 bg-light-gray">
        <div className="container">
          <div className="main-div">
            <div className="">
              <CustomerSupportForm />
            </div>
            <div className="right">
              <div className="top" >
                <div className="contact-details-container">
                  <div className="contact-detail">
                    <div className="contact-circle">
                      <i className="ri-map-pin-2-fill"></i>
                    </div>
                    <div className="text">
                      22, Blomdalsvägen 3b, Stockholm, Sweden
                    </div>
                  </div>
                  <div className="contact-detail">
                    <div className="contact-circle">
                      <i className="ri-phone-fill"></i>
                    </div>
                    <div className="text">
                      +46 (0)10 491 23 66
                    </div>
                  </div>
                  <div className="contact-detail">
                    <div className="contact-circle">
                      <i className="ri-send-plane-fill"></i>
                    </div>
                    <div className="text">
                      info@nordicfield.se
                    </div>
                  </div>
                  <div className="contact-detail">
                    <div className="contact-circle">
                      <i className="ri-earth-fill"></i>
                    </div>
                    <div className="text">
                      nordicfield.se
                    </div>
                  </div>
                </div>
              </div>
              <div className="bottom-div">
                <GoogleMapEmbed location={ location } zoom={ zoom } apiKey={ apiKey } />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default HelpAndSupport;
