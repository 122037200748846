import React from 'react';
import "../../assets/styles/components/common/GoogleMapEmbed.scss"

const GoogleMapEmbed = ({ location, zoom, apiKey }) => {
  const mapSrc = `https://www.google.com/maps/embed/v1/place?key=${apiKey}&q=${encodeURIComponent(location)}&zoom=${zoom}`;

  return (
    <div className='map-container'>
      <iframe
        title="Google Map"
        src={mapSrc}
        width="100%"
        height="100%"
        style={{ border: 0 }}
        allowFullScreen=""
        loading="lazy"
        referrerPolicy="no-referrer-when-downgrade"
      />
    </div>
  );
};

export default GoogleMapEmbed;
