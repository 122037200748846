export const ROLES = {
  ADMIN: 1,
  CUSTOMER: 2,
  COMPANY: 3,
  DELIVERY: 4,
  FIELD: 5,
};

export const LOGOUT_USER = "LOGOUT_USER";

export const TENDER_TYPES = {
  RFQ: "Request for Quotation (RFQ)",
  RFP: "Request for Proposal (RFP)",
  ITT: "Invitation to Tender (ITT)",
};

export const TENDER_OPTIONS = {
  ALL: "All",
  FIXED_PRICE: "Fixed Price",
  HOURLY_RATE: "Hourly Rate",
  MILESTONE: "Milestone-based",
};

export const projectUserTypes = [
  { id: 2, type: "Service Provider" },
  { id: 3, type: "3rd Party Company" },
  { id: 4, type: "Field Visit" },
  { id: 5, type: "Delivery Agent" },
];

export const TASK_STATUS_TYPE_LABEL = {
  IN_PROGRESS: "In Progress",
  DONE: "Done",
  HOLD: "On Hold",
  TODO: "To Do",
  VERIFY: "Verify",
  CANCEL: "Cancel",
};

export const RatingTypes = [
  {
    id: "1"
  },
  {
    id: "2"
  },
  {
    id: "3"
  },
  {
    id: "4"
  },
  {
    id: "5"
  },
]

export const projectReportTypes = [
  {
    id: "1",
    name: "Company Project Report"
  },
]

export const completeStatusTypes = [
  {
    id: "1",
    name: "Completed"
  },
  {
    id: "0",
    name: "Not Completed"
  },
]


export const userReportTypes = [
  {
    id: "1",
    name: "Admin User Report"
  },
]

export const userPackagesTypes = [
  {
    id: "1",
    lable: "Free Trail"
  },
  {
    id: "2",
    lable: "Starter"
  },
  {
    id: "3",
    lable: "Business"
  },
  {
    id: "4",
    lable: "Enterprise"
  },
  {
    id: "5",
    lable: "Customer Free Package"
  },
]

export const userRoleTypes = [
  {
    id: "2",
    lable: "Customer"
  },
  {
    id: "3",
    lable: "Company"
  },
  {
    id: "4",
    lable: "Delivery Agent"
  },
  {
    id: "5",
    lable: "Field Agent"
  },
]

export const userStatusTypes = [
  {
    id: "1",
    lable: "Active"
  },
  {
    id: "0",
    lable: "Inactive"
  }
]

export const tenderStatusTypes = [
  {
    id: "1",
    name: "Active"
  },
  {
    id: "0",
    name: "Close"
  },
]

export const tenderReportTypes = [
  {
    id: "1",
    name: "Admin Tender Report"
  }
]

export const tenderReportTypes2 = [
  {
    id: "2",
    name: "Company Tender Report"
  },
  {
    id: "3",
    name: "Company Tender - Bid Report"
  },
  {
    id: "4",
    name: "Tender - Customer Quotation Report"
  },
]

export const reportTypes = [
  {
    id: "1",
    name: "General Feedback Report"
  }
]

export const reportTypes2 = [
  {
    id: "2",
    name: "Feedback Analysis Report"
  },
  {
    id: "3",
    name: "Customer Feedback Report"
  }
]

export const TASK_STATUS_TYPE = [
  {
    id: 1,
    name: TASK_STATUS_TYPE_LABEL.IN_PROGRESS,
  },
  {
    id: 2,
    name: TASK_STATUS_TYPE_LABEL.DONE,
  },
  {
    id: 3,
    name: TASK_STATUS_TYPE_LABEL.HOLD,
  },
  {
    id: 4,
    name: TASK_STATUS_TYPE_LABEL.TODO,
  },
  {
    id: 5,
    name: TASK_STATUS_TYPE_LABEL.VERIFY,
  },
  {
    id: 6,
    name: TASK_STATUS_TYPE_LABEL.CANCEL,
  },
];

export const TENDER_PROGRESS = [
  { id: 1, type: "Open to bidding", bg: "success" },
  { id: 2, type: "Cancelled", bg: "danger" },
  { id: 3, type: "Customer quotation created", bg: "warning" },
  { id: 4, type: "Quotation sent to customer", bg: "info" },
  { id: 5, type: "The customer accepted the quotation", bg: "success" },
  { id: 6, type: "The customer rejected the quotation", bg: "danger" },
  { id: 7, type: "Bidding is over", bg: "danger" },
];

export const SAVE_FEEDBACK_SUCCESS = "SAVE_FEEDBACK_SUCCESS";

export const FILTER_TYPE = {
  PERSON: "PERSON",
  SORT: "SORT",
  FILTER: "FILTER",

};

export const FAQCategoriesList = [
  "General",
  "Company Service Providers",
  "Customer",
  "Administrator",
  "Technical Support",
  "Legal and Compliance",
]

export const FAQDataList = [
  {
    question: "What is the Abdolit tender management system?",
    answer: "Abdolit is a comprehensive web-based platform designed to streamline the tender management process. It provides tools for creating, managing, and tracking tenders, facilitating bid submissions, and ensuring secure and efficient procurement processes.",
    points: [],
    category: "General",
  },
  {
    question: "Who can use the Abdolit system?",
    answer: "The Abdolit system is designed for three main user groups :",
    points: [
      "Customers : To manage and oversee their projects and tenders.",
      "Company Service Providers : To create and manage tenders, place bids, manage quotations and handle project tasks",
      "System Administrators : To manage system settings, user permissions, and ensure smooth operation.",
    ],
    category: "General",
  },
  {
    question: "Is there a user guide or tutorial available?",
    answer: "Yes, a comprehensive user guide and tutorials are available in the help center to assist you with using the system.",
    points: [],
    category: "General",
  },
  {
    question: "How do I create a new tender?",
    answer: "To create a new tender, log in to your Abdolit account and navigate to the Tender Management section. Click on \"Create Tender\", fill out the required details such as project scope, requirements, deadlines, and budget, and attach any necessary documents before publishing.",
    points: [],
    category: "Company Service Providers",
  },
  {
    question: "How can I invite suppliers to bid on my tender?",
    answer: "Once you have created a tender, suppliers can view it on the platform. You can share the tender details directly with suppliers through your preferred communication channels.",
    points: [],
    category: "Company Service Providers",
  },
  {
    question: "How do I manage submitted bids?",
    answer: "Navigate to the Tender Management section and select the relevant tender to view submitted bids. You can review bid details, compare them based on your criteria.",
    points: [],
    category: "Company Service Providers",
  },
  {
    question: "Can I extend the deadline for a tender?",
    answer: "Yes, you can extend the deadline for a tender if it’s created by your company :",
    points: [
      "Go to the Tender Management section.",
      "Click on the \"Edit\" button next to the relevant tender.",
      "Update the closing date",
      "Click on the \“Update Tender”\ button to save the new deadline."
    ],
    category: "Company Service Providers",
  },
  {
    question: "How do I ensure the confidentiality of the bids?",
    answer: "Abdolit employs secure protocols to protect bid confidentiality. Ensure that access to tender details is restricted to authorized users only and that your account settings are configured to maintain privacy.",
    points: [],
    category: "Company Service Providers",
  },
  {
    question: "How do I find available tenders to bid on?",
    answer: "Log in to your Abdolit account and go to the Tender Search section. Use the search filters to find tenders that match your business interests and capabilities. You can filter the category of each tender.",
    points: [],
    category: "Company Service Providers",
  },
  {
    question: "How do I submit a bid?",
    answer: "To submit a bid, select the relevant tender from your search results, click on \"View More,\" to access tender details. And click on \“Add Bid”\ button and enter required details and submit your bid.",
    points: [],
    category: "Company Service Providers",
  },
  {
    question: "Can I modify my bid after submission?",
    answer: "No, once a bid is submitted, it cannot be modified. Ensure all details are accurate before submitting your bid.",
    points: [],
    category: "Company Service Providers",
  },
  {
    question: "How do I get notified of new tenders?",
    answer: "You can enable notifications in your account settings to receive alerts about new tenders that match your criteria. Additionally, you may receive email notifications.",
    points: [],
    category: "Company Service Providers",
  },
  {
    question: "How does Abdolit ensure a secure bidding process?",
    answer: "Abdolit employs advanced security measures including encryption for data transmission, secure servers for data storage, and strict access controls to protect the integrity and confidentiality of the bidding process.",
    points: [],
    category: "Company Service Providers",
  },
  {
    question: "Can I track the status of my tender or bid?",
    answer: "Yes, you can track the status of your tender or bid through your dashboard. Updates will be reflected in real-time.",
    points: [],
    category: "Company Service Providers",
  },
  {
    question: "Can I communicate with service providers regarding a quotation?",
    answer: "Yes, Abdolit provides integrated communication tools that allow you to chat with service providers directly from the project details page.",
    points: [],
    category: "Customer",
  },
  {
    question: "How do I accept or reject a quotation?",
    answer: "To accept or reject a quotation, go to the email that you received including your quotation, open the quotation and select the appropriate action (accept or reject).",
    points: [],
    category: "Customer",
  },
  {
    question: "What happens after I accept a quotation?",
    answer: "Once you accept a quotation, it automatically transitions into an active project, and you can start tracking its progress and communicate with the service provider.",
    points: [],
    category: "Customer",
  },
  {
    question: "How do I track the progress of my projects?",
    answer: "You can track project progress through the \"Project’s chat\" section in your projects, where you will see key metrics, milestones, and status updates.",
    points: [],
    category: "Customer",
  },
  {
    question: "Can I view all ongoing and completed projects?",
    answer: "Yes, the \"Projects\" section allows you to view and manage both ongoing and completed projects, providing detailed information and communication options for each project.",
    points: [],
    category: "Customer",
  },
  {
    question: "What is the role of an admin in Abdolit?",
    answer: "Admins in Abdolit oversee the management of user accounts, subscription management, system configurations, and overall platform operations to ensure smooth and efficient functioning.",
    points: [],
    category: "Administrator",
  },
  {
    question: "How do I create a new user account?",
    answer: "To create a new user account, go to the \"Manage User Accounts\" section in the admin panel, click on \"Create user\” button and fill in the required details.",
    points: [],
    category: "Administrator",
  },
  {
    question: "Can I assign different roles to users?",
    answer: "Yes, you can assign different roles to users, such as customer, service provider, or admin, based on their responsibilities and access requirements.",
    points: [],
    category: "Administrator",
  },
  {
    question: "How do I manage user permissions?",
    answer: "User permissions can be managed by navigating to the \"Permissions\" section in the admin panel, where you can define and modify access levels for different roles and individual users.",
    points: [],
    category: "Administrator",
  },
  {
    question: "How can I deactivate or delete a user account?",
    answer: "To deactivate a user account, go to the \"User Management\" section, select the user, and choose the appropriate action.",
    points: [],
    category: "Administrator",
  },
  {
    question: "What should I do if I forget my password?",
    answer: "If you forget your password, use the \"Forgot Password\" link on the login page. Enter your registered email address to receive a verification code. Follow the instructions to set a new password.",
    points: [],
    category: "Technical Support",
  },
  {
    question: "How do I update my profile information?",
    answer: "Log in to your Abdolit account and go to the Profile section. You can update your personal information, contact details, and preferences. Make sure to save any changes.",
    points: [],
    category: "Technical Support",
  },
  {
    question: "What if I encounter a technical issue while using the system?",
    answer: "Contact technical support through the help center or submit a support mail detailing the issue, including any error messages or screenshots. Our support team will assist you promptly.",
    points: [],
    category: "Technical Support",
  },
  {
    question: "What file formats are accepted for document uploads?",
    answer: "The system accepts common file formats such as PDF, DOCX, XLSX, and JPG for document uploads. Ensure your files are within the size limits specified in the tender requirements.",
    points: [],
    category: "Technical Support",
  },
  {
    question: "Are there any terms and conditions I should be aware of?",
    answer: "Yes, all users must agree to the Abdolit Terms of Service and Privacy Policy. These documents outline your rights, responsibilities, and the terms under which you use the system. You can review them in the footer of the website or during the registration process.",
    points: [],
    category: "Legal and Compliance",
  },
  {
    question: "How is my data protected?",
    answer: "Abdolit takes data protection seriously. We use encryption protocols and secure servers to protect your data during transmission and storage. Additionally, access to data is controlled through rolebased permissions to ensure only authorized users can view or modify it.",
    points: [],
    category: "Legal and Compliance",
  },
  {
    question: "Can I withdraw my bid after submission?",
    answer: "No, once a bid is submitted through Abdolit, it cannot be withdrawn. Ensure all bid details are final and accurate before submission. If you have concerns, contact the tender issuer directly for further instructions.",
    points: [],
    category: "Legal and Compliance",
  },
]