import React, { useState, useEffect } from "react";
import { Button, Tooltip, OverlayTrigger, Row, Form } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import FormModal from "../../common/FormModal";
import BidQuotation from "./BidQuotation";
import LimitationExceedModal from "../../common/LimitationExceedModal";
import UploadFile from "../../common/UploadFile";
import { get } from "../../../servces/Api";
import {
  addBidForTender,
  tenderView,
  fetchBidListTender,
} from "../../../store/actions/tenderActions";
import Alert from "../../common/Alert";

const AddBidModal = ({ tenderId }) => {
  const dispatch = useDispatch();
  const loggedUser = useSelector((state) => state.auth.userInfo);
  const [showModalState, setShowModalState] = useState(false);
  const [bidQuotationData, setBidQuotationData] = useState([]);
  const [showExceedPackageModalState, setShowExceedPackageModalState] =
    useState(false);
  const [attachmentDocName, setAttachmentDocName] = useState("");
  const [bidCount, setBidCount] = useState();
  const { loading } = useSelector((state) => state.tender);
  const {
    register,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    const fetchbidCount = async () => {
      try {
        const response = await get("/api/get_bid_quotation_count_by_company");
        setBidCount(response?.data?.bid_quotation_count);
      } catch (error) {
        console.error("Error fetching bid count", error);
      }
    };

    fetchbidCount();
  }, []);

  const handleBidCreate = () => {
    const bidLimit = loggedUser?.package_detais?.bid_limit;
    if (bidLimit) {
      if (bidCount && bidCount >= bidLimit && loggedUser.user_role !== "1") {
        showExceedPackageModal();
      } else {
        toggleFormModal();
      }
    } else {
      toggleFormModal();
    }
  };

  const toggleFormModal = () => {
    setShowModalState(!showModalState);
    reset();
  };

  const closeExceedPackageModal = () => {
    setShowExceedPackageModalState(false);
  };
  const showExceedPackageModal = () => {
    setShowExceedPackageModalState(true);
  };

  const onSubmit = async (data) => {
    // Calculate the total price
    if (bidQuotationData.length > 0) {
      const totalPrice = bidQuotationData.reduce(
        (acc, item) => acc + item.qty * item.price,
        0
      );

      const details = bidQuotationData.map((item) => ({
        item_name: item.itemName,
        quantity: item.qty,
        price: item.price,
      }));

      const submissionData = {
        ...data,
        tender_id: tenderId,
        details: details,
        total_price: totalPrice,
        bid_quotation_document: attachmentDocName,
      };
      // Dispatch your action or handle the submission here
      await dispatch(addBidForTender(submissionData))
        .then(() => {
          dispatch(tenderView(tenderId));
          dispatch(fetchBidListTender(tenderId));
        })
        .then(() => {
          toggleFormModal();
        });
    } else {
      Alert.error("Bid Quotation item not added!");
    }
  };
  return (
    <>
      <OverlayTrigger
        placement="top"
        overlay={ <Tooltip>Click here Add bid</Tooltip> }
      >
        <Button variant="success" onClick={ handleBidCreate }>
          <i className="ri-vip-crown-line"></i> <span>Add bid</span>
        </Button>
      </OverlayTrigger>
      <FormModal
        moduleName={ "Add Quotation" }
        modalState={ showModalState }
        toggleFormModal={ toggleFormModal }
        size="xl"
      //width="900px"
      >
        <Form onSubmit={ handleSubmit(onSubmit) }>
          <Row>
            <div className="col-md-12">
              <div className="form-group">
                <Form.Label><span><i style={ { color: "#ff3300", fontSize: "10px" } } className="ri-asterisk"></i></span> Bid Note</Form.Label>
                <Form.Control
                  disabled={ loading }
                  as="textarea"
                  { ...register("bid_quotation_note", {
                    required: "Tender description is required",
                    maxLength: {
                      value: 1000,
                      message: "Maximum length is 1000 characters",
                    },
                  }) }
                  placeholder="Tender description"
                  className="textarea"
                ></Form.Control>
                { errors.bid_quotation_note && (
                  <small className="text-danger">
                    { errors.bid_quotation_note.message }
                  </small>
                ) }
              </div>
            </div>
          </Row>
          <div className="col-md-12 mt-3">
            <div className="form-group">
              <UploadFile
                lableName="Upload Tender Document "
                uploadInfo="[.pdf, .doc / Max file size 4Mb]"
                disabled={ loading }
                setFileName={ setAttachmentDocName }
                loading={ loading }
              />
            </div>
          </div>

          <BidQuotation
            setBidQuotationData={ setBidQuotationData }
            toggleFormModal={ toggleFormModal }
          />

          <div className="d-flex flex-wrap gap-2 mt-4">
            <Button
              type="button"
              variant="outline-primary"
              className="btn-sign"
              onClick={ () => {
                toggleFormModal();
              } }
              disabled={ loading }
            >
              Cancel
            </Button>
            <Button
              type="submit"
              variant="primary"
              className="btn-sign"
              disabled={ loading }
            >
              { loading ? (
                <>
                  <span
                    className="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>{ " " }
                  Submit bid
                </>
              ) : (
                "Submit bid"
              ) }
            </Button>
          </div>
        </Form>
      </FormModal>
      <LimitationExceedModal
        showViewModal={ showExceedPackageModalState }
        closeModal={ closeExceedPackageModal }
        name="bid limit"
      />
    </>
  );
};

export default AddBidModal;
