import React, { useState, useEffect } from 'react'
import { Button, Form, Row, Tooltip, OverlayTrigger, Spinner, Badge, Card, ListGroup, Dropdown } from "react-bootstrap";
import { Controller, useForm, useWatch } from 'react-hook-form';
import ReactDatePicker from 'react-datepicker';
import { CustomInput } from '../../utils/utils';
import PaginationWithDataTable from '../common/PagintionWithDataTable';
import FormModal from '../common/FormModal';
import { post, get } from '../../servces/Api';
import Alert from '../common/Alert';
import "../../assets/styles/components/common/GeneralFeedbackReports.scss";
import NFSLogoDark from "../../assets/img/icon.png";
import HeaderImg from "../../assets/img/default-banner-img.jpg";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import * as XLSX from 'xlsx';
import CustomSearchSelect from '../common/CustomSearchSelect';
import { completeStatusTypes } from '../../utils/types';
import { useDispatch } from 'react-redux';
import { fetchCategoryListData, fetchCompanyProjectListData, fetchInitialCompanyProjectsData, filterCompanyProjectsData } from '../../store/actions/reportActions';

function CompanyProjectReport() {
    const moment = require("moment");
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [submitLoading, setSubmitLoading] = useState(false);
    const [projectsData, setProjectData] = useState([]);
    const [completeStatusData, setCompleteStatusData] = useState(completeStatusTypes);
    const [companyProjectData, setCompanyProjectData] = useState([]);
    const [categoryData, setCategoryData] = useState([]);
    const [projectTaskStatusData, setProjectTaskStatusData] = useState([]);
    const [viewMoreModal, setViewMoreModal] = useState({ open: false, data: [] });
    const [generateButtonLoading, setGenerateButtonLoading] = useState(false);

    const [fromChanged, setFromChanged] = useState(false);
    const [toChanged, setToChanged] = useState(false);

    const [changedFrom, setChangedFrom] = useState(false);
    const [changedTo, setChangedTo] = useState(false);

    const thirtyDaysAgo = moment().subtract(30, 'days').toDate();
    const today = moment().toDate();

    const [maxDateFrom, setMaxDateFrom] = useState(today);
    const [minDateFrom, setMinDateFrom] = useState(null);
    const [maxDateTo, setMaxDateTo] = useState(today);
    const [minDateTo, setMinDateTo] = useState(null);

    const { register, setValue, handleSubmit, reset, control, formState: { errors }, watch } = useForm({
        defaultValues: {
            project_id: "",
            category_id: "",
            is_completed: "",
            task_status_id: "",
            assignee_id: "",
            from: thirtyDaysAgo,
            to: today
        }
    });

    const [selectedReport, setSelectedReport] = useState('Generate Report');

    const handleSelect = (eventKey) => {
        setSelectedReport(eventKey);
        if (eventKey === 'Generate PDF') {
            handleGenerateReport(projectsData);
        } else if (eventKey === 'Generate Excel') {
            generateExcel(projectsData);
        }
    };

    useEffect(() => {
        initialProjectsList(thirtyDaysAgo, today);
        fetchCompanyProjectList();
        fetchCategoryList();
    }, [])

    const initialProjectsList = async (startDate, endDate) => {
        setLoading(true);
        const data = {
            project_id: "",
            category_id: "",
            is_completed: "",
            task_status_id: "",
            assignee_id: "",
            start_date: moment(startDate).format('YYYY-MM-DD'),
            end_date: moment(endDate).format('YYYY-MM-DD'),
        };
        const projects = await dispatch(fetchInitialCompanyProjectsData(data));
        if (projects.payload) {
            setProjectData(projects.payload);
            setLoading(false);
        } else {
            setLoading(false);
        }
    };

    const fetchCompanyProjectList = async () => {
        const projects = await dispatch(fetchCompanyProjectListData());
        if (projects.payload) {
            setCompanyProjectData(projects.payload);
        }
    };

    const fetchCategoryList = async () => {
        const categories = await dispatch(fetchCategoryListData());
        if (categories.payload) {
            setCategoryData(categories.payload);
        }
    };

    const toggleFormModal = () => {
        setViewMoreModal({ open: !viewMoreModal })
    }

    const onSubmit = async (data) => {
        setLoading(true);
        setSubmitLoading(true)
        let newData = {};
        newData.project_id = data.project_id !== "" ? data.project_id : "";
        newData.category_id = data.category_id !== "" ? data.category_id : "";
        newData.is_completed = data.is_completed !== "" ? data.is_completed : "";
        newData.task_status_id = data.task_status_id !== "" ? data.task_status_id : "";
        newData.assignee_id = data.assignee_id !== "" ? data.assignee_id : "";
        if (changedFrom && !changedTo) {
            newData.end_date = maxDateTo ? moment(maxDateTo).format("YYYY-MM-DD") : null;
            setValue('to', maxDateTo ? maxDateTo : '');
        } else {
            newData.end_date = data.to ? moment(data.to).format("YYYY-MM-DD") : null;
        }
        if (changedTo && !changedFrom) {
            newData.start_date = minDateFrom ? moment(minDateFrom).format("YYYY-MM-DD") : null;
            setValue('from', minDateFrom ? minDateFrom : '');
        } else {
            newData.start_date = data.from ? moment(data.from).format("YYYY-MM-DD") : null;
        }

        const projects = await dispatch(filterCompanyProjectsData(newData));
        if (projects.payload) {
            setProjectData(projects.payload);
            setLoading(false);
            setSubmitLoading(false);
        } else {
            setLoading(false);
            setSubmitLoading(false);
        }

        setChangedFrom(false);
        setChangedTo(false);
    };

    const handleGenerateReport = (projectsData) => {
        setGenerateButtonLoading(true);
        generateReportPDF(projectsData).then(() => {
            setGenerateButtonLoading(false);
        }).catch((error) => {
            Alert.error("Error generating PDF:", error)
            console.error("Error generating PDF:", error);
            setGenerateButtonLoading(false);
        });
    };

    const generateReportPDF = (data) => {
        const formatDate = (dateTimeString) => {
            const date = new Date(dateTimeString);
            const dateOptions = { year: 'numeric', month: 'long', day: 'numeric' };
            const timeOptions = { hour: '2-digit', minute: '2-digit', hour12: true };
            const formattedDate = date.toLocaleDateString('en-US', dateOptions);
            const formattedTime = date.toLocaleTimeString('en-US', timeOptions);
            return `${formattedDate}`;
        };
        return new Promise((resolve, reject) => {
            try {
                const doc = new jsPDF("p", "mm", "a4");
                const imgWidth = doc.internal.pageSize.getWidth();
                const imgHeight = 40;

                // Add letterhead
                const imgData = HeaderImg;
                doc.addImage(imgData, "JPEG", 0, 0, imgWidth, imgHeight);

                // title
                doc.setFontSize(16);
                doc.setTextColor(0, 0, 0);
                doc.text("Company Project Report", doc.internal.pageSize.getWidth() / 2, 50, {
                    align: "center",
                });

                doc.setFontSize(11);
                doc.text(`(From : ${formatDate(watchFrom)} - To : ${formatDate(watchTo)})`, doc.internal.pageSize.getWidth() / 2, 55, {
                    align: "center",
                });

                // Content below the header
                const tableColumn = [
                    "ID",
                    "Project",
                    "Value",
                    "Category",
                    "Description",
                    "Deadline",
                    "Complete Status",
                    "Created At",
                ];

                // Function to format date
                const formatDateTime = (dateTimeString) => {
                    const date = new Date(dateTimeString);
                    const dateOptions = { year: 'numeric', month: 'long', day: 'numeric' };
                    const timeOptions = { hour: '2-digit', minute: '2-digit', hour12: true };
                    const formattedDate = date.toLocaleDateString('en-US', dateOptions);
                    const formattedTime = date.toLocaleTimeString('en-US', timeOptions);
                    return `${formattedDate}, ${formattedTime}`;
                };

                const tableRows =
                    data &&
                    data.length > 0 &&
                    data.map((row) => [
                        row.id,
                        row.project_title,
                        row.project_value,
                        row.category.name ? row.category.name : "N/A",
                        row.project_description,
                        formatDateTime(row.project_deadline),
                        row.is_completed === 1 ? "Completed" : "Incomplete",
                        formatDateTime(row.created_at),
                    ]);

                doc.autoTable({
                    head: [tableColumn],
                    body: tableRows,
                    startY: 60,
                    theme: "grid",
                    headStyles: { fillColor: [35, 35, 64] },
                    styles: { cellPadding: 3, fontSize: 9, halign: 'center' },
                    margin: { left: 5, right: 5 },
                });

                // Add footer
                const pageCount = doc.internal.getNumberOfPages();
                for (let i = 1; i <= pageCount; i++) {
                    doc.setPage(i);
                    const footerHeight = 12;
                    const footerY = doc.internal.pageSize.getHeight() - footerHeight;

                    doc.setFillColor(35, 35, 64);
                    doc.rect(0, footerY, imgWidth, footerHeight, "F");

                    const logoData = NFSLogoDark;
                    const logoWidth = 16;
                    const logoHeight = 8;
                    const logoX = imgWidth - logoWidth - 5;
                    doc.addImage(logoData, "JPEG", logoX, footerY + (footerHeight - logoHeight) / 2, logoWidth, logoHeight);

                    doc.setTextColor(255, 255, 255);
                    doc.setFontSize(10);
                    doc.text(`Page ${i} of ${pageCount}`, 10, footerY + 7);
                }

                window.open(doc.output("bloburl"), "_blank");
                resolve();
            } catch (error) {
                reject(error);
            }
        });
    };

    const generateExcel = (data) => {
        const formatDate = (dateTimeString) => {
            const date = new Date(dateTimeString);
            const dateOptions = { year: 'numeric', month: 'long', day: 'numeric' };
            const timeOptions = { hour: '2-digit', minute: '2-digit', hour12: true };
            const formattedDate = date.toLocaleDateString('en-US', dateOptions);
            return `${formattedDate}`;
        };

        const formattedData = data.map(item => ({
            'ID': item?.id,
            'Project': `${item?.project_title ? item.project_title : "N/A"}`,
            'Value': item?.project_value ? item?.project_value : "N/A",
            'Category': `${item?.category?.name ? item.category?.name : "N/A"}`,
            'Description': `${item?.project_description ? item?.project_description : "N/A"}`,
            'Deadline': item?.project_deadline ? formatDateTime(item.project_deadline) : "N/A",
            'Complete Status': `${item?.is_completed === 1 ? "Completed" : "Incomplete"}`,
            'Created At': item?.created_at ? formatDateTime(item.created_at) : "N/A",
        }));

        const worksheet = XLSX.utils.json_to_sheet([]);

        XLSX.utils.sheet_add_aoa(worksheet, [
            [`Company Project Report`],
            [`Date Range: ${formatDate(watchFrom)} - ${formatDate(watchTo)}`],
            [],
        ]);

        XLSX.utils.sheet_add_json(worksheet, formattedData, {
            origin: 'A4',
            skipHeader: false,
            header: [
                'ID',
                'Project',
                'Value',
                'Category',
                'Description',
                'Deadline',
                'Complete Status',
                'Created At',
            ],
        });

        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'ProjectDetails');

        XLSX.writeFile(workbook, 'CompanyProjectReport.xlsx');
    };

    const onReset = () => {
        initialProjectsList(thirtyDaysAgo, today);
        reset({
            project_id: "",
            category_id: "",
            is_completed: "",
            task_status_id: "",
            assignee_id: "",
            from: thirtyDaysAgo,
            to: today,
        });
        setFromChanged(false);
        setToChanged(false);
        setMaxDateFrom(today);
        setMinDateFrom(null);
        setMaxDateTo(today);
        setMinDateTo(null);
        setSelectedReport('Generate Report');
    };

    //handle button disble
    const watchFields = watch(["project_id", "category_id", "is_completed"]);

    const watchFrom = useWatch({ control, name: "from" });
    const watchTo = useWatch({ control, name: "to" });

    function areDatesSame(date1, date2) {
        const d1 = new Date(date1);
        const d2 = new Date(date2);

        return (
            d1.getFullYear() === d2.getFullYear() &&
            d1.getMonth() === d2.getMonth() &&
            d1.getDate() === d2.getDate()
        );
    }

    useEffect(() => {
        if (watchFrom && !areDatesSame(watchFrom, thirtyDaysAgo)) {
            setFromChanged(true);
        } else {
            setFromChanged(false);
        }
    }, [watchFrom]);

    useEffect(() => {
        if (watchTo && !areDatesSame(watchTo, today)) {
            setToChanged(true);
        } else {
            setToChanged(false);
        }
    }, [watchTo]);

    const isFilterButtonDisabled = loading || (!watchFields.some(field => field) && !fromChanged && !toChanged);

    //validate forms
    const validateFromField = (from, allValues) => {
        if (!from && allValues.to) {
            return "From date is required";
        }
        return true;
    };

    const validateToField = (to, allValues) => {
        if (!to && allValues.from) {
            return "To date is required";
        }
        return true;
    };

    // Function to format date
    const formatDateTime = (dateTimeString) => {
        const date = new Date(dateTimeString);
        const dateOptions = { year: 'numeric', month: 'long', day: 'numeric' };
        const timeOptions = { hour: '2-digit', minute: '2-digit', hour12: true };
        const formattedDate = date.toLocaleDateString('en-US', dateOptions);
        const formattedTime = date.toLocaleTimeString('en-US', timeOptions);
        return `${formattedDate}, ${formattedTime}`;
    };

    //set table columns
    const columns = [
        {
            name: "ID",
            selector: (row) => row.id,
            width: "75px",
        },
        {
            name: "Project",
            selector: (row) => (
                <div className="d-flex flex-wrap">
                    <OverlayTrigger
                        placement="top"
                        overlay={<Tooltip>{row.project_title ? row.project_title : "N/A"}</Tooltip>}
                    >
                        <div style={{ display: "inline-block" }}>
                            {row.project_title ? row.project_title : "N/A"}
                        </div>
                    </OverlayTrigger>
                </div>
            ),
        },
        {
            name: "Value",
            selector: (row) => (
                <div className="d-flex flex-wrap">
                    <OverlayTrigger
                        placement="top"
                        overlay={<Tooltip>{row.project_value ? row.project_value : "N/A"}</Tooltip>}
                    >
                        <div style={{ display: "inline-block" }}>
                            {row.project_value ? row.project_value : "N/A"}
                        </div>
                    </OverlayTrigger>
                </div>
            ),
        },
        {
            name: "Category",
            selector: (row) => (
                <div className="d-flex flex-wrap">
                    <OverlayTrigger
                        placement="top"
                        overlay={<Tooltip>{row.category?.name ? row.category?.name : "N/A"}</Tooltip>}
                    >
                        <div style={{ display: "inline-block" }}>
                            {row.category?.name ? row.category?.name : "N/A"}
                        </div>
                    </OverlayTrigger>
                </div>
            ),
        },
        {
            name: "Description",
            selector: (row) => (
                <div className="d-flex flex-wrap">
                    <OverlayTrigger
                        placement="top"
                        overlay={<Tooltip>{row.project_description ? row.project_description : "N/A"}</Tooltip>}
                    >
                        <div style={{ display: "inline-block" }}>
                            {row.project_description ? row.project_description : "N/A"}
                        </div>
                    </OverlayTrigger>
                </div>
            ),
        },
        {
            name: "Deadline",
            selector: (row) => (
                <div className="d-flex flex-wrap">
                    <OverlayTrigger
                        placement="top"
                        overlay={<Tooltip>{row.project_deadline ? formatDateTime(row.project_deadline) : "N/A"}</Tooltip>}
                    >
                        <div style={{ display: "inline-block" }}>
                            {row.project_deadline ? formatDateTime(row.project_deadline) : "N/A"}
                        </div>
                    </OverlayTrigger>
                </div>
            ),
        },
        {
            name: "Complete Status",
            selector: (row) => {
                return (
                    <div className='d-flex justify-content-center'>
                        {row?.is_completed === 1 ?
                            <Badge bg="success">Completed</Badge>
                            :
                            <Badge bg="danger">Incomplete</Badge>
                        }
                    </div>
                )
            },
            width: "100px",
        },
        {
            name: "Created At",
            selector: (row) => (
                <div className="d-flex flex-wrap">
                    <OverlayTrigger
                        placement="top"
                        overlay={<Tooltip>{formatDateTime(row.created_at)}</Tooltip>}
                    >
                        <div style={{ display: "inline-block" }}>
                            {formatDateTime(row.created_at)}
                        </div>
                    </OverlayTrigger>
                </div>
            ),
        },
    ];

    //Set table rows data
    const rows = Array.isArray(projectsData)
        ? projectsData?.map(
            ({
                id,
                project_title,
                project_value,
                category,
                project_description,
                project_deadline,
                is_completed,
                created_at,
            }) => ({
                id,
                project_title,
                project_value,
                category,
                project_description,
                project_deadline,
                is_completed,
                created_at,
            })
        )
        : [];


    //handle searchable select project
    const handleProjectChange = (selectedOption) => {
        setValue('project_id', selectedOption ? selectedOption.value : '');
    };

    const selectedProject = watch('project_id');

    const projectsOptions = companyProjectData.map(list => ({
        value: list.id,
        label: list.project_title,
    }));

    //get status batch
    const getStatusBatch = (status) => {
        if (status === 1) {
            return <Badge bg="success">Active</Badge>;
        } else {
            return <Badge bg="danger">Inactive</Badge>
        }
    }

    return (
        <div>
            <Form onSubmit={handleSubmit(onSubmit)}>
                <Row>
                    <div className='col-md-4 mb-3'>
                        <Form.Group>
                            <Form.Label>Select Project</Form.Label>
                            <CustomSearchSelect
                                options={projectsOptions}
                                selectedOption={selectedProject ? projectsOptions.find(option => option.value === selectedProject) : null}
                                handleChange={handleProjectChange}
                                disabled={loading || projectsData.length <= 0 || submitLoading}
                            />
                        </Form.Group>
                    </div>
                    <div className="col-md-4 mb-3">
                        <Form.Label>From</Form.Label>
                        <Controller
                            control={control}
                            name="from"
                            rules={{ validate: (value) => validateFromField(value, watch()) }}
                            render={({ field }) => (
                                <ReactDatePicker
                                    disabled={loading || submitLoading}
                                    className="form-control"
                                    dateFormat="yyyy-MM-dd"
                                    dropdownMode="select"
                                    selected={field.value}
                                    onChange={(date) => {
                                        field.onChange(date);
                                        setChangedFrom(true);
                                        const thirtyDaysAfter = moment(date).add(30, 'days').toDate();
                                        setMinDateTo(date);
                                        if (thirtyDaysAfter > today) {
                                            setMaxDateTo(today);
                                        } else {
                                            setMaxDateTo(thirtyDaysAfter)
                                        }
                                    }}
                                    customInput={<CustomInput />}
                                    popperClassName="custom-datepicker-popper"
                                    maxDate={maxDateFrom}
                                    minDate={minDateFrom}
                                />
                            )}
                        />

                        {errors.from && (
                            <small className="text-danger">{errors.from.message}</small>
                        )}
                    </div>
                    <div className="col-md-4 mb-3">
                        <Form.Label>To</Form.Label>
                        <div>
                            <Controller
                                control={control}
                                name="to"
                                rules={{ validate: (value) => validateToField(value, watch()) }}
                                render={({ field }) => (
                                    <ReactDatePicker
                                        disabled={loading || submitLoading}
                                        className="form-control"
                                        dateFormat="yyyy-MM-dd"
                                        dropdownMode="select"
                                        selected={field.value}
                                        onChange={(date) => {
                                            field.onChange(date);
                                            const thirtyDaysAgo = moment(date).subtract(30, 'days').toDate();
                                            setMaxDateFrom(date);
                                            setMinDateFrom(thirtyDaysAgo);
                                            setChangedTo(true);
                                        }}
                                        customInput={<CustomInput />}
                                        popperClassName="custom-datepicker-popper"
                                        maxDate={maxDateTo}
                                        minDate={minDateTo}
                                    />
                                )}
                            />

                            {errors.to && (
                                <small className="text-danger">{errors.to.message}</small>
                            )}
                        </div>
                    </div>
                </Row>
                <Row>
                    <div className='col-md-4 mb-3'>
                        <Form.Group>
                            <Form.Label>Select Complete Status </Form.Label>
                            <Form.Control
                                className="form-select"
                                as="select"
                                name="is_completed"
                                {...register("is_completed")}
                                disabled={loading || projectsData.length <= 0 || submitLoading}
                            >
                                <option value="">Select Complete Status </option>
                                {completeStatusData &&
                                    completeStatusData.map((list) => (
                                        <option key={list.id} value={list.id}>
                                            {list.name}
                                        </option>
                                    ))
                                }
                            </Form.Control>
                        </Form.Group>
                    </div>
                    <div className='col-md-4 mb-3'>
                        <Form.Group>
                            <Form.Label>Select Category </Form.Label>
                            <Form.Control
                                className="form-select"
                                as="select"
                                name="category_id"
                                {...register("category_id")}
                                disabled={loading || projectsData.length <= 0 || submitLoading}
                            >
                                <option value="">Select category </option>
                                {categoryData &&
                                    categoryData.map((list) => (
                                        <option key={list.id} value={list.id}>
                                            {list.name}
                                        </option>
                                    ))
                                }
                            </Form.Control>
                        </Form.Group>
                    </div>
                </Row>
                <div className='d-flex justify-content-end gap-3 mb-3'>
                    <div className="">
                        <Button type='submit' className='d-flex align-items-center gap-1' disabled={isFilterButtonDisabled}>
                            {submitLoading ? <Spinner animation="border" size="sm" /> : <i className="ri-filter-line"></i>}
                            Filter
                        </Button>
                    </div>
                    <div className="">
                        <Button onClick={onReset} className='d-flex align-items-center gap-1' disabled={isFilterButtonDisabled}>
                            <i className="ri-restart-line"></i>
                            Reset
                        </Button>
                    </div>
                    <div className="">
                        <Dropdown onSelect={handleSelect}>
                            <Dropdown.Toggle
                                as={Button}
                                disabled={loading || projectsData.length <= 0 || submitLoading}
                                className="d-flex align-items-center gap-1"
                            >
                                {generateButtonLoading ? (
                                    <Spinner animation="border" size="sm" />
                                ) : (
                                    null
                                )}
                                {selectedReport}
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                                <Dropdown.Item eventKey="Generate PDF" className='d-flex align-items-center gap-1'>
                                    <i className="ri-file-pdf-line"></i>
                                    Generate PDF
                                </Dropdown.Item>
                                <Dropdown.Item eventKey="Generate Excel" className='d-flex align-items-center gap-1'>
                                    <i className="ri-file-excel-2-line"></i>
                                    Generate Excel
                                </Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
                </div>
            </Form>
            <PaginationWithDataTable
                dataColumns={columns}
                entities={rows}
                loading={loading}
                rowCount={4}
                columnCount={8}
            />
            {viewMoreModal.open &&
                <FormModal
                    moduleName={"User Details"}
                    modalState={viewMoreModal.open}
                    toggleFormModal={toggleFormModal}
                    width="600px"
                >
                    <div className="feedback-content">
                        <Card.Body className='p-3'>
                            <Card.Title className='fs-3' >{viewMoreModal?.data?.first_name ? viewMoreModal?.data?.first_name : null} {viewMoreModal?.data?.last_name ? viewMoreModal?.data?.last_name : null} {(!viewMoreModal?.data?.first_name && !viewMoreModal?.data?.last_name) && "N/A"}</Card.Title>
                            <Card.Subtitle className="mb-2 text-primary">{viewMoreModal?.data?.company?.com_name ? viewMoreModal?.data?.company?.com_name : null}</Card.Subtitle>
                            <Card.Text>{viewMoreModal?.data?.user_description ? viewMoreModal?.data?.user_description : "N/A"}</Card.Text>
                        </Card.Body>
                        <ListGroup variant="flush">
                            <ListGroup.Item><strong>Email : </strong> {viewMoreModal?.data?.email ? viewMoreModal?.data?.email : "N/A"}</ListGroup.Item>
                            <ListGroup.Item><strong>Contact : </strong> {viewMoreModal?.data?.contact ? viewMoreModal?.data?.contact : "N/A"}</ListGroup.Item>
                            <ListGroup.Item><strong>Address : </strong>
                                {viewMoreModal?.data?.address1 ? viewMoreModal?.data?.address1 + "," : null}{" "}
                                {viewMoreModal?.data?.address2 ? viewMoreModal?.data?.address2 + "," : null}{" "}
                                {viewMoreModal?.data?.address3 ? viewMoreModal?.data?.address3 + "," : null}{" "}
                                {viewMoreModal?.data?.region ? viewMoreModal?.data?.region + "," : null}{" "}
                                {viewMoreModal?.data?.country ? viewMoreModal?.data?.country : null}{" "}
                                {(!viewMoreModal?.data?.address1 && !viewMoreModal?.data?.address2 && !viewMoreModal?.data?.address3 && !viewMoreModal?.data?.region && !viewMoreModal?.data?.country) && "N/A"}
                            </ListGroup.Item>
                            <ListGroup.Item><strong>Role : </strong>
                                {
                                    viewMoreModal?.data?.user_role_id === "2" ? "Customer" :
                                        viewMoreModal?.data?.user_role_id === "3" ? "Company" :
                                            viewMoreModal?.data?.user_role_id === "4" ? "Delivery Agent" :
                                                viewMoreModal?.data?.user_role_id === "5" ? "Field Agent" :
                                                    "N/A"
                                }
                            </ListGroup.Item>
                            <ListGroup.Item><strong>Package : </strong>
                                {
                                    viewMoreModal?.data?.packages?.id === 1 ? "Free Trial" :
                                        viewMoreModal?.data?.packages?.id === 2 ? "Starter" :
                                            viewMoreModal?.data?.packages?.id === 3 ? "Business" :
                                                viewMoreModal?.data?.packages?.id === 4 ? "Enterprise" :
                                                    "N/A"
                                }
                            </ListGroup.Item>
                            <ListGroup.Item><strong>Status : </strong>
                                {
                                    getStatusBatch(viewMoreModal?.data?.status)
                                }
                            </ListGroup.Item>
                            <ListGroup.Item><strong>Package End Date : </strong> {viewMoreModal?.data?.package_end_date ? formatDateTime(viewMoreModal?.data?.package_end_date) : "N/A"}</ListGroup.Item>
                            <ListGroup.Item><strong>Onboarded Date : </strong> {viewMoreModal?.data?.created_at ? formatDateTime(viewMoreModal?.data?.created_at) : "N/A"}</ListGroup.Item>
                        </ListGroup>
                    </div>
                </FormModal>
            }
        </div>
    )
}

export default CompanyProjectReport