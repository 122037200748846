import React from "react";
import { Link } from "react-router-dom";
import HeaderSection4 from "../../assets/img/HeaderSection4.png";
import "../../assets/styles/components/home/ChooseWorkPreference.scss";

export default function TeamAcceleration() {
  return (
    <div className="work-preference pd-top-60 pd-bottom-60">
      <div className="container">
        <div className="team-details-page">
          <div className="row">
            <div className="col-lg-7 order-xs-2 align-self-center ps-xl-5">
              <div className="content-section mb-0 ">
                <h2 className="title mb-2">Move faster with our team</h2>

                <p className="content">
                  With your other work connected to our team, you can work
                  faster by switching tabs less. And with powerful tools like
                  workflow builder, you can automate away routine tasks.
                </p>
                <Link className="learn-more-link text-decoration-none" to="/#">
                  Learn more <i className="ri-arrow-right-line"></i>
                </Link>
              </div>
            </div>
            <div className="col-lg-5 order-xs-1 text-center">
              <div className="thumb mb-4 mb-lg-0">
                <img className="img-fluid" src={ HeaderSection4 } alt="img" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
