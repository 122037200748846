import React, { useState, useRef } from 'react';
import { Form, Alert } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import GeneralFeedbackReport from '../../components/report/GeneralFeedbackReport';
import CustomerFeedbackReport from '../../components/report/CustomerFeedbackReport';
import FeedbackAnalysisReport from '../../components/report/FeedbackAnalysisReport';
import { reportTypes, reportTypes2 } from '../../utils/types';

function FeedbackReports() {
  const moduleName = "Feedback Reports";
  const UserRole = useSelector((state) => state.auth.userInfo.user_role);
  const reportSelectRef = useRef(null);
  const [selectedReport, setSelectedReport] = useState("");
  const [selectOptions, setSelectOption] = useState(UserRole === "1" ? reportTypes : reportTypes2)
  const isReportIdNullOrZero = selectedReport === "" || selectedReport === "0";

  const handleSelectChange = (reportType) => {
    setSelectedReport(reportType);
  };

  return (
    <>
      <div className="main main-app p-3 p-lg-4">
        <div className="d-flex align-items-center justify-content-between mb-4">
          <div>
            <ol className="breadcrumb fs-sm mb-1">
              <li className="breadcrumb-item" aria-current="page">
                Dashboard
              </li>
              <li className="breadcrumb-item active">{moduleName}</li>
            </ol>
            <h4 className="main-title mb-0">{moduleName}</h4>
          </div>
        </div>
        <div className="d-flex flex-row mb-4 col-md-3">
          <Form.Select
            ref={reportSelectRef}
            onChange={(e) => handleSelectChange(e.target.value)}
          >
            <option value="0">Select Report Type</option>
            {selectOptions?.map((report) => (
              <option key={report.id} value={report.id}>
                {report.name}
              </option>
            ))}
          </Form.Select>
        </div>
        {isReportIdNullOrZero ? (
          <Alert variant="primary" className="d-flex align-items-center mb-2">
            <i className="ri-information-line"></i> Report Type not selected.
            Please select a report type.
          </Alert>
        ) : (
          <div>
            {selectedReport === "1" ?
              <GeneralFeedbackReport /> :
              selectedReport === "2" ?
                <FeedbackAnalysisReport /> :
                selectedReport === "3" ?
                  <CustomerFeedbackReport /> :
                  null
            }
          </div>
        )
        }
      </div>
    </>
  )
}

export default FeedbackReports