import React, { useState, useEffect } from 'react'
import { Button, Form, Row, Tooltip, OverlayTrigger, Spinner, Badge, Card, ListGroup, Dropdown } from "react-bootstrap";
import { Controller, useForm, useWatch } from 'react-hook-form';
import ReactDatePicker from 'react-datepicker';
import { CustomInput } from '../../utils/utils';
import PaginationWithDataTable from '../common/PagintionWithDataTable';
import FormModal from '../common/FormModal';
import Alert from '../common/Alert';
import "../../assets/styles/components/common/GeneralFeedbackReports.scss";
import NFSLogoDark from "../../assets/img/icon.png";
import HeaderImg from "../../assets/img/default-banner-img.jpg";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import * as XLSX from 'xlsx';
import CustomSearchSelect from '../common/CustomSearchSelect';
import { completeStatusTypes } from '../../utils/types';
import { useDispatch } from 'react-redux';
import { fetchInitialCustomerProjectData } from '../../store/actions/reportActions';
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

function CustomerSingleProjectReport({ id }) {
    const moment = require("moment");
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [submitLoading, setSubmitLoading] = useState(false);
    const [projectsData, setProjectData] = useState([]);
    const [taskData, setTaskData] = useState([]);
    const [companyProjectData, setCompanyProjectData] = useState([]);
    const [generateButtonLoading, setGenerateButtonLoading] = useState(false);

    const [selectedReport, setSelectedReport] = useState('Generate Report');

    const handleSelect = (eventKey) => {
        setSelectedReport(eventKey);
        if (eventKey === 'Generate PDF') {
            handleGenerateReport(projectsData);
        } else if (eventKey === 'Generate Excel') {
            generateExcel(projectsData);
        }
    };

    useEffect(() => {
        initialCustomerProjectsList();
    }, [id])

    const initialCustomerProjectsList = async () => {
        setLoading(true);
        setTaskData([]);
        setProjectData([]);
        const data = {
            project_id: id,
        };
        const projects = await dispatch(fetchInitialCustomerProjectData(data));
        if (projects.payload) {
            const project = projects.payload
            setProjectData(project[0]);
            setTaskData(project[0].tasks);
            setLoading(false);
        } else {
            setLoading(false);
        }
    };

    const handleGenerateReport = (projectsData) => {
        setGenerateButtonLoading(true);
        generateReportPDF(projectsData).then(() => {
            setGenerateButtonLoading(false);
        }).catch((error) => {
            Alert.error("Error generating PDF:", error)
            console.error("Error generating PDF:", error);
            setGenerateButtonLoading(false);
        });
    };

    const generateReportPDF = (data) => {
        const formatDateTime = (dateTimeString) => {
            const date = new Date(dateTimeString);
            const dateOptions = { year: 'numeric', month: 'long', day: 'numeric' };
            const timeOptions = { hour: '2-digit', minute: '2-digit', hour12: true };
            const formattedDate = date.toLocaleDateString('en-US', dateOptions);
            const formattedTime = date.toLocaleTimeString('en-US', timeOptions);
            return `${formattedDate}, ${formattedTime}`;
        };

        return new Promise((resolve, reject) => {
            try {
                const doc = new jsPDF("p", "mm", "a4");
                const imgWidth = doc.internal.pageSize.getWidth();
                const imgHeight = 40;

                // Add letterhead
                const imgData = HeaderImg;
                doc.addImage(imgData, "JPEG", 0, 0, imgWidth, imgHeight);

                doc.setFontSize(16);
                doc.setTextColor(0, 0, 0);
                doc.text("Customer Single Project Report", doc.internal.pageSize.getWidth() / 2, 50, {
                    align: "center",
                });

                const cardX = 10;
                const cardY = 55;
                const cardWidth = imgWidth - 20;
                const cardHeight = 45;

                doc.setDrawColor(35, 35, 64);
                doc.setLineWidth(0.5);
                doc.roundedRect(cardX, cardY, cardWidth, cardHeight, 3, 3);

                doc.setFontSize(12);
                doc.text(`Project Name: ${data.project_title ? data.project_title : "N/A"}`, cardX + 5, cardY + 10);
                doc.text(`Category: ${data.category?.name ? data.category?.name : "N/A"}`, cardX + 5, cardY + 16);
                doc.text(`Value: $ ${data.project_value ? data.project_value : "N/A"}`, cardX + 5, cardY + 22);
                doc.text(`Description: ${data.project_description ? data.project_description : "N/A"}`, cardX + 5, cardY + 28);
                doc.text(`Start Date: ${data.created_at ? formatDateTime(data.created_at) : "N/A"}`, cardX + 5, cardY + 34);
                doc.text(`End Date: ${data.project_deadline ? formatDateTime(data.project_deadline) : "N/A"}`, cardX + 5, cardY + 40);

                const badgeText = data.is_completed === 1 ? "Completed" : "Incomplete";
                const badgeWidth = doc.getTextWidth(badgeText) + 10;
                const badgeX = cardX + cardWidth - badgeWidth - 5;
                const badgeY = cardY + 6;

                doc.setFillColor(35, 35, 64);
                doc.roundedRect(badgeX, badgeY, badgeWidth, 10, 3, 3, 'F');
                doc.setTextColor(255, 255, 255);
                doc.text(badgeText, badgeX + 5, badgeY + 6);

                const tableColumn = [
                    "ID",
                    "Task",
                    "Description",
                    "Status",
                    "Closing Date",
                    "Created At",
                ];

                const tasks = data.tasks;

                const tableRows =
                    tasks &&
                    tasks.length > 0 &&
                    tasks.map((row) => [
                        row.id,
                        row.task_title,
                        row.task_description,
                        row.task_status.type_name ? row.task_status.type_name : "N/A",
                        formatDateTime(row.task_closing_date),
                        formatDateTime(row.created_at),
                    ]);

                doc.autoTable({
                    head: [tableColumn],
                    body: tableRows,
                    startY: 104,
                    theme: "grid",
                    headStyles: { fillColor: [35, 35, 64] },
                    styles: { cellPadding: 3, fontSize: 9, halign: 'center' },
                    margin: { left: 5, right: 5 },
                });

                // Add footer
                const pageCount = doc.internal.getNumberOfPages();
                for (let i = 1; i <= pageCount; i++) {
                    doc.setPage(i);
                    const footerHeight = 12;
                    const footerY = doc.internal.pageSize.getHeight() - footerHeight;

                    doc.setFillColor(35, 35, 64);
                    doc.rect(0, footerY, imgWidth, footerHeight, "F");

                    const logoData = NFSLogoDark;
                    const logoWidth = 16;
                    const logoHeight = 8;
                    const logoX = imgWidth - logoWidth - 5;
                    doc.addImage(logoData, "JPEG", logoX, footerY + (footerHeight - logoHeight) / 2, logoWidth, logoHeight);

                    doc.setTextColor(255, 255, 255);
                    doc.setFontSize(10);
                    doc.text(`Page ${i} of ${pageCount}`, 10, footerY + 7);
                }

                window.open(doc.output("bloburl"), "_blank");
                resolve();
            } catch (error) {
                reject(error);
            }
        });
    };

    const generateExcel = (data) => {
        const formatDateTime = (dateTimeString) => {
            const date = new Date(dateTimeString);
            const dateOptions = { year: 'numeric', month: 'long', day: 'numeric' };
            const timeOptions = { hour: '2-digit', minute: '2-digit', hour12: true };
            const formattedDate = date.toLocaleDateString('en-US', dateOptions);
            const formattedTime = date.toLocaleTimeString('en-US', timeOptions);
            return `${formattedDate}, ${formattedTime}`;
        };

        const tasks = data.tasks;

        const formattedData = tasks.map(item => ({
            'ID': item?.id,
            'Task': `${item?.task_title ? item.task_title : "N/A"}`,
            'Description': item?.task_description ? item?.task_description : "N/A",
            'Status': `${item?.task_status.type_name ? item.task_status.type_name : "N/A"}`,
            'Closing Date': item?.task_closing_date ? formatDateTime(item.task_closing_date) : "N/A",
            'Created At': item?.created_at ? formatDateTime(item.created_at) : "N/A",
        }));

        const worksheet = XLSX.utils.json_to_sheet([]);

        XLSX.utils.sheet_add_aoa(worksheet, [
            [`Customer Single Project Report`],
            [`Project Name: ${data.project_title ? data.project_title : "N/A"}`],
            [`Project Category: ${data.category?.name ? data.category?.name : "N/A"}`],
            [`Project Value: $ ${data.project_value ? data.project_value : "N/A"}`],
            [`Description: ${data.project_description ? data.project_description : "N/A"}`],
            [`Start Date: ${data.created_at ? formatDateTime(data.created_at) : "N/A"}`],
            [`End Date: ${data.project_deadline ? formatDateTime(data.project_deadline) : "N/A"}`],
            [`Status: ${data.is_completed === 1 ? "Completed" : "Incomplete"}`],
            [],
        ]);

        const startingRowForData = 10;

        XLSX.utils.sheet_add_json(worksheet, formattedData, {
            origin: `A${startingRowForData}`,
            skipHeader: false,
            header: [
                'ID',
                'Task',
                'Description',
                'Status',
                'Closing Date',
                'Created At',
            ],
        });

        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'ProjectDetails');

        XLSX.writeFile(workbook, 'CustomerProjectReport.xlsx');
    }

    // Function to format date
    const formatDateTime = (dateTimeString) => {
        const date = new Date(dateTimeString);
        const dateOptions = { year: 'numeric', month: 'long', day: 'numeric' };
        const timeOptions = { hour: '2-digit', minute: '2-digit', hour12: true };
        const formattedDate = date.toLocaleDateString('en-US', dateOptions);
        const formattedTime = date.toLocaleTimeString('en-US', timeOptions);
        return `${formattedDate}, ${formattedTime}`;
    };;

    //set table columns
    const columns = [
        {
            name: "ID",
            selector: (row) => row.id,
            width: "75px",
        },
        {
            name: "Task",
            selector: (row) => (
                <div className="d-flex flex-wrap">
                    <OverlayTrigger
                        placement="top"
                        overlay={<Tooltip>{row.task_title ? row.task_title : "N/A"}</Tooltip>}
                    >
                        <div style={{ display: "inline-block" }}>
                            {row.task_title ? row.task_title : "N/A"}
                        </div>
                    </OverlayTrigger>
                </div>
            ),
        },
        {
            name: "Description",
            selector: (row) => (
                <div className="d-flex flex-wrap">
                    <OverlayTrigger
                        placement="top"
                        overlay={<Tooltip>{row.task_description ? row.task_description : "N/A"}</Tooltip>}
                    >
                        <div style={{ display: "inline-block" }}>
                            {row.task_description ? row.task_description : "N/A"}
                        </div>
                    </OverlayTrigger>
                </div>
            ),
        },
        {
            name: "Status",
            selector: (row) => (
                <div className="d-flex flex-wrap">
                    <OverlayTrigger
                        placement="top"
                        overlay={<Tooltip>{row?.task_status?.type_name ? row.task_status.type_name : "N/A"}</Tooltip>}
                    >
                        <div style={{ display: "inline-block" }}>
                            {row?.task_status?.type_name ? row.task_status.type_name : "N/A"}
                        </div>
                    </OverlayTrigger>
                </div>
            ),
        },
        {
            name: "Closing Date",
            selector: (row) => (
                <div className="d-flex flex-wrap">
                    <OverlayTrigger
                        placement="top"
                        overlay={<Tooltip>{row.task_closing_date ? formatDateTime(row.task_closing_date) : "N/A"}</Tooltip>}
                    >
                        <div style={{ display: "inline-block" }}>
                            {row.task_closing_date ? formatDateTime(row.task_closing_date) : "N/A"}
                        </div>
                    </OverlayTrigger>
                </div>
            ),
        },
        {
            name: "Created At",
            selector: (row) => (
                <div className="d-flex flex-wrap">
                    <OverlayTrigger
                        placement="top"
                        overlay={<Tooltip>{formatDateTime(row.created_at)}</Tooltip>}
                    >
                        <div style={{ display: "inline-block" }}>
                            {formatDateTime(row.created_at)}
                        </div>
                    </OverlayTrigger>
                </div>
            ),
        },
    ];

    //Set table rows data
    const rows = Array.isArray(taskData)
        ? taskData?.map(
            ({
                id,
                task_title,
                task_description,
                task_status,
                task_closing_date,
                created_at,
            }) => ({
                id,
                task_title,
                task_description,
                task_status,
                task_closing_date,
                created_at,
            })
        )
        : [];

    return (
        <div>
            <Form>
                <div className='d-flex justify-content-end gap-3 mb-3'>
                    <div className="">
                        <Dropdown onSelect={handleSelect}>
                            <Dropdown.Toggle
                                as={Button}
                                disabled={loading || taskData?.length <= 0 || submitLoading}
                                className="d-flex align-items-center gap-1"
                            >
                                {generateButtonLoading ? (
                                    <Spinner animation="border" size="sm" />
                                ) : (
                                    null
                                )}
                                {selectedReport}
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                                <Dropdown.Item eventKey="Generate PDF" className='d-flex align-items-center gap-1'>
                                    <i className="ri-file-pdf-line"></i>
                                    Generate PDF
                                </Dropdown.Item>
                                <Dropdown.Item eventKey="Generate Excel" className='d-flex align-items-center gap-1'>
                                    <i className="ri-file-excel-2-line"></i>
                                    Generate Excel
                                </Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
                </div>
            </Form>
            <div>
                {loading ?
                    <SkeletonTheme color="#e0e0e0" highlightColor="#f5f5f5">
                        <Card className="mb-3 position-relative">
                            <Card.Body>
                                <Card.Title className="d-flex justify-content-between align-items-start">
                                    <Skeleton width={200} height={18} />
                                    <Skeleton width={60} height={23} className="position-absolute top-0 end-0 m-3" />
                                </Card.Title>
                                <Card.Subtitle className="mb-2 text-muted">
                                    <Skeleton width={150} height={13} />
                                </Card.Subtitle>
                                <Card.Text>
                                    <Skeleton count={3} />
                                </Card.Text>
                                <div className='d-flex justify-content-between'>
                                    <Skeleton width={100} height={13} />
                                </div>
                                <div className='d-flex justify-content-between'>
                                    <Skeleton width={100} height={13} />
                                </div>
                            </Card.Body>
                        </Card>
                    </SkeletonTheme>
                    :
                    <Card className="mb-3 position-relative">
                        <Card.Body>
                            <Card.Title className="d-flex justify-content-between align-items-start">
                                {projectsData?.project_title ? projectsData?.project_title : "N/A"}
                                <Badge
                                    bg={projectsData?.is_completed === 1 ? 'success' : 'warning'}
                                    className="position-absolute top-0 end-0 m-3"
                                >
                                    {projectsData?.is_completed === 1 ? "Completed" : "Incomplete"}
                                </Badge>

                            </Card.Title>
                            <Card.Subtitle className="mb-2 text-info">{projectsData?.category?.name ? projectsData?.category?.name : "N/A"}</Card.Subtitle>
                            <Card.Subtitle className="mb-2 text-muted">$ {projectsData?.project_value ? projectsData?.project_value : "N/A"}</Card.Subtitle>
                            <Card.Text>
                                {projectsData?.project_description ? projectsData?.project_description : "N/A"}
                            </Card.Text>
                            <div className='d-flex justify-content-between'>
                                <div><strong>Start Date : </strong> {projectsData?.created_at ? formatDateTime(projectsData?.created_at) : "N/A"}</div>
                            </div>
                            <div className='d-flex justify-content-between'>
                                <div><strong>End Date : </strong> {projectsData?.project_deadline ? formatDateTime(projectsData?.project_deadline) : "N/A"}</div>
                            </div>
                        </Card.Body>
                    </Card>
                }
            </div>
            <PaginationWithDataTable
                dataColumns={columns}
                entities={rows}
                loading={loading}
                rowCount={4}
                columnCount={6}
            />
        </div>
    )
}

export default CustomerSingleProjectReport