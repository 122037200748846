import React, { useState, useRef, useEffect } from "react";
import { Button, Form, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import FormModal from "../../common/FormModal";

const AddReviewForTask = ({
  showValue,
  toggleFormModalReview,
  status,
  onSubmit,
  isLoading,
}) => {
  const [rating, setRating] = useState(0);
  const [hover, setHover] = useState(0);
  const formRef = useRef(null);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    setRating(0);
    setHover(0);
    reset();
  }, [status]);

  const handleFormSubmit = (data) => {
    onSubmit({ ...data, task_end_feedback_rate: rating });
    formRef.current.reset(); // Reset the form
  };

  const toggleFormModal = () => {
    setRating(0); // Reset rating state
    reset(); // Reset react-hook-form
    toggleFormModalReview(); // Close the modal
  };

  return (
    <FormModal
      moduleName={ "Add Review" }
      modalState={ showValue }
      toggleFormModal={ toggleFormModal }
      width="600px"
    >
      <Form ref={ formRef } onSubmit={ handleSubmit(handleFormSubmit) }>
        { status === 5 && (
          <Row>
            <div className="col-md-12 mb-3">
              <div className="form-group">
                <Form.Label><span><i style={ { color: "#ff3300", fontSize: "10px" } } className="ri-asterisk"></i></span> Add Comment</Form.Label>
                <Form.Control
                  as="textarea"
                  placeholder="Add Review"
                  className="textarea"
                  name="assignee_comment"
                  disabled={ isLoading }
                  { ...register("assignee_comment", {
                    required: "Review is required",
                  }) }
                ></Form.Control>
                { errors.assignee_comment && (
                  <small className="text-danger">
                    { errors.assignee_comment.message }
                  </small>
                ) }
              </div>
            </div>
          </Row>
        ) }

        { status === 2 && (
          <>
            { [1, 2, 3, 4, 5].map((star) => (
              <span
                key={ star }
                onMouseEnter={ () => setHover(star) }
                onMouseLeave={ () => setHover(rating) }
                onClick={ () => setRating(star) }
                style={ { cursor: "pointer" } }
                disabled={ isLoading }
              >
                { hover >= star ? (
                  <i
                    className="ri-star-fill"
                    style={ { color: "gold", fontSize: "2rem" } }
                  ></i>
                ) : (
                  <i
                    className="ri-star-line"
                    style={ { color: "gold", fontSize: "2rem" } }
                  ></i>
                ) }
              </span>
            )) }
          </>
        ) }

        <div className="d-flex flex-wrap gap-2 mt-3 justify-content-end">
          <Button
            type="button"
            variant="outline-primary"
            className="btn-sign"
            onClick={ toggleFormModal }
            disabled={ isLoading }
          >
            Cancel
          </Button>

          <Button
            type="submit"
            variant="primary"
            className="btn-sign"
            disabled={ isLoading }
          >
            { isLoading ? (
              <>
                <span
                  className="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                ></span>{ " " }
                Submit Review
              </>
            ) : (
              "Submit Review"
            ) }
          </Button>
        </div>
      </Form>
    </FormModal>
  );
};

export default AddReviewForTask;
