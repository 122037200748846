import React, { useState, useEffect } from 'react'
import Footer from '../../components/dashboard/Footer';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import styles from "../../assets/styles/components/common/PricingPackages.module.scss"
import { Placeholder } from 'react-bootstrap';
import ConfirmModal from '../../components/common/ConfirmModal';
import { useDispatch } from 'react-redux';
import { userUpgradePackage } from '../../store/actions/authActions';
import { setUserPackageNotExpired } from '../../store/reducers/authSlice';
import { get } from '../../servces/Api';
import Alert from '../../components/common/Alert';
import TableLoader from '../../components/common/TableLoader';

function PricingPackages() {
    const dispatch = useDispatch();
    const UserData = useSelector((state) => state.auth.userInfo);
    const UserExpired = useSelector((state) => state.auth.userPackageExpired);
    const [timePeriod, setTimePeriod] = useState("month");
    const [showModal, setShowModal] = useState(false);
    const [selectedPackage, setSelectedPackage] = useState();
    const [selectedPricingPackges, setSelectedPricingPackges] = useState([]);
    const [loading, setLoading] = useState(false);
    const [packageLimitloading, setPackageLimitloading] = useState(false);
    const [features, setFeatures] = useState([]);
    const [packageList, setPackageList] = useState([]);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        getPackages();
        getPackageLimitations();
    }, []);

    const getPackageLimitations = async () => {
        setPackageLimitloading(true);
        try {
            const response = await get("/api/load_packages");
            if (response.status === 200) {
                const packages = response.data.packages;
                const filteredPackages = packages.filter(pkg => [1, 2, 3].includes(pkg.id));
                setPackageList(filteredPackages)
                if (filteredPackages.length > 0) {
                    const features = Object.keys(filteredPackages[0]).filter(
                        (key) => key !== "id" && key !== "name"
                    );
                    setFeatures(features);
                    setPackageLimitloading(false);
                }
            } else {
                Alert.error(response.error);
                setPackageLimitloading(false);
            }
        } catch (error) {
            Alert.error("Unable to get packges limitations!");
            setPackageLimitloading(false);
        }
    };

    const getPackages = async () => {
        setLoading(true);
        try {
            const response = await get("/api/auth_packages");
            if (!response.error) {
                const packages = response.data.packages;
                const filteredPackages = packages.filter(pkg => [2, 3, 4].includes(pkg.id));
                setSelectedPricingPackges(filteredPackages);
                setLoading(false);
            } else {
                Alert.error(response.error);
                setLoading(false);
            }
        } catch (error) {
            Alert.error("Unable to get packges list!");
            setLoading(false);
        }
    };

    const pricingCardCLick = (id) => {
        setSelectedPackage(id);
        setShowModal(true);
    }

    const handleConfirmUpgrade = () => {
        setLoading(true);
        dispatch(userUpgradePackage(selectedPackage))
            .then(() => {
                dispatch(setUserPackageNotExpired());
                setShowModal(false);
                setLoading(false);
            })
            .catch((error) => {
                console.error("Upgrade failed:", error);
                setLoading(false);
            });
    }

    function capitalizeFirstLetter(string) {
        if (!string) return string;
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    return (
        <React.Fragment>
            <div className="main main-app p-3 p-lg-4">
                <div className="d-flex align-items-center justify-content-between mb-3">
                    <div className="w-100">
                        <ol className="breadcrumb fs-sm mb-2">
                            <li className="breadcrumb-item">
                                <Link href="#">Upgrade Plan</Link>
                            </li>
                            <li className="breadcrumb-item active" aria-current="page">
                                Select Plan
                            </li>
                        </ol>
                        <div className="row g-0 d-flex align-items-center mb-3">
                            <div className="col-sm-6 col-md-8">
                                <h4 className="fs-24 mb-0">Upgrade Plan</h4>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={ styles['top-container'] }>
                    <p className={ styles['main-topic'] }>Pricing Plan</p>
                    {/* <p className={styles['desc']}>Our packages offer a variety of services to help your business thrive. Select the one that best fits your requirements and take your business to the next level. </p> */ }
                </div>
                { loading &&
                    <Placeholder as="div" animation="glow" className={ styles["middle-container"] }>
                        <Placeholder className={ styles["place-holder"] } />
                        <Placeholder className={ styles["place-holder"] } />
                        <Placeholder className={ styles["place-holder"] } />
                    </Placeholder>
                }
                { !loading &&
                    <div className={ styles["middle-container"] }>
                        { selectedPricingPackges.length > 0 && selectedPricingPackges.map((pkg) => (
                            <div
                                className={ `
                                ${pkg?.id === 2 ? styles["grid-item"] :
                                        pkg?.id === 3 ? styles["grid-item-business"] :
                                            pkg?.id === 4 ? styles["grid-item-enterprise"] :
                                                ""} 
                                ${(UserData.package_id === pkg.id && !UserExpired) && pkg?.id === 2 ? styles['selected'] :
                                        (UserData.package_id === pkg.id && !UserExpired) && pkg?.id === 3 ? styles['selected-business'] :
                                            (UserData.package_id === pkg.id && !UserExpired) && pkg?.id === 4 ? styles['selected-enterprise'] :
                                                ""}` }
                            >
                                <div className={ styles['title'] }>{ capitalizeFirstLetter(pkg.name) }</div>
                                <div className={ styles['desc'] }>{ pkg?.description }</div>
                                <div className={ styles["horizontal-line"] }></div>
                                <div className={ styles['price-monthly'] }>
                                    <span className={ styles['hilight'] }>${ pkg?.price }</span><span style={ { fontSize: "13px" } }> / month</span>
                                </div>
                                <button onClick={ () => pricingCardCLick(pkg.id) } className={ styles['btn'] }>
                                    Subscribe Now
                                </button>
                                <div className={ styles["horizontal-line"] }></div>
                                <div className={ styles['list'] }>
                                    { pkg?.features.length > 0 && pkg.features.map((feature) => (
                                        <div className={ styles['list-item'] }>
                                            <i style={ { fontSize: "16px", fontWeight: "700" } } className="ri-check-fill"></i>
                                            <div>{ feature?.feature_name }</div>
                                        </div>
                                    ))
                                    }
                                </div>
                            </div>
                        ))
                        }
                    </div>
                }

                <div className={ styles['bottom-container'] }>
                    <p className={ styles['title'] }>Compare plan features</p>
                    { packageLimitloading ?
                        <TableLoader
                            rowCount={ 4 }
                            columnCount={ 4 }
                        />
                        :
                        <>
                            <div className={ styles['main-container-column'] }>
                                <div className={ styles['child1'] }>
                                    Plan
                                </div>
                                <div className={ styles['child1'] }>
                                    Starter Plan
                                </div>
                                <div className={ styles['child1'] }>
                                    Business Plan
                                </div>
                                <div className={ styles['child1'] }>
                                    Enterprise Plan
                                </div>
                            </div>
                            <>
                                <div className={ styles['data-container'] }>
                                    { features && features.map((feature) => (
                                        <div key={ feature } className={ styles['main-container-data'] }>
                                            <div className={ styles['child1'] }>
                                                { feature
                                                    .replace("_", " ")
                                                    .replace(/\b\w/g, (l) => l.toUpperCase()) }
                                            </div>
                                            { packageList && packageList.map((pkg) => (
                                                <div key={ pkg.id } className={ styles['child1'] }>
                                                    { pkg[feature] ? pkg[feature] : "N/A" }
                                                </div>
                                            )) }
                                        </div>
                                    )) }
                                </div>
                            </>
                        </>
                    }
                </div>
                <ConfirmModal
                    show={ showModal }
                    onHide={ () => setShowModal(false) }
                    onConfirm={ handleConfirmUpgrade }
                    title="Please confirm the package subscription"
                    message={ `Are you sure you want to subscribe to the  ${selectedPackage === 2 ? "Starter" : selectedPackage === 3 ? "Business" : "Enterprise"} Package?` }
                    loading={ loading }
                />
            </div>
            <Footer />
        </React.Fragment>
    )
}

export default PricingPackages