import React, { useEffect, useState } from "react";
import MenuBar from "../components/home/MenuBar";
import Banner from "../components/home/Banner";
import MockupSection from "../components/home/MockupSection";
import CardList from "../components/common/CardList";
import ChooseWorkPreference from "../components/home/ChooseWorkPreference";
import TeamAcceleration from "../components/home/TeamAcceleration";
import FeedbackCarousel from "../components/home/FeedbackCarousel";
import ChooseYourPricePackage from "../components/home/ChooseYourPricePackage";
import Footer from "../components/home/Footer";
import { items } from "../data/HomeData";
import { get } from "../servces/Api";
import Alert from "../components/common/Alert";

const Home = () => {
  const ItemsPerPage = 3;

  const [feedbackDetails, setFeedbackDetails] = useState([]);
  const [feedbackDetailsLoading, setFeedbackDetailsLoading] = useState(false);

  useEffect(() => {
    setFeedbackDetailsLoading(true);
    const fetchData = async () => {
      try {
        const response = await get("/api/get_latest_feedbacks");
        if (response) {
          const feedbacks = response.data.feedbacks;
          setFeedbackDetails(feedbacks);
          setFeedbackDetailsLoading(false);
        }
      } catch (err) {
        Alert.error("Error in feedback details fetch");
        setFeedbackDetailsLoading(false);
      }
    };

    fetchData();
  }, []);
  return (
    <div className="page-home">
      <MenuBar />
      <Banner />
      <MockupSection />
      <CardList
        items={ items }
        ItemsPerPage={ ItemsPerPage }
        listTitle={ "Chose your template" }
      />
      <ChooseWorkPreference />
      <TeamAcceleration />
      <ChooseYourPricePackage />

      { feedbackDetails && <FeedbackCarousel feedbackDetailsLoading={feedbackDetailsLoading} feedbackData={ feedbackDetails } /> }
      <Footer />
    </div>
  );
};

export default Home;
