import React from "react";
import { Modal, Button, Spinner } from "react-bootstrap";

const ConfirmModal = ({ show, onHide, onConfirm, title, message, loading }) => {
  return (
    <Modal show={show} centered backdrop="static" keyboard={false}>
      <Modal.Header>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{message}</Modal.Body>
      <Modal.Footer>
        <Button 
        variant="danger" 
        onClick={onHide}
        disabled={loading}
        >
          Cancel
        </Button>
        <Button 
        variant="success" 
        onClick={onConfirm}
        disabled={loading}
        >
          { loading && <Spinner animation="border" size="sm" /> }{ " " }Confirm
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ConfirmModal;
