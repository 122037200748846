import React, { useRef, useState } from "react";
import { useSelector } from "react-redux";
import Footer from "../../components/dashboard/Footer";
import CreateNewTender from "../../components/dashboard/tenders/CreateNewTender";
import TenderList from "../../components/dashboard/tenders/TenderList";
import SearchBarSelection from "../../components/common/SearchBarSelection";

const AllTenders = () => {
  const moduleName = "All Tenders";
  const refetchTendersRef = useRef(null);
  const { loading, tenderList } = useSelector((state) => state.tender);
  const [searchCategory, setSearchCategory] = useState("");

  const handleRefetchTenders = () => {
    if (refetchTendersRef.current) {
      refetchTendersRef.current();
    }
  };

  const handleSearch = (value) => {
    setSearchCategory(value);
  };


  return (
    <>
      <div className="main main-app p-3 p-lg-4">
        <div className="d-flex align-items-center justify-content-between mb-4">
          <div>
            <ol className="breadcrumb fs-sm mb-1">
              <li className="breadcrumb-item" aria-current="page">
                Dashboard
              </li>
              <li className="breadcrumb-item active">{ moduleName }</li>
            </ol>
            <h4 className="main-title mb-0">{ moduleName }</h4>
          </div>
        </div>
        <div className="d-flex align-items-center justify-content-between mb-4">
          <div className="w-50">
            <SearchBarSelection
              onChange={ handleSearch }
              categoryList={ tenderList?.tender_category || [] }
              handleClearSearch={ () => setSearchCategory(0) }
              dis
            />
          </div>
          <div>
            <CreateNewTender onTenderCreated={ handleRefetchTenders } />
          </div>
        </div>
        <div className="inner-wrapper">
          <TenderList
            refetchTendersRef={ refetchTendersRef }
            searchCategory={ searchCategory }
          />
        </div>
        <Footer />
      </div>
    </>
  );
};

export default AllTenders;
