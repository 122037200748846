import { createAsyncThunk } from "@reduxjs/toolkit";
import { get, post } from "../../servces/Api";

//Fetch all tenders data
export const fetchProjectList = createAsyncThunk(
  "project/projectList",
  async (data, { rejectWithValue }) => {
    try {
      const response = await post("api/load_projects", data);

      if (response.status === 200) {
        return response.data;
      }
    } catch (error) {
      if (!error.response) {
        // network error
        return rejectWithValue("Network error. Please try again.");
      } else {
        // server error
        return rejectWithValue(
          "Failed to fetch tenders. Please try again later."
        );
      }
    }
  }
);

//project view
export const fetchProjectInfo = createAsyncThunk(
  "project/projectInfo",
  async (projectId, thunkApi) => {
    try {
      const response = await get(`api/load_one_project/${projectId}`);
      if (response.status === 200) {
        return response.data;
      }
    } catch (error) {
      if (!error.response) {
        // network error
        return thunkApi.rejectWithValue("Network error. Please try again.");
      } else {
        // server error
        return thunkApi.rejectWithValue(
          "Failed to fetch tenders. Please try again later."
        );
      }
    }
  }
);

//project Mark Project As Completed

export const markProjectCompleted = createAsyncThunk(
  "project/markAscompleted",
  async (data, { rejectWithValue }) => {
    try {
      const response = await post(`api/mark_status_complete_project`, data);

      if (response.status === 200) {
        return response.data;
      }
    } catch (error) {
      if (!error.response) {
        // network error
        return rejectWithValue("Network error. Please try again.");
      } else {
        // server error
        return rejectWithValue(
          "Failed to fetch tenders. Please try again later."
        );
      }
    }
  }
);
