import { createAsyncThunk } from "@reduxjs/toolkit";
import { get, post } from "../../servces/Api";
import Alert from "../../components/common/Alert";

//Company Tender Bid report-------------------------------------------------------------------

//get all for select tenders
export const fetchAllTendersData = createAsyncThunk(
    "report/report-data",
    async (_, thunkApi) => {
        try {
            const response = await get("api/get_all_tenders");
            if (response.status === 200) {
                const tenders = response.data.tenders;
                return tenders;
            }else {
                Alert.error("Unable to get tender list!");
            }
        } catch (error) {
            if (!error.response) {
                // network error
                return thunkApi.rejectWithValue("Network error. Please try again.");
            } else {
                // server error
                return thunkApi.rejectWithValue(
                    "Failed to fetch tenders. Please try again later."
                );
            }
        }
    }
);

//get bid company list
export const fetchAllBidCompanyData = createAsyncThunk(
    "report/report-data",
    async (_, thunkApi) => {
        try {
            const response = await get("api/get_all_bid_company");
            if (response.status === 200) {
                const companies = response.data.bid_companies;
                return companies;
            }else {
                Alert.error("Unable to get bid company list!");
            }
        } catch (error) {
            if (!error.response) {
                // network error
                return thunkApi.rejectWithValue("Network error. Please try again.");
            } else {
                // server error
                return thunkApi.rejectWithValue(
                    "Failed to fetch tenders. Please try again later."
                );
            }
        }
    }
);

//get all tenders
export const fetchInitialTendersData = createAsyncThunk(
    "report/report-data",
    async (data, thunkApi) => {
        try {
            const response = await post("api/company_bid_report", data);
            if (response.status === 200) {
                const tenders = response.data?.tender_report_details;
                return tenders;
            }else {
                Alert.error("Unable to get company tender bid list!");
            }
        } catch (error) {
            if (!error.response) {
                // network error
                return thunkApi.rejectWithValue("Network error. Please try again.");
            } else {
                // server error
                return thunkApi.rejectWithValue(
                    "Failed to fetch tenders. Please try again later."
                );
            }
        }
    }
);

//filter all tenders
export const filterTendersData = createAsyncThunk(
    "report/report-data",
    async (data, thunkApi) => {
        try {
            const response = await post("api/company_bid_report", data);
            if (response.status === 200) {
                const tenders = response.data?.tender_report_details;
                return tenders;
            }else {
                Alert.error("Unable to filter company tender bid list!");
            }
        } catch (error) {
            if (!error.response) {
                // network error
                return thunkApi.rejectWithValue("Network error. Please try again.");
            } else {
                // server error
                return thunkApi.rejectWithValue(
                    "Failed to fetch tenders. Please try again later."
                );
            }
        }
    }
);

//Company Project report-------------------------------------------------------------------

//get company project list
export const fetchCompanyProjectListData = createAsyncThunk(
    "report/report-data",
    async (_, thunkApi) => {
        try {
            const response = await get("api/load_company_projects");
            if (response.status === 200) {
                const companies = response.data?.load_company_projects;
                return companies;
            }else {
                Alert.error("Unable to get company project list!");
            }
        } catch (error) {
            if (!error.response) {
                // network error
                return thunkApi.rejectWithValue("Network error. Please try again.");
            } else {
                // server error
                return thunkApi.rejectWithValue(
                    "Failed to fetch tenders. Please try again later."
                );
            }
        }
    }
);

//get project categories list
export const fetchCategoryListData = createAsyncThunk(
    "report/report-data",
    async (_, thunkApi) => {
        try {
            const response = await get("api/get_all_categories");
            if (response.status === 200) {
                const users = response.data?.categories;
                return users;
            }else {
                Alert.error("Unable to category list!");
            }
        } catch (error) {
            if (!error.response) {
                // network error
                return thunkApi.rejectWithValue("Network error. Please try again.");
            } else {
                // server error
                return thunkApi.rejectWithValue(
                    "Failed to fetch tenders. Please try again later."
                );
            }
        }
    }
);

//get all company projects
export const fetchInitialCompanyProjectsData = createAsyncThunk(
    "report/report-data",
    async (data, thunkApi) => {
        try {
            const response = await post("api/company_project_report", data);
            if (response.status === 200) {
                const projects = response.data?.project_report_details;
                return projects;
            }else {
                Alert.error("Unable to get company project list!");
            }
        } catch (error) {
            if (!error.response) {
                // network error
                return thunkApi.rejectWithValue("Network error. Please try again.");
            } else {
                // server error
                return thunkApi.rejectWithValue(
                    "Failed to fetch tenders. Please try again later."
                );
            }
        }
    }
);

//filter company projects
export const filterCompanyProjectsData = createAsyncThunk(
    "report/report-data",
    async (data, thunkApi) => {
        try {
            const response = await post("api/company_project_report", data);
            if (response.status === 200) {
                const projects = response.data?.project_report_details;
                return projects;
            }else {
                Alert.error("Unable to filter company project list!");
            }
        } catch (error) {
            if (!error.response) {
                // network error
                return thunkApi.rejectWithValue("Network error. Please try again.");
            } else {
                // server error
                return thunkApi.rejectWithValue(
                    "Failed to fetch tenders. Please try again later."
                );
            }
        }
    }
);

//Company Tender report-------------------------------------------------------------------

//get progress types
export const fetchAllProgressTypesData = createAsyncThunk(
    "report/report-data",
    async (_, thunkApi) => {
        try {
            const response = await get("api/get_all_progress_types");
            if (response.status === 200) {
                const progressTypes = response.data?.tender_progress_types;
                return progressTypes;
            }else {
                Alert.error("Unable to get progress type list!");
            }
        } catch (error) {
            if (!error.response) {
                // network error
                return thunkApi.rejectWithValue("Network error. Please try again.");
            } else {
                // server error
                return thunkApi.rejectWithValue(
                    "Failed to fetch tenders. Please try again later."
                );
            }
        }
    }
);

//get customer list
export const fetchAllCustomersData = createAsyncThunk(
    "report/report-data",
    async (_, thunkApi) => {
        try {
            const response = await get("api/get_tender_customers");
            if (response.status === 200) {
                const customers = response.data?.customer_names;
                return customers;
            }else {
                Alert.error("Unable to get customer list!");
            }
        } catch (error) {
            if (!error.response) {
                // network error
                return thunkApi.rejectWithValue("Network error. Please try again.");
            } else {
                // server error
                return thunkApi.rejectWithValue(
                    "Failed to fetch tenders. Please try again later."
                );
            }
        }
    }
);

//get all company company tenders
export const fetchInitialCompanyTendersData = createAsyncThunk(
    "report/report-data",
    async (data, thunkApi) => {
        try {
            const response = await post("api/company_tender_report", data);
            if (response.status === 200) {
                const tenders = response.data?.tender_report_details;
                return tenders;
            }else {
                Alert.error("Unable to get company tender list!");
            }
        } catch (error) {
            if (!error.response) {
                // network error
                return thunkApi.rejectWithValue("Network error. Please try again.");
            } else {
                // server error
                return thunkApi.rejectWithValue(
                    "Failed to fetch tenders. Please try again later."
                );
            }
        }
    }
);

//filter company company tenders
export const filterCompanyTendersData = createAsyncThunk(
    "report/report-data",
    async (data, thunkApi) => {
        try {
            const response = await post("api/company_tender_report", data);
            if (response.status === 200) {
                const tenders = response.data?.tender_report_details;
                return tenders;
            }else {
                Alert.error("Unable to filter company tender list!");
            }
        } catch (error) {
            if (!error.response) {
                // network error
                return thunkApi.rejectWithValue("Network error. Please try again.");
            } else {
                // server error
                return thunkApi.rejectWithValue(
                    "Failed to fetch tenders. Please try again later."
                );
            }
        }
    }
);

//Tender Customer Quotation report-------------------------------------------------------------------

//get customer for quotation
export const fetchAllCustomersForQuotationData = createAsyncThunk(
    "report/report-data",
    async (_, thunkApi) => {
        try {
            const response = await get("api/get_customer_names_to_customer_quotation_report");
            if (response.status === 200) {
                const customers = response.data?.customer_names_to_customer_quotation;
                return customers;
            }else {
                Alert.error("Unable to get customer list for quotation report!");
            }
        } catch (error) {
            if (!error.response) {
                // network error
                return thunkApi.rejectWithValue("Network error. Please try again.");
            } else {
                // server error
                return thunkApi.rejectWithValue(
                    "Failed to fetch tenders. Please try again later."
                );
            }
        }
    }
);

//get all customer quotations
export const fetchInitialCustomerQuotationsData = createAsyncThunk(
    "report/report-data",
    async (data, thunkApi) => {
        try {
            const response = await post("api/company_customer_quotation_report", data);
            if (response.status === 200) {
                const quotations = response.data?.tender_customer_quotation_report_details;
                return quotations;
            }else {
                Alert.error("Unable to get customer quotation list!");
            }
        } catch (error) {
            if (!error.response) {
                // network error
                return thunkApi.rejectWithValue("Network error. Please try again.");
            } else {
                // server error
                return thunkApi.rejectWithValue(
                    "Failed to fetch tenders. Please try again later."
                );
            }
        }
    }
);

//filter customer quotations
export const filterCustomerQuotationsData = createAsyncThunk(
    "report/report-data",
    async (data, thunkApi) => {
        try {
            const response = await post("api/company_customer_quotation_report", data);
            if (response.status === 200) {
                const quotations = response.data?.tender_customer_quotation_report_details;
                return quotations;
            }else {
                Alert.error("Unable to filter customer quotation list!");
            }
        } catch (error) {
            if (!error.response) {
                // network error
                return thunkApi.rejectWithValue("Network error. Please try again.");
            } else {
                // server error
                return thunkApi.rejectWithValue(
                    "Failed to fetch tenders. Please try again later."
                );
            }
        }
    }
);

//Company - customer feedback report-------------------------------------------------------------------

//get all customer feedbacks
export const fetchInitialCustomerFeedbacksData = createAsyncThunk(
    "report/report-data",
    async (data, thunkApi) => {
        try {
            const response = await post("api/load_customer_feedback_filter", data);
            if (response.status === 200) {
                const feedbacks = response.data?.feedbacks;
                return feedbacks;
            }else {
                Alert.error("Unable to get customer feedback list!");
            }
        } catch (error) {
            if (!error.response) {
                // network error
                return thunkApi.rejectWithValue("Network error. Please try again.");
            } else {
                // server error
                return thunkApi.rejectWithValue(
                    "Failed to fetch tenders. Please try again later."
                );
            }
        }
    }
);

//get all customer quotations
export const filterCustomerFeedbacksData = createAsyncThunk(
    "report/report-data",
    async (data, thunkApi) => {
        try {
            const response = await post("api/load_customer_feedback_filter", data);
            if (response.status === 200) {
                const feedbacks = response.data?.feedbacks;
                return feedbacks;
            }else {
                Alert.error("Unable to filter customer feedback list!");
            }
        } catch (error) {
            if (!error.response) {
                // network error
                return thunkApi.rejectWithValue("Network error. Please try again.");
            } else {
                // server error
                return thunkApi.rejectWithValue(
                    "Failed to fetch tenders. Please try again later."
                );
            }
        }
    }
);

//Company - feedback analysis report-------------------------------------------------------------------

//get task status list
export const fetchTaskStatusData = createAsyncThunk(
    "report/report-data",
    async (_, thunkApi) => {
        try {
            const response = await get("api/all_project_task_status_types");
            if (response.status === 200) {
                const taskStatusTypes = response.data?.task_status_types;
                return taskStatusTypes;
            }else {
                Alert.error("Unable to get task status list!");
            }
        } catch (error) {
            if (!error.response) {
                // network error
                return thunkApi.rejectWithValue("Network error. Please try again.");
            } else {
                // server error
                return thunkApi.rejectWithValue(
                    "Failed to fetch tenders. Please try again later."
                );
            }
        }
    }
);

//get assignee list - feedback report
export const fetchAssigneeData = createAsyncThunk(
    "report/report-data",
    async (_, thunkApi) => {
        try {
            const response = await get("api/load_company_remaining_users");
            if (response.status === 200) {
                const users = response.data?.remaining_company_users;
                return users;
            }else {
                Alert.error("Unable to get assignee list!");
            }
        } catch (error) {
            if (!error.response) {
                // network error
                return thunkApi.rejectWithValue("Network error. Please try again.");
            } else {
                // server error
                return thunkApi.rejectWithValue(
                    "Failed to fetch tenders. Please try again later."
                );
            }
        }
    }
);

//get all customer feedbacks
export const fetchInitialFeedbackAnalysisData = createAsyncThunk(
    "report/report-data",
    async (data, thunkApi) => {
        try {
            const response = await post("api/project_task_filter_report", data);
            if (response.status === 200) {
                const feedbacks = response.data?.taskreportdetails;
                return feedbacks;
            }else {
                Alert.error("Unable to get feedback analysis data!");
            }
        } catch (error) {
            if (!error.response) {
                // network error
                return thunkApi.rejectWithValue("Network error. Please try again.");
            } else {
                // server error
                return thunkApi.rejectWithValue(
                    "Failed to fetch tenders. Please try again later."
                );
            }
        }
    }
);

//filter customer feedbacks
export const filterFeedbackAnalysisData = createAsyncThunk(
    "report/report-data",
    async (data, thunkApi) => {
        try {
            const response = await post("api/project_task_filter_report", data);
            if (response.status === 200) {
                const feedbacks = response.data?.taskreportdetails;
                return feedbacks;
            }else {
                Alert.error("Unable to filter feedback analysis data!");
            }
        } catch (error) {
            if (!error.response) {
                // network error
                return thunkApi.rejectWithValue("Network error. Please try again.");
            } else {
                // server error
                return thunkApi.rejectWithValue(
                    "Failed to fetch tenders. Please try again later."
                );
            }
        }
    }
);

//Admin - general feedback report-------------------------------------------------------------------

//get all general feedbacks
export const fetchInitialGeneralFeedbacksData = createAsyncThunk(
    "report/report-data",
    async (data, thunkApi) => {
        try {
            const response = await post("api/load_general_feedback", data);
            if (response.status === 200) {
                const feedbacks = response.data?.feedbacks;
                return feedbacks;
            }else {
                Alert.error("Unable to get general feedback data!");
            }
        } catch (error) {
            if (!error.response) {
                // network error
                return thunkApi.rejectWithValue("Network error. Please try again.");
            } else {
                // server error
                return thunkApi.rejectWithValue(
                    "Failed to fetch tenders. Please try again later."
                );
            }
        }
    }
);

//filter general feedbacks
export const filterGeneralFeedbacksData = createAsyncThunk(
    "report/report-data",
    async (data, thunkApi) => {
        try {
            const response = await post("api/load_general_feedback", data);
            if (response.status === 200) {
                const feedbacks = response.data?.feedbacks;
                return feedbacks;
            }else {
                Alert.error("Unable to filter general feedback data!");
            }
        } catch (error) {
            if (!error.response) {
                // network error
                return thunkApi.rejectWithValue("Network error. Please try again.");
            } else {
                // server error
                return thunkApi.rejectWithValue(
                    "Failed to fetch tenders. Please try again later."
                );
            }
        }
    }
);

//Admin - user report-------------------------------------------------------------------

//get all companies
export const fetchAllCompanyData = createAsyncThunk(
    "report/report-data",
    async (_, thunkApi) => {
        try {
            const response = await get("api/get_all_companies");
            if (response.status === 200) {
                const companies = response.data?.companies;
                return companies;
            }else {
                Alert.error("Unable to get company list!");
            }
        } catch (error) {
            if (!error.response) {
                // network error
                return thunkApi.rejectWithValue("Network error. Please try again.");
            } else {
                // server error
                return thunkApi.rejectWithValue(
                    "Failed to fetch tenders. Please try again later."
                );
            }
        }
    }
);

//get all admin users
export const fetchInitialUsersData = createAsyncThunk(
    "report/report-data",
    async (data, thunkApi) => {
        try {
            const response = await post("api/admin_user_management_report", data);
            if (response.status === 200) {
                const users = response.data?.users_report_details;
                return users;
            }else {
                Alert.error("Unable to get users data!");
            }
        } catch (error) {
            if (!error.response) {
                // network error
                return thunkApi.rejectWithValue("Network error. Please try again.");
            } else {
                // server error
                return thunkApi.rejectWithValue(
                    "Failed to fetch tenders. Please try again later."
                );
            }
        }
    }
);

//filter admin users
export const filterUsersData = createAsyncThunk(
    "report/report-data",
    async (data, thunkApi) => {
        try {
            const response = await post("api/admin_user_management_report", data);
            if (response.status === 200) {
                const users = response.data?.users_report_details;
                return users;
            }else {
                Alert.error("Unable to filter users data!");
            }
        } catch (error) {
            if (!error.response) {
                // network error
                return thunkApi.rejectWithValue("Network error. Please try again.");
            } else {
                // server error
                return thunkApi.rejectWithValue(
                    "Failed to fetch tenders. Please try again later."
                );
            }
        }
    }
);

//Admin - tender report-------------------------------------------------------------------

//get all admin tenders
export const fetchInitialAdminTenderData = createAsyncThunk(
    "report/report-data",
    async (data, thunkApi) => {
        try {
            const response = await post("api/admin_tender_report", data);
            if (response.status === 200) {
                const tenders = response.data?.tender_report_details;
                return tenders;
            }else {
                Alert.error("Unable to get admin tender data!");
            }
        } catch (error) {
            if (!error.response) {
                // network error
                return thunkApi.rejectWithValue("Network error. Please try again.");
            } else {
                // server error
                return thunkApi.rejectWithValue(
                    "Failed to fetch tenders. Please try again later."
                );
            }
        }
    }
);

//filter admin tenders
export const filterAdminTenderData = createAsyncThunk(
    "report/report-data",
    async (data, thunkApi) => {
        try {
            const response = await post("api/admin_tender_report", data);
            if (response.status === 200) {
                const tenders = response.data?.tender_report_details;
                return tenders;
            }else {
                Alert.error("Unable to filter admin tender data!");
            }
        } catch (error) {
            if (!error.response) {
                // network error
                return thunkApi.rejectWithValue("Network error. Please try again.");
            } else {
                // server error
                return thunkApi.rejectWithValue(
                    "Failed to fetch tenders. Please try again later."
                );
            }
        }
    }
);

//Customer - project report-------------------------------------------------------------------

//get all admin tenders
export const fetchInitialProjectListCustomer = createAsyncThunk(
    "report/report-data",
    async (_, thunkApi) => {
        try {
            const response = await get("api/load_customer_projects");
            if (response.status === 200) {
                const projects = response.data?.load_customer_projects;
                return projects;
            }else {
                Alert.error("Unable to get customer project list!");
            }
        } catch (error) {
            if (!error.response) {
                // network error
                return thunkApi.rejectWithValue("Network error. Please try again.");
            } else {
                // server error
                return thunkApi.rejectWithValue(
                    "Failed to fetch tenders. Please try again later."
                );
            }
        }
    }
);

//get all admin tenders
export const fetchInitialCustomerProjectData = createAsyncThunk(
    "report/report-data",
    async (data, thunkApi) => {
        try {
            const response = await post("api/customer_project_report", data);
            if (response.status === 200) {
                const tenders = response.data?.project_report_details;
                return tenders;
            }else {
                Alert.error("Unable to get customer projects data!");
            }
        } catch (error) {
            if (!error.response) {
                // network error
                return thunkApi.rejectWithValue("Network error. Please try again.");
            } else {
                // server error
                return thunkApi.rejectWithValue(
                    "Failed to fetch tenders. Please try again later."
                );
            }
        }
    }
);

//get all admin tenders
export const filterCustomerProjectData = createAsyncThunk(
    "report/report-data",
    async (data, thunkApi) => {
        try {
            const response = await post("api/customer_project_report", data);
            if (response.status === 200) {
                const tenders = response.data?.project_report_details;
                return tenders;
            }else {
                Alert.error("Unable to filter customer projects data!");
            }
        } catch (error) {
            if (!error.response) {
                // network error
                return thunkApi.rejectWithValue("Network error. Please try again.");
            } else {
                // server error
                return thunkApi.rejectWithValue(
                    "Failed to fetch tenders. Please try again later."
                );
            }
        }
    }
);