import React, { useState, useEffect, useRef } from "react";
import {
  Dropdown,
  Row,
  Col,
  Nav,
  Form,
  Placeholder,
  Button,
  Spinner,
} from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import PerfectScrollbar from "react-perfect-scrollbar";
import Avatar from "../../components/common/Avatar";
import { get, post } from "../../servces/Api";
import Alert from "../common/Alert";
import pusher from "../../pusherConfig";
import { useSelector, useDispatch } from "react-redux";
import { uploadFile, uploadReset } from "../../store/actions/fileUploadActions";
import ViewAttachmentModal from "../common/ViewAttachmentModal";
import FormModal from "../common/FormModal";
import ChatRatingModal from "./ChatRatingModal";
import { getSasToken } from "../../utils/utils";
import { OverlayTrigger } from "react-bootstrap";
import { Tooltip } from "react-bootstrap";

import img16 from "../../assets/img/chat/img16.jpg";
import userImg from "../../assets/img/user.png";
import { projectUserTypes } from "../../utils/types";

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const Chat = () => {
  const query = useQuery();
  const sasToken = getSasToken();
  const projectId = query.get("projectId");
  const UserData = useSelector((state) => state.auth.userInfo);
  const blobFileData = useSelector((state) => state.fileUpload);

  const UserId = UserData.id;

  const [msgShow, setMsgShow] = useState(false);
  const [chatList, setChatList] = useState([]);
  const [selectedChatRoom, setSelectedChatRoom] = useState("");
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState("");
  const [preview, setPreview] = useState("");
  const [sendingMsg, setSendingMsg] = useState(false);
  const [chatClicked, setChatClicked] = useState(false);
  const [chatLoading, setChatLoading] = useState(false);
  const [roomSelected, setRoomSelected] = useState();
  const [chatUserRole, setChatUserRole] = useState();
  const [chatCustomerRole, setChatCustomerRole] = useState();

  const [showUserAssignModal, setShowUserAssignModal] = useState(false);
  const [userAssignLoading, setUserAssignLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [availableUsers, setAvailableUsers] = useState([]);
  const [availableUsersForRefresh, setAvailableUsersForRefresh] = useState([]);
  const [userRoles, setUserRoles] = useState({});
  const [roleErrors, setRoleErrors] = useState({});

  const [showViewModal, setShowViewModal] = useState(false);
  const [url, setUrl] = useState(null);
  const chatEndRef = useRef(null);
  const scrollbarRef = useRef(null);

  const [showRatingModal, setShowRatingModal] = useState({ open: false });

  const dispatch = useDispatch();
  const messagesRef = useRef(messages);

  const scrollToBottom = () => {
    if (scrollbarRef.current) {
      scrollbarRef.current.scrollTop = scrollbarRef.current.scrollHeight;
    }
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages, preview]);

  useEffect(() => {
    setPreview(false);
    dispatch(uploadReset());
    setNewMessage("");

    if (selectedChatRoom) {
      const selectedChatRoomData = chatList.filter(
        (chat) => chat.chat_room.id === selectedChatRoom
      );
      setRoomSelected(selectedChatRoomData[0]);
      setAvailableUsers(
        selectedChatRoomData[0].non_allocated_users
          ? selectedChatRoomData[0].non_allocated_users
          : []
      );
      setAvailableUsersForRefresh(
        selectedChatRoomData[0].non_allocated_users
          ? selectedChatRoomData[0].non_allocated_users
          : []
      );
    }
  }, [selectedChatRoom]);

  useEffect(() => {
    if (roomSelected) {
      const getCompnayUser = () => {
        const userRoleMap = {};
        const userIds = roomSelected?.chat_room.user_ids;
        const roleIds = roomSelected?.chat_room.user_type_ids;

        for (let i = 0; i < userIds.length; i++) {
          userRoleMap[userIds[i]] = roleIds[i];
        }

        for (let i = 0; i < userIds.length; i++) {
          if (userRoleMap[userIds[i]] === 1) {
            return userIds[i];
          }
        }

        return "";
      };

      const getCustomerUser = () => {
        const userRoleMap = {};
        const userIds = roomSelected?.chat_room.user_ids;
        const roleIds = roomSelected?.chat_room.user_type_ids;

        for (let i = 0; i < userIds.length; i++) {
          userRoleMap[userIds[i]] = roleIds[i];
        }

        for (let i = 0; i < userIds.length; i++) {
          if (userRoleMap[userIds[i]] === 2) {
            return userIds[i];
          }
        }

        return "";
      };

      const u_id = getCompnayUser();
      const cus_id = getCustomerUser();
      setChatUserRole(u_id);
      setChatCustomerRole(cus_id);
    }
  }, [roomSelected]);

  useEffect(() => {
    getChatList();
  }, []);

  useEffect(() => {
    if (selectedChatRoom) {
      initChatMessages();
    }
    const channel = pusher.subscribe("nfs-chat");
    channel.bind("new-message", (data) => {
      const chatData = data.chat;
      if (chatData.chat_room_id === selectedChatRoom) {
        const currentMessages = messagesRef.current;
        addSingleMessage({ chatData, currentMessages });
      }
    });
    return () => {
      channel.unbind();
      pusher.unsubscribe("nfs-chat");
    };
  }, [selectedChatRoom]);

  useEffect(() => {
    messagesRef.current = messages;
  }, [messages]);

  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <Link
      to=""
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
      className="header-link"
    >
      {children}
    </Link>
  ));

  function sortItemsByCreatedAtAscending(data) {
    data.forEach((group) => {
      group.items.sort(
        (a, b) => new Date(a.created_at) - new Date(b.created_at)
      );
    });

    data.sort((a, b) => {
      const dateA =
        a.date === "Today" ? new Date().toISOString().slice(0, 10) : a.date;
      const dateB =
        b.date === "Today" ? new Date().toISOString().slice(0, 10) : b.date;
      return new Date(dateA) - new Date(dateB);
    });

    return data;
  }

  //API functions
  const initChatMessages = async () => {
    setChatLoading(true);
    const data = {
      project_id: projectId,
      chat_room_id: selectedChatRoom,
    };
    try {
      const response = await post("/api/load_chats", data);
      const chats = response.data.chats;
      const convertedMessages = convertMessages(chats);
      setChatLoading(false);
      setMessages(convertedMessages);
    } catch (error) {
      Alert.error("Unable to Get Messages!");
      setChatLoading(false);
    }
  };

  const getChatList = async () => {
    const updatedData = {
      project_id: projectId,
    };
    try {
      const response = await post(
        "/api/chat_room_list_by_project",
        updatedData
      );
      if (response.status === 200) {
        const chats = response.data;
        setChatList(response.data);
        if (chats) {
          setSelectedChatRoom(chats[0].chat_room.id);
          setSelectedIndex(0);
          setRoomSelected(chats[0]);
          setAvailableUsers(chats[0].non_allocated_users);
          setAvailableUsersForRefresh(chats[0].non_allocated_users);
        } else {
          setSelectedChatRoom("");
          setSelectedIndex(0);
          setRoomSelected();
        }
      } else {
        Alert.error(response.error);
      }
    } catch (error) {
      Alert.error("Unable to get chat list!");
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter" && !sendingMsg) {
      event.preventDefault();
      sendMessageToBackend();
    }
  };

  const sendMessageToBackend = async () => {
    setSendingMsg(true);
    try {
      const data = {
        project_id: projectId,
        chat_room_id: selectedChatRoom ? selectedChatRoom : 1,
        msg_type: "1",
        ref_number: "1",
        sender_id: UserId.toString(),
        msg_text: newMessage,
        user_type: "1",
        attach_file_name: blobFileData.uploadedFileName,
      };
      const response = await post("/api/send_chat_message", data);
      if (response.status === 200) {
        setNewMessage("");
        setPreview("");
        dispatch(uploadReset());
        setSendingMsg(false);
        Alert.success("Messages Sent Successfully!");
      } else {
        setSendingMsg(false);
        Alert.error("Unable to Send Messages!");
      }
    } catch (error) {
      setSendingMsg(false);
      Alert.error("Unable to Send Messages!");
    }
  };

  //Supportive functions
  function convertMessages(chats) {
    const messageGroup = [];
    chats.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));

    chats.forEach((message) => {
      const date = message.created_at.slice(0, 10);
      const isToday = new Date().toISOString().slice(0, 10) === date;

      if (!messageGroup[date]) {
        messageGroup[date] = { date: isToday ? "Today" : date, items: [] };
      }

      const item = { messages: [] };

      if (message.msg_type === "3") {
        item.center = true;
        item.ref_number = message.ref_number;
        item.text = message.msg_text;
        item.time = message.created_at.slice(11, 16);
        item.created_at = message.created_at;
        item.status = message.task_status_type?.type_name;
      } else {
        if (message.sender_id === UserId.toString()) {
          item.reverse = true;
          item.created_at = message.created_at;
          item.messages.push({
            text: message.msg_text,
            time: message.created_at.slice(11, 16),
            attachments: message.attach_file_name,
          });
        } else {
          item.avatar = {
            status: "online",
            img: img16,
            name: message?.user?.first_name,
          };
          item.created_at = message.created_at;
          item.messages.push({
            text: message.msg_text,
            time: message.created_at.slice(11, 16),
            attachments: message.attach_file_name,
          });
        }
      }
      messageGroup[date].items.push(item);
    });

    const sortedMessageGroups = Object.values(messageGroup).map((group) => {
      group.items.sort(
        (a, b) => new Date(b.created_at) - new Date(a.created_at)
      );
      return group;
    });

    sortedMessageGroups.sort(
      (a, b) =>
        new Date(
          b.date === "Today" ? new Date().toISOString().slice(0, 10) : b.date
        ) -
        new Date(
          a.date === "Today" ? new Date().toISOString().slice(0, 10) : a.date
        )
    );

    return sortItemsByCreatedAtAscending(sortedMessageGroups);
  }

  function addSingleMessage({ chatData, currentMessages }) {
    const date = chatData.created_at.slice(0, 10);
    const isToday = new Date().toISOString().slice(0, 10) === date;

    const todayGroupIndex = currentMessages.findIndex(
      (group) => group.date === "Today"
    );

    const item = { messages: [] };

    if (chatData.msg_type === "3") {
      item.center = true;
      item.ref_number = chatData.ref_number;
      item.text = chatData.msg_text;
      item.time = chatData.created_at.slice(11, 16);
      item.created_at = chatData.created_at;
    } else {
      if (chatData.sender_id === UserId.toString()) {
        item.reverse = true;
        item.created_at = chatData.created_at;
        item.messages.push({
          text: chatData.msg_text,
          time: chatData.created_at.slice(11, 16),
          attachments: chatData.attach_file_name,
        });
      } else {
        item.avatar = {
          status: "online",
          img: img16,
          name: chatData?.user?.first_name,
        };
        item.created_at = chatData.created_at;
        item.messages.push({
          text: chatData.msg_text,
          time: chatData.created_at.slice(11, 16),
          attachments: chatData.attach_file_name,
        });
      }
    }

    setMessages((prevMessageGroup) => {
      const updatedMessageGroup = [...prevMessageGroup];

      if (todayGroupIndex !== -1) {
        updatedMessageGroup[todayGroupIndex].items.push(item);
      } else {
        updatedMessageGroup.push({ date: "Today", items: [item] });
      }

      return sortItemsByCreatedAtAscending(updatedMessageGroup);
    });
  }

  const navToggle = (e) => {
    e.target.closest(".row").classList.toggle("nav-show");
  };

  function isValidFileType(filename) {
    const validExtensions = [
      ".jpg",
      ".jpeg",
      ".png",
      ".pdf",
      ".doc",
      ".docx",
      ".xls",
      ".xlsx",
    ];
    const ext = filename.slice(filename.lastIndexOf(".")).toLowerCase();
    return validExtensions.includes(ext);
  }

  const handleFileChange = async (event) => {
    dispatch(uploadReset());

    let file = event.target.files[0];

    if (file && !isValidFileType(file.name)) {
      Alert.error(
        "Please select a valid file (PNG, JPEG, JPG, PDF, DOC, DOCX, XLS, XLSX"
      );
      return;
    }

    if (file && file.size > 5242880) {
      Alert.error("File size exceeds the maximum limit of 5MB");
      return;
    }

    setPreview(URL.createObjectURL(file));

    try {
      const url = await dispatch(uploadFile({ file, sasToken }));
    } catch (error) {
      setPreview("");
    }
  };

  function isImageFile(filename) {
    const imageExtensions = [".jpg", ".jpeg", ".png", ".gif", ".bmp"];
    const ext = filename.slice(filename.lastIndexOf(".")).toLowerCase();
    return imageExtensions.includes(ext);
  }

  const getFileName = (name) => {
    const parts = name.split("_");
    const fileNameWithoutTimestamp = parts[parts.length - 1];
    return fileNameWithoutTimestamp;
  };

  const getChatroomByID = (id) => {
    const chatRoom = chatList.find((chat) => chat.chat_room.id === id);

    const users = chatRoom.users;
    const userCount = users.length;

    const getUserName = (user) => {
      return user.id === UserId
        ? "You"
        : `${user.first_name} ${user.last_name}`;
    };

    if (userCount === 0) return "";
    if (userCount === 1) return getUserName(users[0]);
    if (userCount === 2)
      return `${getUserName(users[0])} and ${getUserName(users[1])}`;

    const firstTwoNames = `${getUserName(users[0])}, ${getUserName(users[1])}`;
    const remainingCount = userCount - 2;

    return `${firstTwoNames} and ${remainingCount} ${
      remainingCount > 1 ? "others" : "other"
    }`;
  };

  const generateUserAvailable = (users) => {
    const userCount = users.length;

    const getUserName = (user) => {
      return user.id === UserId
        ? "You"
        : `${user.first_name} ${user.last_name}`;
    };

    if (userCount === 0) return "";
    if (userCount === 1) return getUserName(users[0]);
    if (userCount === 2)
      return `${getUserName(users[0])} and ${getUserName(users[1])}`;

    const firstTwoNames = `${getUserName(users[0])}, ${getUserName(users[1])}`;
    const remainingCount = userCount - 2;

    return `${firstTwoNames} and ${remainingCount} ${
      remainingCount > 1 ? "others" : "other"
    }`;
  };

  //view Attachment functions
  const openModal = (name) => {
    const realUrl = `https://${process.env.REACT_APP_STORAGE_ACCOUNT_NAME}.blob.core.windows.net/${process.env.REACT_APP_STORAGE_CONTAINER_NAME}/${name}?${sasToken}`;
    setUrl(realUrl);
    setShowViewModal(true);
  };

  const closeModal = () => {
    setShowViewModal(false);
  };

  //view user assign modal functions
  const toggleUserAssignModal = () => {
    setSelectedUsers([]);
    setSearchQuery("");
    setAvailableUsers(availableUsersForRefresh);
    setShowUserAssignModal(!showUserAssignModal);
  };

  const assignUserToChat = async () => {
    setUserAssignLoading(true);
    const errors = {};
    selectedUsers.forEach((userId) => {
      if (!userRoles[userId]) {
        errors[userId] = "Please select a role type";
      }
    });

    if (Object.keys(errors).length > 0) {
      setRoleErrors(errors);
      setUserAssignLoading(false);
      return;
    }

    try {
      const user_type_ids = selectedUsers.map((userId) => userRoles[userId]);
      const data = {
        project_id: projectId,
        user_ids: selectedUsers,
        user_type_ids: user_type_ids,
        chat_room_id: selectedChatRoom,
      };

      const response = await post("/api/add_user_chat_room/", data);
      if (response.status === 200) {
        Alert.success("User assigned to chat room successfully");
        toggleUserAssignModal();
        getChatList();
        setUserAssignLoading(false);
      } else {
        Alert.error("Error in assigning user to chat room!");
      }
    } catch (error) {
      console.error("Error assigning user to chat room:", error);
      Alert.error("Error in assigning user to chat room!");
      setUserAssignLoading(false);
    }
  };

  const handleSearch = () => {
    const filteredUsers = availableUsers.filter(
      (user) =>
        user.first_name.toLowerCase().includes(searchQuery.toLowerCase()) ||
        user.last_name.toLowerCase().includes(searchQuery.toLowerCase())
    );
    setAvailableUsers(filteredUsers);
  };

  const handleRefresh = () => {
    setSearchQuery("");
    setAvailableUsers(availableUsersForRefresh);
    setRoleErrors({});
    setUserRoles({});
    setSelectedUsers([]);
  };

  const handleCheckboxChange = (userId) => {
    if (selectedUsers.includes(userId)) {
      setSelectedUsers(selectedUsers.filter((id) => id !== userId));
      setUserRoles((prevState) => {
        const updatedRoles = { ...prevState };
        delete updatedRoles[userId];
        return updatedRoles;
      });
      setRoleErrors((prevState) => {
        const updatedErrors = { ...prevState };
        delete updatedErrors[userId];
        return updatedErrors;
      });
    } else {
      setSelectedUsers([...selectedUsers, userId]);
    }
  };

  const handleRoleChange = (userId, roleId) => {
    setUserRoles({
      ...userRoles,
      [userId]: roleId,
    });
    setRoleErrors((prevState) => {
      const updatedErrors = { ...prevState };
      delete updatedErrors[userId];
      return updatedErrors;
    });
  };

  //function related to system status update
  const modalToggleFuncRating = () => {
    setShowRatingModal(!showRatingModal.open);
  };

  return (
    <>
      <div className={"chat-panel shadow" + (msgShow ? " msg-show" : "")}>
        <div className="chat-sidebar">
          <div className="sidebar-header">
            <h6 className="sidebar-title me-auto">Chat Messages</h6>

            <Dropdown align="end">
              <Dropdown.Toggle as={CustomToggle}>
                <i className="ri-more-2-fill"></i>
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item href="">
                  <i className="ri-question-answer-line"></i> Create Channel
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>

            <Link className="header-link ms-1" title="New message">
              <i className="ri-chat-new-line"></i>
            </Link>
          </div>

          <PerfectScrollbar className="sidebar-body">
            <label className="sidebar-label mb-2">Channels</label>

            <div className="chat-group mb-4">
              {chatList.length > 0 &&
                chatList.map((channel, index) => (
                  <div
                    onClick={() => {
                      setChatClicked(true);
                      setSelectedChatRoom(channel.chat_room.id);
                      setSelectedIndex(index);
                    }}
                    className={`chat-item ${
                      selectedChatRoom === channel.chat_room.id
                        ? "selected"
                        : ""
                    }`}
                    key={index + channel.chat_room.id}
                  >
                    <div className="avatar">
                      <span className="avatar-initial">#</span>
                    </div>
                    <div className="chat-item-body">
                      <h6> Group {index + 1}</h6>
                      <div className="user-list">
                        {generateUserAvailable(channel.users)}
                      </div>
                    </div>
                  </div>
                ))}
              {chatList.length <= 0 && (
                <div className="chat-list-empty">
                  No chennel available for the moment
                </div>
              )}
            </div>
          </PerfectScrollbar>
        </div>
        <div className="chat-body">
          {selectedChatRoom && (
            <div className="chat-body-header">
              <div className="chat-item">
                <div className="avatar">
                  <span className="avatar-initial">#</span>
                </div>
                <div className="chat-item-body">
                  <h6 className="mb-1">Group {selectedIndex + 1}</h6>
                  <div className="user-list">
                    {getChatroomByID(selectedChatRoom)}
                  </div>
                </div>
              </div>
              {UserId === chatUserRole && (
                <Nav className="nav-icon ms-auto">
                  <Nav.Link
                    onClick={toggleUserAssignModal}
                    title="Invite People"
                  >
                    <i className="ri-user-add-line"></i>
                  </Nav.Link>
                </Nav>
              )}
            </div>
          )}
          <PerfectScrollbar
            containerRef={(ref) => (scrollbarRef.current = ref)}
            className="chat-body-content"
          >
            {!chatLoading &&
              messages.map((msgroup, index) => (
                <React.Fragment key={index + msgroup.date}>
                  <div key={msgroup.date} className="divider">
                    <span>{msgroup.date}</span>
                  </div>
                  {msgroup.items.map((item, ind) => (
                    <React.Fragment>
                      {!item.center ? (
                        <div
                          key={ind}
                          className={
                            "msg-item" + (item.reverse ? " reverse" : "")
                          }
                        >
                          {item.avatar && (
                            <Avatar
                              status={item.avatar.status}
                              img={item.avatar.img}
                            />
                          )}
                          <div className="msg-body-container">
                            {item.avatar && (
                              <div className="sender-name">
                                {item?.avatar?.name}
                              </div>
                            )}
                            <div className="msg-body">
                              {item.messages.map((message, i) => (
                                <Row
                                  key={i + message.time}
                                  className="gx-3 row-cols-auto"
                                >
                                  <Col>
                                    <div
                                      key={i}
                                      className="msg-bubble"
                                      onMouseOver={navToggle}
                                      onMouseLeave={navToggle}
                                    >
                                      {message.attachments &&
                                        isImageFile(message.attachments) && (
                                          <div
                                            id="img-div"
                                            className="image-div"
                                            onClick={() =>
                                              openModal(message.attachments)
                                            }
                                          >
                                            <img
                                              src={`https://${process.env.REACT_APP_STORAGE_ACCOUNT_NAME}.blob.core.windows.net/${process.env.REACT_APP_STORAGE_CONTAINER_NAME}/${message.attachments}?${sasToken}`}
                                              alt="attach file image"
                                              className="image-show"
                                            />
                                          </div>
                                        )}
                                      {message.attachments &&
                                        !isImageFile(message.attachments) && (
                                          <div
                                            id="doc-div"
                                            className="doc-link"
                                            onClick={() =>
                                              openModal(message.attachments)
                                            }
                                          >
                                            <div className="icon">
                                              <i
                                                className={`ri-file-download-fill attachment-icon`}
                                              ></i>
                                            </div>
                                            <div className="doc-name">
                                              {getFileName(message.attachments)}
                                            </div>
                                          </div>
                                        )}
                                      {message.text}
                                      <span>{message.time}</span>
                                    </div>
                                  </Col>
                                  {/* <Col>
                              <Nav className="nav-icon">
                                <Nav.Link href="">
                                  <i className="ri-reply-line"></i>
                                </Nav.Link>
                                <Nav.Link href="">
                                  <i className="ri-more-fill"></i>
                                </Nav.Link>
                              </Nav>
                            </Col> */}
                                </Row>
                              ))}
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div key={ind + item.text} className="status">
                          <div className="status-container">
                            <div className="text-container">
                              <i
                                style={{ fontSize: "12px" }}
                                className={`ri-task-line`}
                              ></i>
                              {item.status === "Done" &&
                              UserId === chatCustomerRole ? (
                                <OverlayTrigger
                                  placement="top"
                                  overlay={
                                    <Tooltip id={index}>Add Feedback</Tooltip>
                                  }
                                >
                                  <Link
                                    className="text"
                                    onClick={() =>
                                      setShowRatingModal({
                                        open: true,
                                        ref_number: item.ref_number,
                                      })
                                    }
                                  >
                                    {item.text}
                                  </Link>
                                </OverlayTrigger>
                              ) : (
                                <div className="text">{item.text}</div>
                              )}
                            </div>
                            <div className="time">{item.time}</div>
                          </div>
                        </div>
                      )}
                    </React.Fragment>
                  ))}
                </React.Fragment>
              ))}
            {!chatLoading && messages.length <= 0 && selectedChatRoom && (
              <div className="chat-empty">
                <div>Send a message to start the chat...</div>
              </div>
            )}
            {chatLoading && (
              <div className="chat-loading">
                <div>loading...</div>
              </div>
            )}
            {preview && !blobFileData.error && (
              <div className="chat-preview-container">
                <div className="chat-preview">
                  {!blobFileData.isLoading && (
                    <i
                      onClick={() => {
                        dispatch(uploadReset());
                        setPreview("");
                      }}
                      className={`ri-close-circle-line preview-icon`}
                    ></i>
                  )}
                  {blobFileData.isLoading ? (
                    <i className={`ri-loader-2-fill preview-loader`}></i>
                  ) : (
                    <i className={`ri-attachment-fill preview-done`}></i>
                  )}
                </div>
                {!blobFileData.isLoading && (
                  <p className="name">
                    {getFileName(
                      blobFileData.uploadedFileName
                        ? blobFileData.uploadedFileName
                        : ""
                    )}
                  </p>
                )}
              </div>
            )}
          </PerfectScrollbar>
          {selectedChatRoom && (
            <div className="chat-body-footer">
              <label className="file-attach" htmlFor="file-upload">
                <i
                  style={{
                    cursor: `${
                      blobFileData.isLoading || blobFileData.blobUrl !== null
                        ? "not-allowed"
                        : ""
                    }`,
                  }}
                  className={`ri-image-line file-icon ${
                    blobFileData.blobUrl !== null ? "attached" : ""
                  }`}
                ></i>
              </label>
              <input
                key={blobFileData.blobUrl}
                disabled={
                  blobFileData.isLoading || blobFileData.blobUrl !== null
                }
                id="file-upload"
                type="file"
                className={`input`}
                accept=".jpg, .jpeg, .png, application/pdf, .doc, .docx, .xls, .xlsx"
                size={5242880}
                onChange={handleFileChange}
              />
              <div className="msg-box">
                <Form.Control
                  type="text"
                  placeholder="Write your message..."
                  onChange={(e) => setNewMessage(e.target.value)}
                  value={newMessage}
                  onKeyPress={handleKeyPress}
                />
                {sendingMsg ? (
                  <Link className="msg-send">
                    <Spinner animation="border" size="sm" />
                  </Link>
                ) : (
                  <Link
                    onClick={newMessage ? sendMessageToBackend : null}
                    className={`msg-send ${newMessage ? "" : "disabled"}`}
                  >
                    <i className="ri-send-plane-2-line"></i>
                  </Link>
                )}
              </div>
              {/* <Nav className="nav-icon">
              <Nav.Link href="">
                <i className="ri-thumb-up-line"></i>
              </Nav.Link>
            </Nav> */}
            </div>
          )}
        </div>
      </div>

      <FormModal
        moduleName="Assign Users"
        modalState={showUserAssignModal}
        toggleFormModal={toggleUserAssignModal}
        size="md"
      >
        <div className="modal-body">
          <div className="d-flex align-items-center gap-3 mb-4">
            <Form.Control
              className="flex-1"
              size="sm"
              type="text"
              placeholder="Search here..."
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
            <Button
              className="fs-6 text-capitalize"
              variant="primary"
              onClick={handleSearch}
            >
              Search
            </Button>
            <button className="reset-btn" onClick={handleRefresh}>
              <i className="ri-refresh-line fs-6"></i>
            </button>
          </div>
          <div className="user-cards">
            {availableUsers.length > 0 ? (
              availableUsers.map((user) => (
                <div key={user.first_name}>
                  <div
                    className={`user-card d-flex align-items-center ${
                      selectedUsers.includes(user.id) ? "selected" : ""
                    }`}
                  >
                    <input
                      className="check-box"
                      type="checkbox"
                      checked={selectedUsers.includes(user.id)}
                      onChange={() => handleCheckboxChange(user.id)}
                    />
                    <img src={userImg} alt={user.name} />
                    <div className="user-details">
                      <span>
                        {user.first_name} {user.last_name}
                      </span>
                    </div>
                    {selectedUsers.includes(user.id) && (
                      <div className="select-box">
                        <Form.Control
                          as="select"
                          value={userRoles[user.id] || ""}
                          onChange={(e) =>
                            handleRoleChange(user.id, e.target.value)
                          }
                          className="select"
                        >
                          <option value="">Select Role Type</option>
                          {projectUserTypes.map((type) => (
                            <option key={type.id} value={type.id}>
                              {type.type}
                            </option>
                          ))}
                        </Form.Control>
                      </div>
                    )}
                  </div>
                  {selectedUsers.includes(user.id) && roleErrors[user.id] && (
                    <div className="text-danger">{roleErrors[user.id]}</div>
                  )}
                </div>
              ))
            ) : availableUsers.length === 0 ? (
              <div className="no-users">Users are not available</div>
            ) : (
              <React.Fragment>
                <div className="user-card">
                  <Placeholder
                    as="div"
                    bg="secondary"
                    className="checkboxHolder"
                  />
                  <Placeholder as="div" className="imageHolder" />
                  <Placeholder as="div" bg="secondary" xs={8} />
                </div>
                <div className="user-card">
                  <Placeholder
                    as="div"
                    bg="secondary"
                    className="checkboxHolder"
                  />
                  <Placeholder as="div" className="imageHolder" />
                  <Placeholder as="div" bg="secondary" xs={8} />
                </div>
                <div className="user-card">
                  <Placeholder
                    as="div"
                    bg="secondary"
                    className="checkboxHolder"
                  />
                  <Placeholder as="div" className="imageHolder" />
                  <Placeholder as="div" bg="secondary" xs={8} />
                </div>
              </React.Fragment>
            )}
          </div>
          <div className="d-flex justify-content-end mr-5">
            <Button
              onClick={() => assignUserToChat()}
              className="fs-6 text-capitalize"
              disabled={selectedUsers.length <= 0 || userAssignLoading}
            >
              {userAssignLoading && <Spinner animation="border" size="sm" />}{" "}
              Assign
            </Button>
          </div>
        </div>
      </FormModal>

      {showViewModal && (
        <ViewAttachmentModal
          fileUrl={url}
          showViewModal={showViewModal}
          closeModal={closeModal}
        />
      )}

      {showRatingModal.open && (
        <ChatRatingModal
          modalState={showRatingModal}
          toggleFormModal={modalToggleFuncRating}
          project_id={projectId}
        />
      )}
    </>
  );
};

export default Chat;
