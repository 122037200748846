import React, { useState } from "react";
import { Form, InputGroup } from "react-bootstrap";
import CustomSearchSelect from "./CustomSearchSelect";

const SearchBarSelection = ({ onChange, categoryList, handleClearSearch }) => {
  const [selectedPersonOption, setSelectedPersonOption] = useState(null);

  const handlePersonChange = (option) => {
    onChange(option.value);
    setSelectedPersonOption(option);
  };
  const getCategoryOptions = () => {
    let options = [];
    categoryList.map((item) => {
      options.push({
        value: item.id,
        label: item.name,
      });
    });
    return options;
  };
  const clearSearch = () => {
    setSelectedPersonOption(null);
    handleClearSearch();
  };
  return (
    <InputGroup className="d-flex">
      <InputGroup.Text>
        <i className="ri-search-line"></i> {/* Remix Icon */}
      </InputGroup.Text>
      <CustomSearchSelect
        options={getCategoryOptions()}
        selectedOption={selectedPersonOption}
        handleChange={handlePersonChange}
        showClearButton={true}
      />
      {selectedPersonOption && (
        <InputGroup.Text
          onClick={() => clearSearch()}
          style={{ cursor: "pointer", backgroundColor: "transparent" }}
        >
          <i className="ri-close-line"></i>
        </InputGroup.Text>
      )}
    </InputGroup>
  );
};

export default SearchBarSelection;
