import { createAsyncThunk } from "@reduxjs/toolkit";
import { get, post, put } from "../../servces/Api";
import Alert from "../../components/common/Alert";

//Fetch all tenders data
export const fetchTaskList = createAsyncThunk(
  "projectManage/taskList",
  async (_, thunkApi) => {
    try {
      const response = await post("api/load_task");
      if (response.status === 200) {
        return response.data;
      }
    } catch (error) {
      if (!error.response) {
        // network error
        return thunkApi.rejectWithValue("Network error. Please try again.");
      } else {
        // server error
        return thunkApi.rejectWithValue(
          "Failed to fetch task list. Please try again later."
        );
      }
    }
  }
);

//Create new Task
export const createTask = createAsyncThunk(
  "projectManage/createTask",
  async (taskData, { rejectWithValue }) => {
    try {
      const response = await post("/api/add_task", taskData);
      if (response.status === 200) {
        Alert.success("Your task  has been successfully created!");
        return response.data;
      }
    } catch (error) {
      if (!error.response) {
        // network error
        Alert.error("Network error. Please try again.");
      } else {
        // server error
        Alert.error("Failed to create task. Please try again later.");
      }
      return rejectWithValue(error.response.data);
    }
  }
);

//Update spesific task data
export const updateTask = createAsyncThunk(
  "projectManage/taskView",
  async (taskData, { rejectWithValue }) => {
    try {
      const response = await put("/api/update_task", taskData);
      if (response.status === 200) {
        Alert.success("Your task  has been successfully update!");
        return response.data;
      }
    } catch (error) {
      if (!error.response) {
        // network error
        Alert.error("Network error. Please try again.");
      } else {
        // server error
        Alert.error("Failed to update task. Please try again later.");
      }
      return rejectWithValue(error.response.data);
    }
  }
);

//Task status update
export const taskStatusUpdate = createAsyncThunk(
  "projectManage/taskStatusUpdate",
  async (statusData, { rejectWithValue }) => {
    try {
      const response = await post("/api/task_status_change", statusData);

      if (response.status === 200) {
        Alert.success("Your task status has been updated successfully!");
        return response;
      }
    } catch (error) {
      if (!error.response) {
        // network error
        Alert.error("Network error. Please try again.");
      } else {
        // server error
        Alert.error(
          "Failed to update your task status.Please try again later."
        );
      }
      return rejectWithValue(error.response.data);
    }
  }
);

//Fetch all tasks data
export const fetchTask = createAsyncThunk(
  "task/viewTask",
  async (_, thunkApi) => {
    try {
      const response = await get("api/view_all_task");
      if (response.status === 200) {
        return response.data;
      }
    } catch (error) {
      if (!error.response) {
        // network error
        return thunkApi.rejectWithValue("Network error. Please try again.");
      } else {
        // server error
        return thunkApi.rejectWithValue(
          "Failed to fetch task. Please try again later."
        );
      }
    }
  }
);

export const getTaskReport = createAsyncThunk(
  "projectManage/taskReportView",
  async (data) => {
    try {
      const response = await post("/api/load_task_report", data);

      if (response.error) {
        Object.values(response.error).forEach((err) => {
          Alert.error("Error Get Report ");
          return false;
        });
      } else {
        if (response.status === 200) {
          if (response.data.length > 0) {
            return response.data;
          }
          Alert.error("No Records Found");
          return false;
        } else {
          Alert.error("Error Get Report");
          return false;
        }
      }
    } catch (error) {
      Alert.error("Error Get Report");
      return false;
    }
  }
);
export const getFiltersByPerson = createAsyncThunk(
  "projectManage/taskFilterByPerson",
  async (data) => {
    try {
      const response = await post("/api/filter_tasks", data);

      if (response.error) {
        Object.values(response.error).forEach((err) => {
          Alert.error("Error Task ");
          return false;
        });
      } else {
        if (response.status === 200) {
          if (response.data) {
            return response.data;
          }
          Alert.error("No Records Found");
          return false;
        } else {
          Alert.error("Error Get Task");
          return false;
        }
      }
    } catch (error) {
      Alert.error("Error Get Task");
      return false;
    }
  }
);

export const getSearchProjects = createAsyncThunk(
  "projectManage/searchProjects",
  async (data) => {
    try {
      const response = await get(
        `/api/search_tasks?search_task=${data.search_task}`
      );

      if (response.error) {
        Object.values(response.error).forEach((err) => {
          Alert.error("Error task search");
          return false;
        });
      } else {
        if (response.status === 200) {
          if (response.data) {
            return response.data;
          }
          Alert.error("No Records Found");
          return false;
        } else {
          Alert.error("Error get task search");
          return false;
        }
      }
    } catch (error) {
      Alert.error("Error get task search");
      return false;
    }
  }
);
