import React from "react";
import MenuBar from "../components/home/MenuBar";
import AboutUsBanner from "../assets/img/about-us-header.jpg";
import Footer from "../components/home/Footer";
import "../assets/styles/pages/AboutUs.scss";
import { useNavigate } from "react-router-dom";
import topImg from "../assets/img/about-us-top.jpg";
import bottomImg1 from "../assets/img/bottom-img-1.png";
import bottomImg2 from "../assets/img/bottom-img-2.png";
import bottomImg3 from "../assets/img/bottom-img-3.png";

function AboutUsPage() {
    const navigate = useNavigate();

    return (
        <div className="page-home">
            <MenuBar />
            <div className="position-relative" style={{ height: '400px', marginTop: '-137px' }}>
                <div
                    className="position-absolute top-0 start-0 w-100 h-100"
                    style={{
                        backgroundImage: `url(${AboutUsBanner})`,
                        backgroundSize: 'cover',

                    }}
                ></div>
                <div
                    className="position-absolute top-0 start-0 w-100 h-100"
                    style={{ backgroundColor: 'rgba(0, 0, 0, 0.5)' }}
                ></div>
                <div className="position-relative d-flex justify-content-center align-items-center w-100 h-100 pt-5" style={{ zIndex: 1 }}>
                    <h1 className="text-white fw-bold">About Us</h1>
                </div>
            </div>
            <div className="work-preference pd-top-60 pd-bottom-60 bg-light-gray">
                <div className="container">
                    <div className="container-top" style={{ backgroundImage: `url(${topImg})` }}>
                        <div className="content">
                            <button className="custom-button">Our story</button>
                            <div className="text-content">
                                <h2 className="topic">Working at Abdoit</h2>
                                <p className="description">
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                                    incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
                                    exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure
                                    dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.
                                    Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit
                                    anim id est laborum
                                </p>
                            </div>
                            <button className="custom-button">Our Products</button>
                        </div>
                    </div>
                    <div className="bottom-first-container">
                        <p className="topic">Our core values</p>
                        <div className="inner-bottom">
                            <div className="image-container" style={{ backgroundImage: `url(${bottomImg1})` }}></div>
                            <div className="text-container" style={{ flex: '2', padding: '20px' }}>
                                <p className="">
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
                                    labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris
                                    nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit
                                    esse cillum dolore eu fugiat nulla pariatur.
                                    Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                                </p>
                                <p className="">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt
                                    ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris
                                    nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse
                                    cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui
                                    officia deserunt mollit anim id est laborum.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="bottom-second-container">
                        <p className="topic">Community</p>
                        <div className="inner-bottom">
                            <div className="text-container" style={{ flex: '2', padding: '20px' }}>
                                <p className="">
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
                                    labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris
                                    nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit
                                    esse cillum dolore eu fugiat nulla pariatur.
                                    Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                                </p>
                                <p className="">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt
                                    ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris
                                    nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse
                                    cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui
                                    officia deserunt mollit anim id est laborum.
                                </p>
                            </div>
                            <div className="image-container" style={{ backgroundImage: `url(${bottomImg2})` }}></div>
                        </div>
                    </div>
                    <div className="bottom-first-container">
                        <p className="topic">Innovation</p>
                        <div className="inner-bottom">
                            <div className="image-container" style={{ backgroundImage: `url(${bottomImg3})` }}></div>
                            <div className="text-container" style={{ flex: '2', padding: '20px' }}>
                                <p className="">
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
                                    labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris
                                    nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit
                                    esse cillum dolore eu fugiat nulla pariatur.
                                    Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                                </p>
                                <p className="">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt
                                    ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris
                                    nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse
                                    cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui
                                    officia deserunt mollit anim id est laborum.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default AboutUsPage