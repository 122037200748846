import axios from "./axiosConfig";
import Cookies from "js-cookie";

// Common function for making GET requests
export async function get(endpoint) {
  try {
    const response = await axios.get(endpoint);
    return response;
  } catch (error) {
    // Handle error
    console.error("Error making GET request:", error);
    return error;
  }
}

export async function post(endpoint, data, includeToken = true) {
  try {
    let headers = {
      "Content-Type": "application/json",
      Accept: "application/json",
      "Accept-Language": "fa",
    };

    if (includeToken) {
      const token = Cookies.get("userToken") || null;
      if (token) {
        headers["Authorization"] = `Bearer ${token}`;
      }
    }
    const response = await axios.post(endpoint, data, { headers });
    return response;
  } catch (error) {
    // Handle error
    console.error("Error making POST request:", error);
    throw error;
  }
}

// Common function for making PUT requests
export async function put(endpoint, data) {
  try {
    const response = await axios.put(endpoint, data);
    return response;
  } catch (error) {
    // Handle error
    console.error("Error making PUT request:", error);
    throw error;
  }
}

// Common function for making DELETE requests
export async function remove(endpoint) {
  try {
    const response = await axios.delete(endpoint);
    return response;
  } catch (error) {
    // Handle error
    console.error("Error making DELETE request:", error);
    throw error;
  }
}

// Common function for uploading files
export async function uploadFile(endpoint, file) {
  try {
    const formData = new FormData();
    formData.append("file", file);
    const response = await axios.post(endpoint, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return response;
  } catch (error) {
    // Handle error
    console.error("Error uploading file:", error);
    throw error;
  }
}

// Common function for fetching files
export async function fetchFile(endpoint) {
  try {
    const response = await axios.get(endpoint, {
      responseType: "blob",
    });
    return response;
  } catch (error) {
    // Handle error
    console.error("Error fetching file:", error);
    throw error;
  }
}

// Common function for deleting table data
export async function deleteTableData(tableId) {
  try {
    // Add logic for deleting table data
    // ...
  } catch (error) {
    // Handle error
    console.error("Error deleting table data:", error);
    throw error;
  }
}

export async function upload(url, data) {
  try {
    if (data.entity) {
      data = data.entity;
    }
    const res = await axios.post(url, data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return res;
  } catch (err) {
    return err;
  }
}

export async function getApiQuotationCustomer(endpoint) {
  try {
    let headers = {
      "Content-Type": "application/json",
      Accept: "application/json",
      "Accept-Language": "fa",
    };

    const response = await axios.get(endpoint, { headers });
    return response;
  } catch (error) {
    // Handle error
    console.error("Error making GET request:", error);
    return error;
  }
}

export async function acceptQuotation(endpoint) {
  try {
    let headers = {
      "Content-Type": "application/json",
      Accept: "application/json",
      "Accept-Language": "fa",
    };

    const response = await axios.put(endpoint, { headers });
    return response;
  } catch (error) {
    // Handle error
    console.error("Error making PUT request:", error);
    return error;
  }
}
