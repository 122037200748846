import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Button } from "react-bootstrap";
import "../../assets/styles/components/common/CardList.scss";

const CardList = ({
  items,
  listTitle,
  ItemsPerPage,
}) => {
  const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState(0);
  const [searchText, setSearchText] = useState("");
  const totalPages = Math.ceil(items.length / ItemsPerPage);

  const handlePrevPage = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 0));
  };

  const handleNextPage = () => {
    setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages - 1));
  };

  const filteredItems = items.filter((item) =>
    item.title.toLowerCase().includes(searchText.toLowerCase())
  );

  const startIndex = currentPage * ItemsPerPage;
  const endIndex = startIndex + ItemsPerPage;
  const currentItems = filteredItems.slice(startIndex, endIndex);

  return (
    <>
      <div className="template-area pd-top-60 pd-bottom-60">
        <div className="container">
          <div className="row align-items-end mb-5">
            <div className="col col-md-8 col-sm-12">
              <div className="section-title">
                <h2 className="title">{ listTitle }</h2>
              </div>
            </div>

            <div className="col col-md-4 col-sm-12  pl-0">
              <div className="search-box d-flex justify-content-end mb-1">
                <div className="input-group">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Search"
                    value={ searchText }
                    onChange={ (e) => setSearchText(e.target.value) }
                  />
                  <button className="btn btn-primary mt-0">Search</button>
                </div>
              </div>
            </div>
          </div>
          { currentItems.length === 0 ? (
            <div className="col-md-12 text-center bg-light-gray p-5 no-result">
              Sorry, no results found for your search. Please try a different
              query or refine your search terms.
            </div>
          ) : (
            <div className="row d-flex justify-content-between">
              { currentItems.map((item, index) => (
                <div key={ index } md={ 4 } className="col-md-4">
                  <div className="card mb-3 shadow m-auto mw-100">
                    <img className="card-img-top" src={ item.image } alt="" />
                    <div className="card-body">
                      <div className="card-title">{ item.title }</div>
                      <div className="card-text mb-4">{ item.description }</div>
                      <div className="d-flex  gap-2">
                        <Button className="w-100" variant="primary">
                          View More
                        </Button>

                        <Button className="w-100" variant="outline-primary">
                          Manage
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              )) }
              <div className="d-flex justify-content-center mt-3 gap-3">
                <Button
                  variant="outline-primary"
                  onClick={ handlePrevPage }
                  disabled={ currentPage === 0 }
                >
                  <i className="ri-arrow-left-s-line"></i> Previous
                </Button>
                <Button
                  variant="outline-primary"
                  onClick={ handleNextPage }
                  disabled={ currentPage === totalPages - 1 }
                >
                  Next <i className="ri-arrow-right-s-line"></i>
                </Button>
              </div>
            </div>
          ) }
        </div>
      </div>
    </>
  );
};

export default CardList;
