import CardImage1 from "../assets/img/home_card/1.jpg";
import CardImage2 from "../assets/img/home_card/2.jpg";
import CardImage3 from "../assets/img/home_card/3.jpg";
import CardImage4 from "../assets/img/home_card/4.jpg";
import CardImage5 from "../assets/img/home_card/5.jpg";
import CardImage6 from "../assets/img/home_card/6.jpg";
import CardImage7 from "../assets/img/home_card/7.jpg";
import CardImage8 from "../assets/img/home_card/8.jpg";
import CardImage9 from "../assets/img/home_card/9.jpg";
import CardImage10 from "../assets/img/home_card/10.jpg";
import UserImage from "../assets/img/user.png";

const items = [
  {
    title: "Content Creation",
    description:
      "Craft compelling and shareable content to connect with your target audience.",
    image: CardImage1,
  },
  {
    title: "Tech Support Services",
    description:
      "Reliable technical support to keep your systems running smoothly.",
    image: CardImage2,
  },
  {
    title: "Creative Design",
    description:
      "Bring your ideas to life with our innovative design solutions.",
    image: CardImage3,
  },
  {
    title: "Product Showcase",
    description:
      "Present your products in a captivating and visually appealing way.",
    image: CardImage4,
  },
  {
    title: "Digital Marketing",
    description:
      "Boost your online presence with our effective digital marketing strategies.",
    image: CardImage5,
  },
  {
    title: "Responsive Web Development",
    description:
      "Crafting websites that adapt seamlessly to various devices and screen sizes.",
    image: CardImage6,
  },
  {
    title: "E-commerce Solutions",
    description:
      "Build a successful online store with our powerful e-commerce solutions.",
    image: CardImage7,
  },
  {
    title: "Social Media Management",
    description:
      "Enhance your brand visibility and engagement through strategic social media management.",
    image: CardImage8,
  },
  {
    title: "Data Analytics",
    description:
      "Gain valuable insights from your data with our advanced analytics services.",
    image: CardImage9,
  },
  {
    title: "Mobile App Development",
    description:
      "Create cutting-edge mobile applications for iOS and Android platforms.",
    image: CardImage10,
  },
  // ... Add more items here
];

const feedbackData = [
  {
    id: "1",
    userName: "Devon Lane",
    rating: 5,
    feedbackText:
      "Great service! Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC",
    userProfileImage: UserImage,
    dateAdded: "October 1, 2023",
  },
  {
    id: "2",
    userName: "Devon Lane",
    rating: 2,
    feedbackText:
      "Great service! Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC",
    userProfileImage: UserImage,
    dateAdded: "October 1, 2023",
  },
  {
    id: "3",
    userName: "Devon Lane",
    rating: 3,
    feedbackText:
      "Excellent product! Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC",
    userProfileImage: UserImage,
    dateAdded: "October 2, 2023",
  },
  {
    id: "4",
    userName: "Devon Lane",
    rating: 5,
    feedbackText:
      "Excellent product! Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC",
    userProfileImage: UserImage,
    dateAdded: "October 2, 2023",
  },
  {
    id: "5",
    userName: "Devon Lane",
    rating: 4,
    feedbackText:
      "Excellent product! Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC",
    userProfileImage: UserImage,
    dateAdded: "October 2, 2023",
  },
  // Add more feedback items as needed
];

const PricePackageData = [
  {
    id: "1",
    pakageName: "Start Free Trial",
    packageInfo: {
      price: "0",
      userCount: "Up to 2 users",
      supportType: "Basic support on Github",
      updates: "Monthly updates",
      cancelation: "Free cancelation",
    },
    popular: false,
  },
  {
    id: "2",
    pakageName: "Starter",
    packageInfo: {
      price: "10",
      userCount: "Up to 5 users",
      supportType: "Basic support on Github",
      updates: "Monthly updates",
      cancelation: "Free cancelation",
    },
    popular: false,
  },
  {
    id: "3",
    pakageName: "Business",
    packageInfo: {
      price: "50",
      userCount: "Up to 50 users",
      supportType: "Basic support on Github",
      updates: "Monthly updates",
      cancelation: "Free cancelation",
    },
    popular: true,
  },
  {
    id: "4",
    pakageName: "Enterprise",
    packageInfo: {
      price: "120",
      userCount: "Unlimited",
      supportType: "Advanced support on Github",
      updates: "Monthly updates",
      cancelation: "Free cancelation",
    },
    popular: false,
  },
];

const TermsAndConditionsData = [
  {
    id: "1",
    name: "Ownership of Site; Agreement to Terms of Use",
    desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum",
  },
  {
    id: "2",
    name: "Privacy",
    desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum",
  },
  {
    id: "3",
    name: "Disclaimers",
    desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum",
  },
  {
    id: "4",
    name: "Violation of These Terms of Use",
    desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum",
  },
  {
    id: "5",
    name: "Governing Law; Dispute Resolution",
    desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum",
  },{
    id: "6",
    name: "Miscellaneous",
    desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum",
  },
]

const tabsData = [
  {
    key: 'tab1',
    title: 'Privacy Policy',
    description: 'Updated March 31, 2024',
    content: [
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum', 
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum', 
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum',
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum',
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum',
    ],
  },
  {
    key: 'tab2',
    title: 'Family Sharing Disclosure',
    description: 'Updated March 31, 2024',
    content: ['Paragraph 1 of Tab 2', 'Paragraph 2 of Tab 2', 'Paragraph 3 of Tab 2'],
  },
  {
    key: 'tab3',
    title: 'Privacy Governance',
    description: 'Updated March 31, 2024',
    content: ['Paragraph 1 of Tab 3', 'Paragraph 2 of Tab 3', 'Paragraph 3 of Tab 3'],
  },
  {
    key: 'tab4',
    title: 'Government Info',
    description: 'Updated March 31, 2024',
    content: ['Paragraph 1 of Tab 4', 'Paragraph 2 of Tab 4', 'Paragraph 3 of Tab 4'],
  },
  {
    key: 'tab5',
    title: 'Your Details',
    description: 'Updated March 31, 2024',
    content: ['Paragraph 1 of Tab 5', 'Paragraph 2 of Tab 5', 'Paragraph 3 of Tab 5'],
  },
];

export { items, feedbackData, PricePackageData, TermsAndConditionsData, tabsData };
