import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import { Alert, Button } from "react-bootstrap";
import InfiniteScroll from "react-infinite-scroll-component";
import styles from "../../assets/styles/components/tender-list/TenderComment.module.scss";
import userAvatar from "../../assets/img/user.png";
import CommentInput from "./CommentInput";
import { getTenderComments } from "../../store/actions/tenderActions";
import TenderCommentReplyLoading from "../../components/common/TenderCommentReplyLoading";

const TenderCommentSection = ({ tenderId }) => {
  const dispatch = useDispatch();
  const { tenderCommentsList, tenderCommentsListLoading } = useSelector(
    (state) => state.tender
  );
  const UserData = useSelector((state) => state.auth.userInfo);
  const UserId = UserData.id;

  const [isShowReplyBox, setIsShowReplyBox] = useState(false);
  const [selectedCommentId, setSelectedCommentId] = useState("0");
  const [parantReplyId, setParantReplyId] = useState("0");
  const [editId, setEditId] = useState("0");
  const [editText, setEditText] = useState("");
  const [hasMore, setHasMore] = useState(true);
  const [page, setPage] = useState(1);
  const [items, setItems] = useState([]);
  const [isShowMore, setIsShowMore] = useState(false);
  const per_page = 10;
  let newTenderID = "";

  useEffect(() => {
    if (tenderId && tenderId !== newTenderID) {
      newTenderID = tenderId;
      setItems([]);
      const data = {
        page: 1,
        per_page: 1,
        tender_id: tenderId,
      };
      getRecentComments(data);
    }
  }, []);

  useEffect(() => {
    if (isShowMore) {
      if (tenderCommentsList?.comments) {
        setItems(tenderCommentsList?.comments);
        if (isShowReplyBox) {
          setIsShowReplyBox(false);
        }
      }
    } else {
      if (tenderCommentsList?.comments) {
        setItems(tenderCommentsList?.comments.slice(0, 1));
      }
    }
  }, [tenderCommentsList, isShowMore]);

  const getRecentComments = (data) => {
    if (tenderId) {
      dispatch(getTenderComments(data)).then((result) => {
        if (getTenderComments.fulfilled.match(result)) {
          if (result?.payload?.comments?.length > 0) {
            setItems((prevItems) => [
              ...prevItems,
              ...result?.payload?.comments,
            ]);
            if (
              result?.payload?.pagination?.current_page ===
              result?.payload?.pagination?.total_pages
            ) {
              setHasMore(false);
            }
          } else {
            setHasMore(false);
          }
        } else if (getTenderComments.rejected.match(result)) {
          console.error("Failed to fetch tender comments:");
        }
      });
    }
  };

  const fetchMoreData = () => {
    const data = {
      page: page,
      per_page: per_page,
      tender_id: tenderId,
    };
    getRecentComments(data);
    setPage((prevPage) => prevPage + 1);
  };

  const showMoreComments = () => {
    setIsShowMore(true);
  };

  const showCommentInput = (id) => {
    setEditId("0");
    setEditText("");
    if (selectedCommentId === "0" || selectedCommentId === id) {
      setIsShowReplyBox(true);
    }
  };

  const removeDuplicates = (array) => {
    const seen = new Set();
    return array.filter((item) => {
      const duplicate = seen.has(item.id);
      seen.add(item.id);
      return !duplicate;
    });
  };

  return tenderCommentsListLoading && !isShowMore ? (
    <TenderCommentReplyLoading />
  ) : (
    <>
      <div className={ styles['comment-banner'] }>
        <div>Get know more about tender</div>
        <div className={ styles['right-container'] }>
          <i style={ { fontSize: "20px", color: "#ffffff" } } className="ri-chat-4-line"></i>
          <div><span>{ tenderCommentsList.count ? tenderCommentsList.count : "" }</span> Comments</div>
        </div>
      </div>
      <CommentInput tenderId={ tenderId } />
      <div className={ `${styles['container']} mt-4` }>
        { removeDuplicates(items).length > 0 ? (
          <>
            { removeDuplicates(items).map((comment) => (
              <div className={ styles.comment } key={ comment.id }>
                <div className="row align-items-center">
                  <div className="col-auto">
                    <img
                      src={ userAvatar }
                      alt="User Avatar"
                      className="rounded-circle"
                      width={ 50 }
                      height={ 50 }
                    />
                  </div>
                  <div className="col">
                    <div className="d-flex flex-column">
                      <span className="font-weight-bold">
                        { comment?.user?.first_name } { comment?.user?.last_name }
                      </span>
                      <span className="text-muted">
                        { moment(comment?.created_at).format(
                          "DD MMM YYYY hh:mm A"
                        ) }
                      </span>
                    </div>
                  </div>
                </div>
                <div className="mt-3">
                  <p className={ styles.question_text }>{ comment?.question }</p>
                  <div className="d-flex mb-3">
                    { UserId === comment?.user?.id && (
                      <button
                        className={ `btn btn-link ${styles.btn_link}` }
                        onClick={ () => {
                          setSelectedCommentId(comment?.id);
                          setEditId(comment?.id);
                          setEditText(comment?.question);
                          setParantReplyId("0");
                          setIsShowReplyBox(true);
                        } }
                      >
                        Edit
                      </button>
                    ) }

                    { comment?.is_close === 0 && (
                      <button
                        className="btn btn-link"
                        onClick={ () => {
                          setSelectedCommentId(comment?.id);
                          showCommentInput(comment?.id);
                          setParantReplyId("0");
                        } }
                      >
                        Reply
                      </button>
                    ) }
                  </div>

                  { comment?.details?.length > 0 &&
                    comment?.details.map((det) => (
                      <React.Fragment key={ det?.id }>
                        { det.parent_reply_id === 0 && (
                          <div className={ styles.reply }>
                            <div className="row align-items-center">
                              <div className="col-auto">
                                <img
                                  src={ userAvatar }
                                  alt="User Avatar"
                                  className="rounded-circle"
                                  width={ 40 }
                                  height={ 40 }
                                />
                              </div>
                              <div className="col">
                                <div className="d-flex flex-column">
                                  <span className="font-weight-bold">
                                    { det?.user?.first_name }{ " " }
                                    { det?.user?.last_name }
                                  </span>
                                  <span className="text-muted">
                                    { moment(det?.created_at).format(
                                      "DD MMM YYYY hh:mm A"
                                    ) }
                                  </span>
                                </div>
                              </div>
                            </div>
                            <div className="mt-2">
                              <p>{ det?.answer }</p>
                              <div className="d-flex">
                                { UserId === det?.user?.id && (
                                  <button
                                    className={ `btn btn-link ${styles.btn_link}` }
                                    onClick={ () => {
                                      setSelectedCommentId(comment?.id);
                                      setEditId(det?.id);
                                      setEditText(det?.answer);
                                      setParantReplyId(det?.id);
                                      setIsShowReplyBox(true);
                                    } }
                                  >
                                    Edit
                                  </button>
                                ) }
                                { comment?.is_close === 0 && (
                                  <button
                                    className={ `btn btn-link ${styles.btn_link}` }
                                    onClick={ () => {
                                      setSelectedCommentId(comment?.id);
                                      showCommentInput(det?.id);
                                      setParantReplyId(det?.id);
                                    } }
                                  >
                                    Reply
                                  </button>
                                ) }
                              </div>
                            </div>
                          </div>
                        ) }

                        {/* Child Replies */ }
                        <div className={ `mb-3 ${styles.child_reply}` }>
                          { det?.replies?.length > 0 &&
                            det?.replies?.map((rep) => (
                              <div key={ rep.id }>
                                <div className="row align-items-center">
                                  <div className="col-auto">
                                    <img
                                      src={ userAvatar }
                                      alt="User Avatar"
                                      className="rounded-circle"
                                      width={ 30 }
                                      height={ 30 }
                                    />
                                  </div>
                                  <div className="col">
                                    <div className="d-flex flex-column">
                                      <span className="font-weight-bold">
                                        { rep?.user?.first_name }{ " " }
                                        { rep?.user?.last_name }
                                      </span>
                                      <span className="text-muted">
                                        { moment(rep?.created_at).format(
                                          "DD MMM YYYY hh:mm A"
                                        ) }
                                      </span>
                                    </div>
                                  </div>
                                </div>
                                <div className="mt-2">
                                  <p>{ rep?.answer }</p>
                                  { UserId === rep?.user?.id && (
                                    <div className="d-flex">
                                      <button
                                        className={ `btn btn-link ${styles.btn_link}` }
                                        onClick={ () => {
                                          setSelectedCommentId(comment?.id);
                                          setEditId(rep?.id);
                                          setEditText(rep?.answer);
                                          setParantReplyId(rep?.parent_reply_id);
                                          setIsShowReplyBox(true);
                                        } }
                                      >
                                        Edit
                                      </button>
                                    </div>
                                  ) }
                                </div>
                              </div>
                            )) }
                        </div>
                      </React.Fragment>
                    )) }
                  <div className="mb-3">
                    { isShowReplyBox && selectedCommentId === comment?.id && (
                      <CommentInput
                        type="reply"
                        isFocus={ isShowReplyBox }
                        comment_id={ selectedCommentId }
                        parant_reply_id={ parantReplyId }
                        editId={ editId }
                        editText={ editText }
                        tenderId={ tenderId }
                        commentOwnerId={ comment?.user?.id }
                      />
                    ) }
                  </div>
                </div>
              </div>
            )) }
            { !isShowMore && tenderCommentsList.count > 1 && items.length > 0 && (
              <div className={ styles['showmore-btn'] }>
                <Button variant="link" onClick={ showMoreComments }>
                  View All Comments
                </Button>
              </div>
            ) }
            { isShowMore && hasMore && (
              <InfiniteScroll
                dataLength={ items.length }
                next={ fetchMoreData }
                hasMore={ hasMore }
                loader={ <TenderCommentReplyLoading /> }
                endMessage={
                  <p style={ { textAlign: "center" } }>
                    <b>You have seen it all</b>
                  </p>
                }
              />
            ) }
          </>
        ) : (
          <div className="people-body">
            <Alert
              variant="primary"
              className="d-flex align-items-center mt-2 mb-2"
            >
              <small>There are no comments at the moment !</small>
            </Alert>
          </div>
        ) }
      </div>
    </>
  );
};

export default TenderCommentSection;
