import React from "react";
import { Row, Form } from "react-bootstrap";
import { useFormContext } from "react-hook-form";
import UploadFile from "../common/UploadFile";

const DeliveryInfoForm = () => {
  const {
    register,
    formState: { errors },
    setValue,
  } = useFormContext();

  const setFileName = (fileName) => {
    setValue("file_name", fileName);
  };
  return (
    <>
      <Row>
        <div className="col-md-12 mb-3">
          <div className="form-group">
            <Form.Label>
              <span><i style={ { color: "#ff3300", fontSize: "10px" } } className="ri-asterisk"></i></span> Register Number
            </Form.Label>
            <Form.Control
              type="text"
              className="form-input"
              { ...register("com_reg_no", {
                required: "Company register number is required",
              }) }
            />
            { errors.com_reg_no && (
              <small className="text-danger">{ errors.com_reg_no.message }</small>
            ) }
          </div>
        </div>
      </Row>
      <Row>
        <UploadFile
          { ...register("file_name", {
            required: "File is required",
          }) }
          setFileName={ setFileName }
          uploadInfo="[Company ID /  NIC / Driven Licences - Max file size 4Mb]"
        />
        { errors.file_name && (
          <small className="text-danger">{ errors.file_name.message }</small>
        ) }
      </Row>
    </>
  );
};

export default DeliveryInfoForm;
