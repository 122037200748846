import React, { useState } from "react";
import { Button, Row, Form, Col } from "react-bootstrap";
import { useDispatch } from "react-redux";
import Rating from "react-rating-stars-component";
import { useForm, Controller } from "react-hook-form";
import FormModal from "../common/FormModal";
import { saveFeedbackData } from "../../store/actions/feedbackAction";
import style from "../../assets/styles/components/home/feedbackForm.module.scss";

const FeedbackForm = ({ feedbackDetailsLoading }) => {
  const [showModalState, setShowModalState] = useState(false);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const [startRate, setStartRate] = useState(0);
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    control,
  } = useForm();

  const toggleFormModal = () => {
    setShowModalState(!showModalState);
  };

  const ratingChanged = (newRating) => {
    setStartRate(newRating);
  };

  const onSubmit = (data) => {
    try {
      setLoading(true);
      dispatch(saveFeedbackData(data));
      reset();
      setStartRate(0); // Reset the rating
      setTimeout(() => {
        setLoading(false);
      }, 1000);
      toggleFormModal();
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <div className="row">
        <div className="text-center mt-2">
          <button
            type="button"
            className="btn btn-primary feed-btn"
            onClick={ toggleFormModal }
          >
            Add Your Feedback
          </button>
        </div>
      </div>
      <FormModal
        moduleName={ "Feedback" }
        modalState={ showModalState }
        toggleFormModal={ toggleFormModal }
        width="600px"
      >
        <form onSubmit={ handleSubmit(onSubmit) } className={ style.feedback_form }>
          <Row>
            <div className="mb-3">
              <div className="form-group">
                <Form.Label><span><i style={ { color: "#ff3300", fontSize: "10px" } } className="ri-asterisk"></i></span> Full Name</Form.Label>
                <Form.Control
                  type="text"
                  className="form-input"
                  { ...register("full_name", {
                    required: "Full name is required",
                  }) }
                />
                { errors.full_name && (
                  <small className="text-danger">
                    { errors.full_name.message }
                  </small>
                ) }
              </div>
            </div>
            <div className="mb-3">
              <div className="form-group">
                <Form.Label><span><i style={ { color: "#ff3300", fontSize: "10px" } } className="ri-asterisk"></i></span> Email</Form.Label>
                <Form.Control
                  type="email"
                  className="form-input"
                  { ...register("email", {
                    required: "Email is required",
                    pattern: {
                      value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                      message: "Invalid email format",
                    },
                  }) }
                />
                { errors.email && (
                  <small className="text-danger">{ errors.email.message }</small>
                ) }
              </div>
            </div>
            <div className="mb-3">
              <div className="form-group">
                <Form.Label><span><i style={ { color: "#ff3300", fontSize: "10px" } } className="ri-asterisk"></i></span> Rating</Form.Label>

                <Controller
                  control={ control }
                  name="rate"
                  rules={ {
                    required: "Rating is required.",
                  } }
                  render={ ({ field }) => (
                    <Rating
                      value={ field.value }
                      count={ 5 }
                      size={ 40 }
                      onChange={ (rate) => field.onChange(rate) }
                      activeColor="#ffa534"
                    />
                  ) }
                />
                { errors.rate && (
                  <small className="text-danger">{ errors.rate.message }</small>
                ) }
              </div>
            </div>
            <div className="mb-3">
              <div className="form-group">
                <Form.Label><span><i style={ { color: "#ff3300", fontSize: "10px" } } className="ri-asterisk"></i></span> Share your experience in scaling</Form.Label>
                <Form.Control
                  as="textarea"
                  rows="4"
                  style={ { height: 100 } }
                  { ...register("q_1", { required: "Feild is required" }) }
                ></Form.Control>
                { errors.q_1 && (
                  <small className="text-danger">{ errors.q_1.message }</small>
                ) }
              </div>
            </div>
            <div className="mb-3">
              <div className="form-group">
                <Form.Label>
                  <span><i style={ { color: "#ff3300", fontSize: "10px" } } className="ri-asterisk"></i></span> Are you satisfied with the order/quoting process in abdolit ? If not, please explain why.
                </Form.Label>
                <Form.Control
                  type="text"
                  className="form-input"
                  { ...register("q_2", { required: "Feild is required" }) }
                />
                { errors.q_2 && (
                  <small className="text-danger">{ errors.q_2.message }</small>
                ) }
              </div>
            </div>
            <div className="mb-3">
              <div className="form-group">
                <Form.Label>
                  <span><i style={ { color: "#ff3300", fontSize: "10px" } } className="ri-asterisk"></i></span> Have you found it easy to track project progress using abdolit ? If not, please provide feedback.
                </Form.Label>
                <Form.Control
                  type="text"
                  className="form-input"
                  { ...register("q_3", { required: "Feild is required" }) }
                />
                { errors.q_3 && (
                  <small className="text-danger">{ errors.q_3.message }</small>
                ) }
              </div>
            </div>
            <div className="mb-3">
              <div className="form-group">
                <Form.Label>
                  <span><i style={ { color: "#ff3300", fontSize: "10px" } } className="ri-asterisk"></i></span> Are there any improvements you would like to see in the change request workflow?
                </Form.Label>
                <Form.Control
                  type="text"
                  className="form-input"
                  { ...register("q_4", { required: "Feild is required" }) }
                />
                { errors.q_4 && (
                  <small className="text-danger">{ errors.q_4.message }</small>
                ) }
              </div>
            </div>
            <div className="mb-3">
              <div className="form-group">
                <Form.Label>
                  <span><i style={ { color: "#ff3300", fontSize: "10px" } } className="ri-asterisk"></i></span>Is there anything else you'd like to share about abdolit , such as specific challenges you've faced or suggestions for enhancements?
                </Form.Label>
                <Form.Control
                  type="text"
                  className="form-input"
                  { ...register("q_5", { required: "Feild is required" }) }
                />
                { errors.q_5 && (
                  <small className="text-danger">{ errors.q_5.message }</small>
                ) }
              </div>
            </div>
            <div className="mb-3">
              <div className="form-group">
                <Form.Label>
                  <span><i style={ { color: "#ff3300", fontSize: "10px" } } className="ri-asterisk"></i></span>Would you recommend abdolit to others in your industry? Why or why not?
                </Form.Label>
                <Form.Control
                  type="text"
                  className="form-input"
                  { ...register("q_6", { required: "Feild is required" }) }
                />
                { errors.q_6 && (
                  <small className="text-danger">{ errors.q_6.message }</small>
                ) }
              </div>
            </div>
            <div className="mb-3">
              <div className="form-group">
                <Form.Label><span><i style={ { color: "#ff3300", fontSize: "10px" } } className="ri-asterisk"></i></span> How long have you been using abdolit?</Form.Label>
                <Form.Control
                  type="text"
                  className="form-input"
                  { ...register("q_7", { required: "Feild is required" }) }
                />
                { errors.q_7 && (
                  <small className="text-danger">{ errors.q_7.message }</small>
                ) }
              </div>
            </div>
            <div className="mb-3">
              <div className="form-group">
                <Form.Label>
                  <span><i style={ { color: "#ff3300", fontSize: "10px" } } className="ri-asterisk"></i></span> Please rate your overall satisfaction with abdolit on a scale of 1 to 10{ " " }
                  <small>
                    (1 being very dissatisfied and 10 being very satisfied)
                  </small>
                  .
                </Form.Label>
                <Form.Control
                  type="number"
                  className="form-input"
                  { ...register("q_8", {
                    required: "Feild is required",
                    max: { value: 10, message: "Maximum value is 10" },
                  }) }
                />
                { errors.q_8 && (
                  <small className="text-danger">{ errors.q_8.message }</small>
                ) }
              </div>
            </div>
          </Row>
          <Row className="g-2 mt-2">
            <Col>
              <Button
                type="submit"
                className="btn-sign w-100"
                disabled={ loading }
              >
                { loading ? (
                  <span
                    className="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>
                ) : (
                  "Submit"
                ) }
              </Button>
            </Col>
            <Col>
              <Button
                type="button"
                variant="outline-primary"
                className="btn-sign w-100"
                onClick={ toggleFormModal }
              >
                Close
              </Button>
            </Col>
          </Row>
        </form>
      </FormModal>
    </>
  );
};

export default FeedbackForm;
