import React, { useState, useEffect } from 'react'
import { Button, Form, Row, Tooltip, OverlayTrigger, Spinner, Dropdown } from "react-bootstrap";
import { Controller, useForm, useWatch } from 'react-hook-form';
import ReactDatePicker from 'react-datepicker';
import { CustomInput } from '../../utils/utils';
import PaginationWithDataTable from '../common/PagintionWithDataTable';
import FormModal from '../common/FormModal';
import PerfectScrollbar from "react-perfect-scrollbar";
import { post, get } from '../../servces/Api';
import Alert from '../common/Alert';
import "../../assets/styles/components/common/GeneralFeedbackReports.scss";
import NFSLogoDark from "../../assets/img/icon.png";
import HeaderImg from "../../assets/img/default-banner-img.jpg";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import * as XLSX from 'xlsx';
import { RatingTypes } from '../../utils/types';
import { useDispatch } from 'react-redux';
import { fetchCompanyProjectListData, fetchInitialCustomerFeedbacksData, filterCustomerFeedbacksData } from '../../store/actions/reportActions';

function CustomerFeedbackReport() {
  const moment = require("moment");
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false)
  const [feedbackData, setFeedbackData] = useState([]);
  const [projectData, setProjectData] = useState([]);
  const [taskData, setTaskData] = useState([]);
  const [viewMoreModal, setViewMoreModal] = useState({ open: false, data: [] });
  const [generateButtonLoading, setGenerateButtonLoading] = useState(false);

  const [fromChanged, setFromChanged] = useState(false);
  const [toChanged, setToChanged] = useState(false);

  const [changedFrom, setChangedFrom] = useState(false);
  const [changedTo, setChangedTo] = useState(false);

  const thirtyDaysAgo = moment().subtract(30, 'days').toDate();
  const today = moment().toDate();

  const [maxDateFrom, setMaxDateFrom] = useState(today);
  const [minDateFrom, setMinDateFrom] = useState(null);
  const [maxDateTo, setMaxDateTo] = useState(today);
  const [minDateTo, setMinDateTo] = useState(null);

  const { register, setValue, handleSubmit, reset, control, formState: { errors }, watch } = useForm({
    defaultValues: {
      rate: "",
      project_id: "",
      from: thirtyDaysAgo,
      to: today
    }
  });

  const [selectedReport, setSelectedReport] = useState('Generate Report');

  const handleSelect = (eventKey) => {
    setSelectedReport(eventKey);
    if (eventKey === 'Generate PDF') {
      handleGenerateReport(feedbackData);
    } else if (eventKey === 'Generate Excel') {
      generateExcel(feedbackData);
    }
  };

  useEffect(() => {
    initialCustomerFeedbackList(thirtyDaysAgo, today);
    fetchProjectList();
  }, [])

  const initialCustomerFeedbackList = async (startDate, endDate) => {
    setLoading(true);
    const data = {
      feedback_rate: "",
      project_id: "",
      start_date: moment(startDate).format('YYYY-MM-DD'),
      end_date: moment(endDate).format('YYYY-MM-DD'),
    };
    const feedbacks = await dispatch(fetchInitialCustomerFeedbacksData(data));
    if (feedbacks.payload) {
      setFeedbackData(feedbacks.payload);
      setLoading(false);
    } else {
      setLoading(false);
    }
  };

  const fetchProjectList = async () => {
    const projects = await dispatch(fetchCompanyProjectListData());
    if (projects.payload) {
      setProjectData(projects.payload);
    }
  };

  const toggleFormModal = () => {
    setViewMoreModal({ open: !viewMoreModal })
  }

  const onSubmit = async (data) => {
    setLoading(true);
    setSubmitLoading(true);
    let newData = {};
    newData.feedback_rate = data.rate !== "" ? data.rate : "";
    newData.project_id = data.project_id !== "" ? data.project_id : "";
    if (changedFrom && !changedTo) {
      newData.end_date = maxDateTo ? moment(maxDateTo).format("YYYY-MM-DD") : null;
      setValue('to', maxDateTo ? maxDateTo : '');
    } else {
      newData.end_date = data.to ? moment(data.to).format("YYYY-MM-DD") : null;
    }
    if (changedTo && !changedFrom) {
      newData.start_date = minDateFrom ? moment(minDateFrom).format("YYYY-MM-DD") : null;
      setValue('from', minDateFrom ? minDateFrom : '');
    } else {
      newData.start_date = data.from ? moment(data.from).format("YYYY-MM-DD") : null;
    }

    const feedbacks = await dispatch(filterCustomerFeedbacksData(newData));
    if (feedbacks.payload) {
      setFeedbackData(feedbacks.payload);
      setLoading(false);
      setSubmitLoading(false);
    } else {
      setLoading(false);
      setSubmitLoading(false);
    }

    setChangedFrom(false);
    setChangedTo(false);
  };

  const handleGenerateReport = (feedbackData) => {
    setGenerateButtonLoading(true);
    generateReportPDF(feedbackData).then(() => {
      setGenerateButtonLoading(false);
    }).catch((error) => {
      Alert.error("Error generating PDF:", error)
      console.error("Error generating PDF:", error);
      setGenerateButtonLoading(false);
    });
  };

  const generateReportPDF = (data) => {
    const formatDate = (dateTimeString) => {
      const date = new Date(dateTimeString);
      const dateOptions = { year: 'numeric', month: 'long', day: 'numeric' };
      const timeOptions = { hour: '2-digit', minute: '2-digit', hour12: true };
      const formattedDate = date.toLocaleDateString('en-US', dateOptions);
      const formattedTime = date.toLocaleTimeString('en-US', timeOptions);
      return `${formattedDate}`;
    };

    return new Promise((resolve, reject) => {
      try {
        const doc = new jsPDF("p", "mm", "a4");
        const imgWidth = doc.internal.pageSize.getWidth();
        const imgHeight = 40;

        // Add letterhead
        const imgData = HeaderImg;
        doc.addImage(imgData, "JPEG", 0, 0, imgWidth, imgHeight);

        // title
        doc.setFontSize(16);
        doc.setTextColor(0, 0, 0);
        doc.text(`Customer Feedback Report`, doc.internal.pageSize.getWidth() / 2, 50, {
          align: "center",
        });

        doc.setFontSize(11);
        doc.text(`(From : ${formatDate(watchFrom)} - To : ${formatDate(watchTo)})`, doc.internal.pageSize.getWidth() / 2, 55, {
          align: "center",
        });

        // Content below the header
        const tableColumn = [
          "ID",
          "Project",
          "Task",
          "User",
          "Rating",
          "Comment",
          "Created At",
        ];

        // Function to format date
        const formatDateTime = (dateTimeString) => {
          const date = new Date(dateTimeString);
          const dateOptions = { year: 'numeric', month: 'long', day: 'numeric' };
          const timeOptions = { hour: '2-digit', minute: '2-digit', hour12: true };
          const formattedDate = date.toLocaleDateString('en-US', dateOptions);
          const formattedTime = date.toLocaleTimeString('en-US', timeOptions);
          return `${formattedDate}, ${formattedTime}`;
        };

        const tableRows =
          data &&
          data.length > 0 &&
          data.map((row) => [
            row.id,
            row.project_title,
            row.task_title,
            row.name,
            row.feedback_rate,
            row.comment,
            formatDateTime(row.created_at),
          ]);

        doc.autoTable({
          head: [tableColumn],
          body: tableRows,
          startY: 60,
          theme: "grid",
          headStyles: { fillColor: [35, 35, 64] },
          styles: { cellPadding: 3, fontSize: 9, halign: 'center' },
          margin: { left: 5, right: 5 },
        });

        // Add footer
        const pageCount = doc.internal.getNumberOfPages();
        for (let i = 1; i <= pageCount; i++) {
          doc.setPage(i);
          const footerHeight = 12;
          const footerY = doc.internal.pageSize.getHeight() - footerHeight;

          doc.setFillColor(35, 35, 64);
          doc.rect(0, footerY, imgWidth, footerHeight, "F");

          const logoData = NFSLogoDark;
          const logoWidth = 16;
          const logoHeight = 8;
          const logoX = imgWidth - logoWidth - 5;
          doc.addImage(logoData, "JPEG", logoX, footerY + (footerHeight - logoHeight) / 2, logoWidth, logoHeight);

          doc.setTextColor(255, 255, 255);
          doc.setFontSize(10);
          doc.text(`Page ${i} of ${pageCount}`, 10, footerY + 7);
        }

        window.open(doc.output("bloburl"), "_blank");
        resolve();
      } catch (error) {
        reject(error);
      }
    });
  };

  const generateExcel = (data) => {
    const formatDate = (dateTimeString) => {
      const date = new Date(dateTimeString);
      const dateOptions = { year: 'numeric', month: 'long', day: 'numeric' };
      const timeOptions = { hour: '2-digit', minute: '2-digit', hour12: true };
      const formattedDate = date.toLocaleDateString('en-US', dateOptions);
      return `${formattedDate}`;
    };

    const formattedData = data.map(item => ({
      'ID': item?.id,
      'Project': item?.project_title || "N/A",
      'Task': item?.task_title || "N/A",
      'User': item?.name || "N/A",
      'Rating': item?.feedback_rate || "N/A",
      'Comment': item?.comment || "N/A",
      'Created At': item?.created_at ? formatDateTime(item.created_at) : "N/A",
    }));

    const worksheet = XLSX.utils.json_to_sheet([]);

    XLSX.utils.sheet_add_aoa(worksheet, [
      [`Customer Feedback Report`],
      [`Date Range: ${formatDate(watchFrom)} - ${formatDate(watchTo)}`],
      [],
    ]);

    XLSX.utils.sheet_add_json(worksheet, formattedData, {
      origin: 'A4',
      skipHeader: false,
      header: [
        'ID',
        'Project',
        'Task',
        'User',
        'Rating',
        'Comment',
        'Created At',
      ],
    });

    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'FeedbackDetails');

    XLSX.writeFile(workbook, 'CustomerFeedbacksReport.xlsx');
  };

  const onReset = () => {
    initialCustomerFeedbackList(thirtyDaysAgo, today);
    reset({
      rate: "",
      project_id: "",
      from: thirtyDaysAgo,
      to: today,
    });
    setFromChanged(false);
    setToChanged(false);
    setMaxDateFrom(today);
    setMinDateFrom(null);
    setMaxDateTo(today);
    setMinDateTo(null);
    setSelectedReport('Generate Report');
  };

  //handle filter button disable
  const watchFields = watch(["rate", "project_id"]);

  const watchFrom = useWatch({ control, name: "from" });
  const watchTo = useWatch({ control, name: "to" });

  function areDatesSame(date1, date2) {
    const d1 = new Date(date1);
    const d2 = new Date(date2);

    return (
      d1.getFullYear() === d2.getFullYear() &&
      d1.getMonth() === d2.getMonth() &&
      d1.getDate() === d2.getDate()
    );
  }

  useEffect(() => {
    if (watchFrom && !areDatesSame(watchFrom, thirtyDaysAgo)) {
      setFromChanged(true);
    } else {
      setFromChanged(false);
    }
  }, [watchFrom]);

  useEffect(() => {
    if (watchTo && !areDatesSame(watchTo, today)) {
      setToChanged(true);
    } else {
      setToChanged(false);
    }
  }, [watchTo]);

  const isFilterButtonDisabled = loading || (!watchFields.some(field => field) && !fromChanged && !toChanged);

  const validateFromField = (from, allValues) => {
    if (!from && allValues.to) {
      return "From date is required";
    }
    return true;
  };

  const validateToField = (to, allValues) => {
    if (!to && allValues.from) {
      return "To date is required";
    }
    return true;
  };

  // Function to format date
  const formatDateTime = (dateTimeString) => {
    const date = new Date(dateTimeString);
    const dateOptions = { year: 'numeric', month: 'long', day: 'numeric' };
    const timeOptions = { hour: '2-digit', minute: '2-digit', hour12: true };
    const formattedDate = date.toLocaleDateString('en-US', dateOptions);
    const formattedTime = date.toLocaleTimeString('en-US', timeOptions);
    return `${formattedDate}, ${formattedTime}`;
  };

  //set table columns
  const columns = [
    {
      name: "ID",
      selector: (row) => row.id,
      width: "75px",
    },
    {
      name: "Project",
      selector: (row) => row.project_title,
      width: "130px",
    },
    {
      name: "Task",
      selector: (row) => (
        <div className="d-flex flex-wrap">
          <OverlayTrigger
            placement="top"
            overlay={<Tooltip>{row.task_title}</Tooltip>}
          >
            <div style={{ display: "inline-block" }}>
              {row.task_title}
            </div>
          </OverlayTrigger>
        </div>
      ),
    },
    {
      name: "User",
      selector: (row) => row.name,
    },
    {
      name: "Rating",
      selector: (row) => row.feedback_rate,
    },
    {
      name: "Comment",
      selector: (row) => (
        <div className="d-flex flex-wrap">
          <OverlayTrigger
            placement="top"
            overlay={<Tooltip>{row.comment}</Tooltip>}
          >
            <div style={{ display: "inline-block" }}>
              {row.comment}
            </div>
          </OverlayTrigger>
        </div>
      ),
      width: "405px",
    },
    {
      name: "Created At",
      selector: (row) => formatDateTime(row.created_at),
    },
  ];

  //Set table rows data
  const rows = Array.isArray(feedbackData)
    ? feedbackData?.map(
      ({
        id,
        project_title,
        task_title,
        name,
        feedback_rate,
        comment,
        created_at,
      }) => ({
        id,
        project_title,
        task_title,
        name,
        feedback_rate,
        comment,
        created_at,
      })
    )
    : [];

  return (
    <div>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Row>
          <div className='col-md-4'>
            <Form.Group>
              <Form.Label>Select Rating </Form.Label>
              <Form.Control
                className="form-select"
                as="select"
                name="rate"
                {...register("rate")}
                disabled={loading || feedbackData.length <= 0 || submitLoading}
              >
                <option value="">Select rating </option>
                {RatingTypes &&
                  RatingTypes.map((list) => (
                    <option key={list.id} value={list.id}>
                      {list.id}
                    </option>
                  ))
                }
              </Form.Control>
            </Form.Group>
          </div>
          <div className="col-md-4 mb-3">
            <Form.Label>From</Form.Label>
            <Controller
              control={control}
              name="from"
              rules={{ validate: (value) => validateFromField(value, watch()) }}
              render={({ field }) => (
                <ReactDatePicker
                  disabled={loading || submitLoading}
                  className="form-control"
                  dateFormat="yyyy-MM-dd"
                  dropdownMode="select"
                  selected={field.value}
                  onChange={(date) => {
                    field.onChange(date);
                    setChangedFrom(true);
                    const thirtyDaysAfter = moment(date).add(30, 'days').toDate();
                    setMinDateTo(date);
                    if (thirtyDaysAfter > today) {
                      setMaxDateTo(today);
                    } else {
                      setMaxDateTo(thirtyDaysAfter)
                    }
                  }}
                  customInput={<CustomInput />}
                  popperClassName="custom-datepicker-popper"
                  maxDate={maxDateFrom}
                  minDate={minDateFrom}
                />
              )}
            />

            {errors.from && (
              <small className="text-danger">{errors.from.message}</small>
            )}
          </div>
          <div className="col-md-4 mb-3">
            <Form.Label>To</Form.Label>
            <div>
              <Controller
                control={control}
                name="to"
                rules={{ validate: (value) => validateToField(value, watch()) }}
                render={({ field }) => (
                  <ReactDatePicker
                    disabled={loading || submitLoading}
                    className="form-control"
                    dateFormat="yyyy-MM-dd"
                    dropdownMode="select"
                    selected={field.value}
                    onChange={(date) => {
                      field.onChange(date);
                      const thirtyDaysAgo = moment(date).subtract(30, 'days').toDate();
                      setMaxDateFrom(date);
                      setMinDateFrom(thirtyDaysAgo);
                      setChangedTo(true);
                    }}
                    customInput={<CustomInput />}
                    popperClassName="custom-datepicker-popper"
                    maxDate={maxDateTo}
                    minDate={minDateTo}
                  />
                )}
              />

              {errors.to && (
                <small className="text-danger">{errors.to.message}</small>
              )}
            </div>
          </div>
        </Row>
        <Row>
          <div className='col-md-4'>
            <Form.Group>
              <Form.Label>Select Project</Form.Label>
              <Form.Control
                className="form-select"
                as="select"
                name="project_id"
                {...register("project_id")}
                disabled={loading || feedbackData.length <= 0 || submitLoading}
              >
                <option value="">Select project</option>
                {projectData &&
                  projectData.map((list) => (
                    <option key={list.id} value={list.id}>
                      {list.project_title}
                    </option>
                  ))
                }
              </Form.Control>
            </Form.Group>
          </div>
        </Row>
        <div className='d-flex justify-content-end gap-3 mb-3'>
          <div className="">
            <Button type='submit' className='d-flex align-items-center gap-1' disabled={isFilterButtonDisabled}>
              {submitLoading ? <Spinner animation="border" size="sm" /> : <i className="ri-filter-line"></i>}
              Filter
            </Button>
          </div>
          <div className="">
            <Button onClick={onReset} className='d-flex align-items-center gap-1' disabled={isFilterButtonDisabled}>
              <i className="ri-restart-line"></i>
              Reset
            </Button>
          </div>
          <div className="">
            <Dropdown onSelect={handleSelect}>
              <Dropdown.Toggle
                as={Button}
                disabled={loading || feedbackData.length <= 0 || submitLoading}
                className="d-flex align-items-center gap-1"
              >
                {generateButtonLoading ? (
                  <Spinner animation="border" size="sm" />
                ) : (
                  null
                )}
                {selectedReport}
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item eventKey="Generate PDF" className='d-flex align-items-center gap-1'>
                  <i className="ri-file-pdf-line"></i>
                  Generate PDF
                </Dropdown.Item>
                <Dropdown.Item eventKey="Generate Excel" className='d-flex align-items-center gap-1'>
                  <i className="ri-file-excel-2-line"></i>
                  Generate Excel
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
      </Form>
      <PaginationWithDataTable
        dataColumns={columns}
        entities={rows}
        loading={loading}
        rowCount={4}
        columnCount={7}
      />
      <FormModal
        moduleName={"Feedback Questions & Answers"}
        modalState={viewMoreModal.open}
        toggleFormModal={toggleFormModal}
        width="600px"
      >
        <PerfectScrollbar>
          <div className="feedback-content">
            {viewMoreModal?.data?.questions?.map((question, index) => (
              <div key={index} className="question-container">
                <h4 style={{ margin: '10px 0', color: '#333' }}>Question {index + 1}</h4>
                <p className="question-text">Q : {question.question}</p>
                <p className="answer-text">A : {question.answer}</p>
              </div>
            ))}
          </div>
        </PerfectScrollbar>
      </FormModal>
    </div>
  )
}

export default CustomerFeedbackReport