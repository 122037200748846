import React, { useState, useEffect } from 'react'
import { Button, Form, Row, Tooltip, OverlayTrigger, Spinner, Badge, Card, ListGroup } from "react-bootstrap";
import { Controller, useForm, useWatch } from 'react-hook-form';
import ReactDatePicker from 'react-datepicker';
import { CustomInput } from '../../utils/utils';
import PaginationWithDataTable from '../common/PagintionWithDataTable';
import FormModal from '../common/FormModal';
import { post, get } from '../../servces/Api';
import Alert from '../common/Alert';
import "../../assets/styles/components/common/GeneralFeedbackReports.scss";
import * as XLSX from 'xlsx';
import CustomSearchSelect from '../common/CustomSearchSelect';
import { Link } from 'react-router-dom';
import { userPackagesTypes, userRoleTypes, userStatusTypes } from '../../utils/types';
import { useDispatch } from 'react-redux';
import { fetchAllCompanyData, fetchInitialUsersData, filterUsersData } from '../../store/actions/reportActions';

function AdminUserReport() {
    const moment = require("moment");
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [submitLoading, setSubmitLoading] = useState(false);
    const [usersData, setUserData] = useState([]);
    const [packagesData, setPackagesData] = useState(userPackagesTypes);
    const [userroleData, setUserroleData] = useState(userRoleTypes);
    const [userStatusData, setUserStatusData] = useState(userStatusTypes);
    const [companyData, setCompanyData] = useState([]);
    const [viewMoreModal, setViewMoreModal] = useState({ open: false, data: [] });
    const [generateButtonLoading, setGenerateButtonLoading] = useState(false);

    const [fromChanged, setFromChanged] = useState(false);
    const [toChanged, setToChanged] = useState(false);

    const [changedFrom, setChangedFrom] = useState(false);
    const [changedTo, setChangedTo] = useState(false);

    const thirtyDaysAgo = moment().subtract(30, 'days').toDate();
    const today = moment().toDate();

    const [maxDateFrom, setMaxDateFrom] = useState(today);
    const [minDateFrom, setMinDateFrom] = useState(null);
    const [maxDateTo, setMaxDateTo] = useState(today);
    const [minDateTo, setMinDateTo] = useState(null);

    const { register, setValue, handleSubmit, reset, control, formState: { errors }, watch } = useForm({
        defaultValues: {
            company_id: "",
            status: "",
            package_id: "",
            user_role_id: "",
            from: thirtyDaysAgo,
            to: today
        }
    });

    useEffect(() => {
        initialUserList(thirtyDaysAgo, today);
        fetchCompanyList();
    }, [])

    const initialUserList = async (startDate, endDate) => {
        setLoading(true);
        const data = {
            company_id: "",
            status: "",
            package_id: "",
            user_role_id: "",
            start_date: moment(startDate).format('YYYY-MM-DD'),
            end_date: moment(endDate).format('YYYY-MM-DD'),
        };
        const users = await dispatch(fetchInitialUsersData(data));
        if (users.payload) {
            setUserData(users.payload);
            setLoading(false);
        } else {
            setLoading(false);
        }
    };

    const fetchCompanyList = async () => {
        const companies = await dispatch(fetchAllCompanyData());
        if (companies.payload) {
            setCompanyData(companies.payload);
        }
    };

    const toggleFormModal = () => {
        setViewMoreModal({ open: !viewMoreModal })
    }

    const onSubmit = async (data) => {
        setLoading(true);
        setSubmitLoading(true)
        let newData = {};
        newData.company_id = data.company_id !== "" ? data.company_id : "";
        newData.status = data.status !== "" ? data.status : "";
        newData.package_id = data.package_id !== "" ? data.package_id : "";
        newData.user_role_id = data.user_role_id !== "" ? data.user_role_id : "";
        if (changedFrom && !changedTo) {
            newData.end_date = maxDateTo ? moment(maxDateTo).format("YYYY-MM-DD") : null;
            setValue('to', maxDateTo ? maxDateTo : '');
        } else {
            newData.end_date = data.to ? moment(data.to).format("YYYY-MM-DD") : null;
        }
        if (changedTo && !changedFrom) {
            newData.start_date = minDateFrom ? moment(minDateFrom).format("YYYY-MM-DD") : null;
            setValue('from', minDateFrom ? minDateFrom : '');
        } else {
            newData.start_date = data.from ? moment(data.from).format("YYYY-MM-DD") : null;
        }

        const users = await dispatch(filterUsersData(newData));
        if (users.payload) {
            setUserData(users.payload);
            setLoading(false);
            setSubmitLoading(false);
        } else {
            setLoading(false);
            setSubmitLoading(false);
        }

        setChangedFrom(false);
        setChangedTo(false);
    };

    const generateExcel = (data) => {
        const formatDate = (dateTimeString) => {
            const date = new Date(dateTimeString);
            const dateOptions = { year: 'numeric', month: 'long', day: 'numeric' };
            const timeOptions = { hour: '2-digit', minute: '2-digit', hour12: true };
            const formattedDate = date.toLocaleDateString('en-US', dateOptions);
            return `${formattedDate}`;
        };

        const formattedData = data.map(item => ({
            'ID': item.id,
            'Name': `${item.first_name ? item.first_name : null} ${item.last_name ? item.last_name : null} ${(!item.last_name && !item.last_name) && "N/A"}`,
            'Company': item?.company?.com_name ? item?.company?.com_name : "N/A",
            'User Role': `${item.user_role_id === "2" ? "Customer" : item.user_role_id === "3" ? "Company" : item.user_role_id === "4" ? "Delivery Agent" : item.user_role_id === "5" ? "Filed Agent" : "N/A"}`,
            'User Package': `${item?.packages?.id === 1 ? "Free Trial" : item?.packages?.id === 2 ? "Stater" : item?.packages?.id === 3 ? "Business" : item?.packages?.id === 5 ? "Enterprise" : "N/A"}`,
            'Status': item.status === 1 ? "Active" : "Inactive",
            'Country': item.country ? item.country : "N/A",
            'Region': item.region ? item.region : "N/A",
            'Address line 1': item.address1 ? item.address1 : "N/A",
            'Address Line 2': item.address2 ? item.address2 : "N/A",
            'Address Line 3': item.address3 ? item.address3 : "N/A",
            'Contact': item.contact ? item.contact : "N/A",
            'Email': item.email ? item.email : "N/A",
            'User Description': item.user_description,
            'Onboard At': item.onboard_date ? formatDateTime(item.onboard_date) : "N/A",
            'Package Start Date': item.package_start_date ? formatDateTime(item.package_start_date) : "N/A",
            'Package End Date': item.package_end_date ? formatDateTime(item.package_end_date) : "N/A",
        }));

        const worksheet = XLSX.utils.json_to_sheet([]);

        XLSX.utils.sheet_add_aoa(worksheet, [
            [`Admin User Report`],
            [`Date Range: ${formatDate(watchFrom)} - ${formatDate(watchTo)}`],
            [],
        ]);

        XLSX.utils.sheet_add_json(worksheet, formattedData, {
            origin: 'A4',
            skipHeader: false,
            header: [
                'ID',
                'Name',
                'Company',
                'User Role',
                'User Package',
                'Status',
                'Country',
                'Region',
                'Address Line 1',
                'Address Line 2',
                'Address Line 3',
                'Contact',
                'Email',
                'User Description',
                'Onboard At',
                'Package Start Date',
                'Package End Date',
            ],
        });

        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'UserDetails');

        XLSX.writeFile(workbook, 'AdminUserDetailsReport.xlsx');
    };

    const onReset = () => {
        initialUserList(thirtyDaysAgo, today);
        reset({
            company_id: "",
            status: "",
            package_id: "",
            user_role_id: "",
            from: thirtyDaysAgo,
            to: today,
        });
        setFromChanged(false);
        setToChanged(false);
        setMaxDateFrom(today);
        setMinDateFrom(null);
        setMaxDateTo(today);
        setMinDateTo(null);
    };

    //handle button disble
    const watchFields = watch(["company_id", "status", "package_id", "user_role_id"]);

    const watchFrom = useWatch({ control, name: "from" });
    const watchTo = useWatch({ control, name: "to" });

    function areDatesSame(date1, date2) {
        const d1 = new Date(date1);
        const d2 = new Date(date2);

        return (
            d1.getFullYear() === d2.getFullYear() &&
            d1.getMonth() === d2.getMonth() &&
            d1.getDate() === d2.getDate()
        );
    }

    useEffect(() => {
        if (watchFrom && !areDatesSame(watchFrom, thirtyDaysAgo)) {
            setFromChanged(true);
        } else {
            setFromChanged(false);
        }
    }, [watchFrom]);

    useEffect(() => {
        if (watchTo && !areDatesSame(watchTo, today)) {
            setToChanged(true);
        } else {
            setToChanged(false);
        }
    }, [watchTo]);

    const isFilterButtonDisabled = loading || (!watchFields.some(field => field) && !fromChanged && !toChanged);

    //validate forms
    const validateFromField = (from, allValues) => {
        if (!from && allValues.to) {
            return "From date is required";
        }
        return true;
    };

    const validateToField = (to, allValues) => {
        if (!to && allValues.from) {
            return "To date is required";
        }
        return true;
    };

    // Function to format date
    const formatDateTime = (dateTimeString) => {
        const date = new Date(dateTimeString);
        const dateOptions = { year: 'numeric', month: 'long', day: 'numeric' };
        const timeOptions = { hour: '2-digit', minute: '2-digit', hour12: true };
        const formattedDate = date.toLocaleDateString('en-US', dateOptions);
        const formattedTime = date.toLocaleTimeString('en-US', timeOptions);
        return `${formattedDate}, ${formattedTime}`;
    };

    //set table columns
    const columns = [
        {
            name: "ID",
            selector: (row) => row.id,
            width: "75px",
        },
        {
            name: "Name",
            selector: (row) => (
                <div className="d-flex flex-wrap">
                    <OverlayTrigger
                        placement="top"
                        overlay={<Tooltip>{row.first_name ? row.first_name : null} {row.last_name ? row.last_name : null} {(!row.first_name && !row.last_name) && "N/A"}</Tooltip>}
                    >
                        <div style={{ display: "inline-block" }}>
                            {row.first_name ? row.first_name : null} {row.last_name ? row.last_name : null} {(!row.first_name && !row.last_name) && "N/A"}
                        </div>
                    </OverlayTrigger>
                </div>
            ),
        },
        // {
        //     name: "Email",
        //     selector: (row) => (
        //         <div className="d-flex flex-wrap">
        //             <OverlayTrigger
        //                 placement="top"
        //                 overlay={<Tooltip>{row.email ? row.email : "N/A"}</Tooltip>}
        //             >
        //                 <div style={{ display: "inline-block" }}>
        //                     {row.email ? row.email : "N/A"}
        //                 </div>
        //             </OverlayTrigger>
        //         </div>
        //     ),
        // },
        {
            name: "Company",
            selector: (row) => (
                <div className="d-flex flex-wrap">
                    <OverlayTrigger
                        placement="top"
                        overlay={<Tooltip>{row.company?.com_name ? row.company?.com_name : "N/A"}</Tooltip>}
                    >
                        <div style={{ display: "inline-block" }}>
                            {row.company?.com_name ? row.company?.com_name : "N/A"}
                        </div>
                    </OverlayTrigger>
                </div>
            ),
        },
        // {
        //     name: "Address",
        //     selector: (row) => (
        //         <div className="d-flex flex-wrap">
        //             <OverlayTrigger
        //                 placement="top"
        //                 overlay={
        //                     <Tooltip>
        //                         {row.address1 ? row.address1 + "," : null}{" "}
        //                         {row.address2 ? row.address2 + "," : null}{" "}
        //                         {row.address3 ? row.address3 + "," : null}{" "}
        //                         {row.region ? row.region + "," : null}{" "}
        //                         {row.country ? row.country : null}{" "}
        //                     </Tooltip>
        //                 }
        //             >
        //                 <div style={{ display: "inline-block" }}>
        //                     {row.address1 ? row.address1 + "," : null}{" "}
        //                     {row.address2 ? row.address2 + "," : null}{" "}
        //                     {row.address3 ? row.address3 + "," : null}{" "}
        //                     {row.region ? row.region + "," : null}{" "}
        //                     {row.country ? row.country : null}{" "}
        //                     {(!row.address1 && !row.address2 && !row.address3 && !row.region && !row.country) && "N/A"}
        //                 </div>
        //             </OverlayTrigger>
        //         </div>
        //     ),
        // },
        {
            name: "User Role",
            selector: (row) => (
                <div className="d-flex flex-wrap">
                    <OverlayTrigger
                        placement="top"
                        overlay={
                            <Tooltip>
                                {row.user_role_id &&
                                    row.user_role_id === "2" ? "Customer" :
                                    row.user_role_id === "3" ? "Company" :
                                        row.user_role_id === "4" ? "Delivery Agent" :
                                            row.user_role_id === "5" ? "Field Agent" :
                                                "N/A"
                                }
                            </Tooltip>
                        }
                    >
                        <div style={{ display: "inline-block" }}>
                            {row.user_role_id &&
                                row.user_role_id === "2" ? "Customer" :
                                row.user_role_id === "3" ? "Company" :
                                    row.user_role_id === "4" ? "Delivery Agent" :
                                        row.user_role_id === "5" ? "Field Agent" :
                                            "N/A"
                            }
                        </div>
                    </OverlayTrigger>
                </div>
            ),
        },
        {
            name: "User Package",
            selector: (row) => (
                <div className="d-flex flex-wrap">
                    <OverlayTrigger
                        placement="top"
                        overlay={
                            <Tooltip>
                                {row.packages?.id &&
                                    row.packages?.id === 1 ? "Free Trail" :
                                    row.packages?.id === 2 ? "Starter" :
                                        row.packages?.id === 3 ? "Business" :
                                            row.packages?.id === 4 ? "Enterprise" :
                                                "N/A"
                                }
                            </Tooltip>
                        }
                    >
                        <div style={{ display: "inline-block" }}>
                            {row.packages?.id &&
                                row.packages?.id === 1 ? "Free Trial" :
                                row.packages?.id === 2 ? "Starter" :
                                    row.packages?.id === 3 ? "Business" :
                                        row.packages?.id === 4 ? "Enterprise" :
                                            "N/A"
                            }
                        </div>
                    </OverlayTrigger>
                </div>
            ),
        },
        {
            name: "Status",
            selector: (row) => {
                return (
                    <div className='d-flex justify-content-center'>
                        {row?.status === 1 ?
                            <Badge bg="success">Active</Badge>
                            :
                            <Badge bg="danger">Inactive</Badge>
                        }
                    </div>
                )
            },
            width: "80px",
        },
        {
            name: "Onboarded At",
            selector: (row) => (
                <div className="d-flex flex-wrap">
                    <OverlayTrigger
                        placement="top"
                        overlay={<Tooltip>{formatDateTime(row.created_at)}</Tooltip>}
                    >
                        <div style={{ display: "inline-block" }}>
                            {formatDateTime(row.created_at)}
                        </div>
                    </OverlayTrigger>
                </div>
            ),
        },
        {
            name: "Action",
            selector: (row) => (
                <div className="d-flex flex-wrap gap-2">
                    <OverlayTrigger
                        placement="top"
                        overlay={<Tooltip>More User Details</Tooltip>}
                    >
                        <div style={{ display: "inline-block" }}>
                            <Link
                                className="d-flex btn btn-primary btn-sm"
                                onClick={() => setViewMoreModal({ open: true, data: row })}
                                disabled={true}
                            >
                                <i className="ri-eye-line"></i>
                            </Link>
                        </div>
                    </OverlayTrigger>
                </div>
            ),
            width: "80px",
        },
    ];

    //Set table rows data
    const rows = Array.isArray(usersData)
        ? usersData?.map(
            ({
                id,
                first_name,
                last_name,
                company,
                email,
                address1,
                address2,
                address3,
                region,
                country,
                user_role_id,
                packages,
                status,
                created_at,
                contact,
                package_end_date,
                user_description,
            }) => ({
                id,
                first_name,
                last_name,
                company,
                email,
                address1,
                address2,
                address3,
                region,
                country,
                user_role_id,
                packages,
                status,
                created_at,
                contact,
                package_end_date,
                user_description,
            })
        )
        : [];


    //handle searchable select
    const handleCompanyChange = (selectedOption) => {
        setValue('company_id', selectedOption ? selectedOption.value : '');
    };

    const selectedCompany = watch('company_id');

    const companyOptions = companyData.map(list => ({
        value: list.id,
        label: list.com_name,
    }));

    //get status batch
    const getStatusBatch = (status) => {
        if (status === 1) {
            return <Badge bg="success">Active</Badge>;
        } else {
            return <Badge bg="danger">Inactive</Badge>
        }
    }

    return (
        <div>
            <Form onSubmit={handleSubmit(onSubmit)}>
                <Row>
                    <div className='col-md-4 mb-3'>
                        <Form.Group>
                            <Form.Label>Select Company</Form.Label>
                            <CustomSearchSelect
                                options={companyOptions}
                                selectedOption={selectedCompany ? companyOptions.find(option => option.value === selectedCompany) : null}
                                handleChange={handleCompanyChange}
                                disabled={loading || usersData.length <= 0 || submitLoading}
                            />
                        </Form.Group>
                    </div>
                    <div className="col-md-4 mb-3">
                        <Form.Label>From</Form.Label>
                        <Controller
                            control={control}
                            name="from"
                            rules={{ validate: (value) => validateFromField(value, watch()) }}
                            render={({ field }) => (
                                <ReactDatePicker
                                    disabled={loading || submitLoading}
                                    className="form-control"
                                    dateFormat="yyyy-MM-dd"
                                    dropdownMode="select"
                                    selected={field.value}
                                    onChange={(date) => {
                                        field.onChange(date);
                                        setChangedFrom(true);
                                        const thirtyDaysAfter = moment(date).add(30, 'days').toDate();
                                        setMinDateTo(date);
                                        if (thirtyDaysAfter > today) {
                                            setMaxDateTo(today);
                                        } else {
                                            setMaxDateTo(thirtyDaysAfter)
                                        }
                                    }}
                                    customInput={<CustomInput />}
                                    popperClassName="custom-datepicker-popper"
                                    maxDate={maxDateFrom}
                                    minDate={minDateFrom}
                                />
                            )}
                        />

                        {errors.from && (
                            <small className="text-danger">{errors.from.message}</small>
                        )}
                    </div>
                    <div className="col-md-4 mb-3">
                        <Form.Label>To</Form.Label>
                        <div>
                            <Controller
                                control={control}
                                name="to"
                                rules={{ validate: (value) => validateToField(value, watch()) }}
                                render={({ field }) => (
                                    <ReactDatePicker
                                        disabled={loading || submitLoading}
                                        className="form-control"
                                        dateFormat="yyyy-MM-dd"
                                        dropdownMode="select"
                                        selected={field.value}
                                        onChange={(date) => {
                                            field.onChange(date);
                                            const thirtyDaysAgo = moment(date).subtract(30, 'days').toDate();
                                            setMaxDateFrom(date);
                                            setMinDateFrom(thirtyDaysAgo);
                                            setChangedTo(true);
                                        }}
                                        customInput={<CustomInput />}
                                        popperClassName="custom-datepicker-popper"
                                        maxDate={maxDateTo}
                                        minDate={minDateTo}
                                    />
                                )}
                            />

                            {errors.to && (
                                <small className="text-danger">{errors.to.message}</small>
                            )}
                        </div>
                    </div>
                </Row>
                <Row>
                    <div className='col-md-4 mb-3'>
                        <Form.Group>
                            <Form.Label>Select Package </Form.Label>
                            <Form.Control
                                className="form-select"
                                as="select"
                                name="package_id"
                                {...register("package_id")}
                                disabled={loading || usersData.length <= 0 || submitLoading}
                            >
                                <option value="">Select package </option>
                                {packagesData &&
                                    packagesData.map((list) => (
                                        <option key={list.id} value={list.id}>
                                            {list.lable}
                                        </option>
                                    ))
                                }
                            </Form.Control>
                        </Form.Group>
                    </div>
                    <div className='col-md-4 mb-3'>
                        <Form.Group>
                            <Form.Label>Select User Role </Form.Label>
                            <Form.Control
                                className="form-select"
                                as="select"
                                name="user_role_id"
                                {...register("user_role_id")}
                                disabled={loading || usersData.length <= 0 || submitLoading}
                            >
                                <option value="">Select user role </option>
                                {userroleData &&
                                    userroleData.map((list) => (
                                        <option key={list.id} value={list.id}>
                                            {list.lable}
                                        </option>
                                    ))
                                }
                            </Form.Control>
                        </Form.Group>
                    </div>
                    <div className='col-md-4 mb-3'>
                        <Form.Group>
                            <Form.Label>Select Status </Form.Label>
                            <Form.Control
                                className="form-select"
                                as="select"
                                name="status"
                                {...register("status")}
                                disabled={loading || usersData.length <= 0 || submitLoading}
                            >
                                <option value="">Select status </option>
                                {userStatusData &&
                                    userStatusData.map((list) => (
                                        <option key={list.id} value={list.id}>
                                            {list.lable}
                                        </option>
                                    ))
                                }
                            </Form.Control>
                        </Form.Group>
                    </div>
                </Row>
                <div className='d-flex justify-content-end gap-3 mb-3'>
                    <div className="">
                        <Button type='submit' className='d-flex align-items-center gap-1' disabled={isFilterButtonDisabled}>
                            {submitLoading ? <Spinner animation="border" size="sm" /> : <i className="ri-filter-line"></i>}
                            Filter
                        </Button>
                    </div>
                    <div className="">
                        <Button onClick={onReset} className='d-flex align-items-center gap-1' disabled={isFilterButtonDisabled}>
                            <i className="ri-restart-line"></i>
                            Reset
                        </Button>
                    </div>
                    <div className="">
                        <Button disabled={loading || usersData.length <= 0 || submitLoading} onClick={() => generateExcel(usersData)} className='d-flex align-items-center gap-1'>
                            {generateButtonLoading ? <Spinner animation="border" size="sm" /> : <i className="ri-file-excel-2-line"></i>}
                            Generate Report
                        </Button>
                    </div>
                </div>
            </Form>
            <PaginationWithDataTable
                dataColumns={columns}
                entities={rows}
                loading={loading}
                rowCount={4}
                columnCount={8}
            />
            {viewMoreModal.open &&
                <FormModal
                    moduleName={"User Details"}
                    modalState={viewMoreModal.open}
                    toggleFormModal={toggleFormModal}
                    width="600px"
                >
                    <div className="feedback-content">
                        <Card.Body className='p-3'>
                            <Card.Title className='fs-3' >{viewMoreModal?.data?.first_name ? viewMoreModal?.data?.first_name : null} {viewMoreModal?.data?.last_name ? viewMoreModal?.data?.last_name : null} {(!viewMoreModal?.data?.first_name && !viewMoreModal?.data?.last_name) && "N/A"}</Card.Title>
                            <Card.Subtitle className="mb-2 text-primary">{viewMoreModal?.data?.company?.com_name ? viewMoreModal?.data?.company?.com_name : null}</Card.Subtitle>
                            <Card.Text>{viewMoreModal?.data?.user_description ? viewMoreModal?.data?.user_description : "N/A"}</Card.Text>
                        </Card.Body>
                        <ListGroup variant="flush">
                            <ListGroup.Item><strong>Email : </strong> {viewMoreModal?.data?.email ? viewMoreModal?.data?.email : "N/A"}</ListGroup.Item>
                            <ListGroup.Item><strong>Contact : </strong> {viewMoreModal?.data?.contact ? viewMoreModal?.data?.contact : "N/A"}</ListGroup.Item>
                            <ListGroup.Item><strong>Address : </strong>
                                {viewMoreModal?.data?.address1 ? viewMoreModal?.data?.address1 + "," : null}{" "}
                                {viewMoreModal?.data?.address2 ? viewMoreModal?.data?.address2 + "," : null}{" "}
                                {viewMoreModal?.data?.address3 ? viewMoreModal?.data?.address3 + "," : null}{" "}
                                {viewMoreModal?.data?.region ? viewMoreModal?.data?.region + "," : null}{" "}
                                {viewMoreModal?.data?.country ? viewMoreModal?.data?.country : null}{" "}
                                {(!viewMoreModal?.data?.address1 && !viewMoreModal?.data?.address2 && !viewMoreModal?.data?.address3 && !viewMoreModal?.data?.region && !viewMoreModal?.data?.country) && "N/A"}
                            </ListGroup.Item>
                            <ListGroup.Item><strong>Role : </strong>
                                {
                                    viewMoreModal?.data?.user_role_id === "2" ? "Customer" :
                                        viewMoreModal?.data?.user_role_id === "3" ? "Company" :
                                            viewMoreModal?.data?.user_role_id === "4" ? "Delivery Agent" :
                                                viewMoreModal?.data?.user_role_id === "5" ? "Field Agent" :
                                                    "N/A"
                                }
                            </ListGroup.Item>
                            <ListGroup.Item><strong>Package : </strong>
                                {
                                    viewMoreModal?.data?.packages?.id === 1 ? "Free Trial" :
                                        viewMoreModal?.data?.packages?.id === 2 ? "Starter" :
                                            viewMoreModal?.data?.packages?.id === 3 ? "Business" :
                                                viewMoreModal?.data?.packages?.id === 4 ? "Enterprise" :
                                                    "N/A"
                                }
                            </ListGroup.Item>
                            <ListGroup.Item><strong>Status : </strong>
                                {
                                    getStatusBatch(viewMoreModal?.data?.status)
                                }
                            </ListGroup.Item>
                            <ListGroup.Item><strong>Package End Date : </strong> {viewMoreModal?.data?.package_end_date ? formatDateTime(viewMoreModal?.data?.package_end_date) : "N/A"}</ListGroup.Item>
                            <ListGroup.Item><strong>Onboarded Date : </strong> {viewMoreModal?.data?.created_at ? formatDateTime(viewMoreModal?.data?.created_at) : "N/A"}</ListGroup.Item>
                        </ListGroup>
                    </div>
                </FormModal>
            }
        </div>
    )
}

export default AdminUserReport